import { Header, Grid, Button } from 'semantic-ui-react';
import { getCSVData } from '../../../lib/functions/CSVFunctions';
import i18next from 'i18next';

const ExerciseAggregate = ({ results }) => {

    return (
        <div className="result">
            {results && <div>
                <Header as="h3" className="title">
                {i18next.t("general.results.exercise.aggregate_title")}
                </Header>
                <Grid className="grid">

                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_success")}</p>
                        <p>{results.score}/{results.outOf}</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanning")}</p>
                        <p>{results.averageScanningPercentage.toPrecision(2)}%</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_actionTime")}</p>
                        <p>{results.averageDecisionTime.toPrecision(1)}s</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanPerSecond")}</p>
                        <p>{results.averageScanningFrequency.toPrecision(1)}</p>
                    </Grid.Row>
                    <Grid.Row>
                        <Button onClick={() => getCSVData(results)}>{i18next.t("general.results.exercise_getCSV")}</Button>
                    </Grid.Row>
                </Grid>
            </div>}
        </div>
    )
}

export default ExerciseAggregate;