import cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {debug, url} from '../../config';

export const getCSVData = async (sessions) => {

    let list;
    let date;

    if (Array.isArray(sessions) === true) {
        list = sessions.map(val => val.sessionId).join();
        date = DateTime.fromJSDate(new Date()).toISODate();
    }
    else {
        list = sessions.sessionId;
        date = DateTime.fromISO(sessions.datePosted).toISODate();
    }

    try {
        let data = await fetch(`${url()}/api/soccer/csv_export?sessions=${list}`, {
            method: "GET",
            headers: new Headers({
                "content-type": "application/json",
                "Authorization": `Bearer ${cookies.get("session")}`
            })
        })
        debug(data)
        let res = await data.blob(); //need to handle use for other browsers

        if (Array.isArray(sessions) === true) {
            saveAs(res, `comparison ${date}.csv`);
        } else {
            saveAs(res, `user ${sessions.userId} ${date}.csv`)
        }

    } catch (error) {
        debug(error)
        return error;
    }
}