import {Button, Dropdown, Form, Loader, Menu, Modal} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {addMessage} from "../../lib/functions/notifications";
import {debug, url} from "../../config";
import cookies from "js-cookie";
import {AddEditDeleteTags} from "./AddEditDeleteTags";

export const Tags = ({tags, setTags, setTagsOpen, existingSequence, getUserSequences}) => {

    const [loading, setLoading] = useState(false)
    const [userTags, setUserTags] = useState([])
    const [tagData, setTagData] = useState([])
    const [tagChanged, setTagChanged] = useState(false)

    const [modifyTags, setModifyTags] = useState(false)

    useEffect(() => {
        const getUserTags = async () => {
            setLoading(true)
            try {
                let data = await fetch(`${url()}/coach/tags`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${cookies.get("session")}`,
                    }
                });
                debug(data.status)
                switch (data.status) {
                    case 200:
                        const res = await data.json()
                        for (let i = 0; i < res.length; i++) {
                            res[i] = {key: res[i].id, text: res[i].tag, value: res[i].tag}
                        }
                        setUserTags(res)
                        break
                    default:
                        addMessage("Unable to get users tags", "warning")
                        break
                }
            } catch (error) {
                addMessage(`Error: ${error.message}`, 'warning')
                debug(error)
            } finally {
                setLoading(false)
            }
        }
        getUserTags()
    }, [])

    useEffect(() => {
        if (loading) {
            setLoading(false)
        }
    }, [loading])

    const updateSequence = async () => {
        if (existingSequence.isTrue && tagChanged) {
            setLoading(true)

            try {
                const body = tagData.map(tag => tag.key)
                let data = await fetch(`${url()}/coach/tags/game_sequence/${existingSequence.id}`, {
                    method: "PUT",
                    body: JSON.stringify({tags: body}),
                    headers: {
                        'content-type': 'application/json',
                        Authorization: `Bearer ${cookies.get("session")}`,
                    }
                });
                debug(data.status)
                switch (data.status) {
                    case 200:
                        addMessage('Sucessfully updated tags', 'success')
                        break
                    default:
                        addMessage("Unable to update tags", "warning")
                        break
                }
            } catch (error) {
                addMessage(`Error: ${error.message}`, 'warning')
                debug(error)
            } finally {
                getUserSequences()
                setTagChanged(false)
                setLoading(false)
            }
        }
        setTagsOpen(false)
    }

    if (loading) {
        return <Loader active={true}/>
    } else {
        return (
            <>
                <Modal
                    size="tiny"
                    open
                >
                    <Modal.Header>
                        Select Tags
                    </Modal.Header>
                    <Modal.Content>
                        <p>Select tags from the dropdown below.</p>
                        <Form>
                            {userTags.length < 1 ?
                                <Loader active={true}/>
                                :
                                <Menu>
                                    <Dropdown
                                        fluid
                                        multiple
                                        selection
                                        value={tags}
                                        options={userTags}
                                        onChange={(event, data) => {
                                            let arr = []
                                            data.value.forEach(tag => {
                                                arr.push(data.options.find(x => x.value === tag))
                                            })
                                            // console.log(arr)
                                            if (tagChanged === false) {
                                                setTagChanged(true)
                                            }
                                            setTagData(arr)
                                            setTags(data.value)
                                        }}
                                        placeholder='Tags'
                                    />
                                </Menu>
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setModifyTags(true)}>Add New Tags...</Button>
                        <Button onClick={() => updateSequence()}>Confirm</Button>
                    </Modal.Actions>
                </Modal>
                {modifyTags && <AddEditDeleteTags
                    userTags={userTags}
                    setUserTags={(val) => setUserTags(val)}
                    setModifyTags={(val) => setModifyTags(val)}
                    setLoading={(val) => setLoading(val)}
                />}
            </>
        )
    }
}