import { Loader } from 'semantic-ui-react';

const SessionExpired = () => {


    //use this if token returns 401

    return (
        <div>
            <Loader size="massive" active>Token expired - refreshing</Loader>
        </div>
    )

}

export default SessionExpired;