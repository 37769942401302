import { useState } from 'react';
import { Form, Button, Label, Input, Header } from 'semantic-ui-react'
import { useHistory } from 'react-router';
import i18next from 'i18next';
import { url, debug } from '../../../config';
import { addMessage } from '../../../lib/functions/notifications';

const NewPassword = () => {

    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    let history = useHistory();

    const submitHandler = async (e) => {
        e.preventDefault();

        if (newPass.trim() === "" || confirmPass.trim() === "") {
            addMessage(i18next.t("general.email_new_password.error"), "warning");
            return;
        }

        if (newPass === confirmPass) {

            let param = history.location.search;

            try {

                let data = await fetch(`${url()}/auth/reset_password${param}`, { // ${param}
                    method: 'PATCH',
                    body: JSON.stringify({
                        password: newPass
                    }),
                    headers: {
                        "content-type": "application/json"
                    }
                });
                if (data.status === 200) {
                    addMessage(i18next.t("general.email_new_password.confirm"), "success");
                }
                let res = await data.json();
                debug(res);

            } catch (error) {
                debug(error);
            }

        } else {
            debug("passwords do not match");
            addMessage(i18next.t("general.email_new_password.invalid"), "warning")
        }

    }

    return (
        <div>
            <Header as="h1" className="teal">{i18next.t("general.email_new_password.title")}</Header>
            <Form className="loginForm" onSubmit={(e) => submitHandler(e)}>
                <Form.Field inline={true} className="field">
                    <div><Label className="teal label">{i18next.t("general.email_new_password.password_label")}</Label>
                        <Input placeholder={i18next.t("general.email_new_password.password_label")} type="password" value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                    </div>
                    <div>
                        <Label className="teal label">{i18next.t("general.email_new_password.confirmPass_label")}</Label>
                        <Input placeholder={i18next.t("general.email_new_password.confirmPass_label")} type="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                    </div>
                </Form.Field>
                <Button size="small" className="loginButton" type="submit">{i18next.t("general.email_new_password.submit")}</Button>
            </Form>
        </div>
    )
}

export default NewPassword;