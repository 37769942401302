import {useState} from 'react';
import {Form, Button, Label, Input, Header, Loader} from 'semantic-ui-react'
import cookies from 'js-cookie'
import '../Login/Login.css'
import {useHistory} from 'react-router-dom'
import i18next from 'i18next';
import {isProfileFilledIn} from '../../../lib/functions/ProfileHelperFunctions';
import {HighlightTag} from '../../../StyledComponents';
import {basicEmailCheck, sessionTokenFetch, userDataObj} from '../../../lib/functions/LoginHelperFunctions';
import {url, debug} from '../../../config';
import {addMessage} from "../../../lib/functions/notifications";
import {GoogleIdentitySignin} from "../ThirdPartySignin/Google/GoogleIdentitySignin";

const Login = ({loggedIn, username, userTeams, setProfileFilledIn}) => {

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [loading, setLoading] = useState(false);
    // const [errormessage, setEM] = useState("");
    const [underline, setUnderline] = useState(false);

    let history = useHistory();

    const submitHandler = async (e) => {
        e.preventDefault(); //prevent re-render upon click

        setLoading(true);

        if (!basicEmailCheck(user)) {
            addMessage("Please enter a valid email address.", "warning")
            setLoading(false);
            return;
        }

        if (user.trim() === "" || pass.trim() === "") {
            addMessage("Please fill in the fields or use the third party sign in options to login.", "warning")
            setLoading(false);
            return;
        }


        const encodedString = Buffer.from(`${user}:${pass}`).toString('base64');
        const controller = new AbortController()
        try {
            const timeout = setTimeout(()=> {
                controller.abort()
                debug("aborted")
            }, 20000)
            let data = await fetch(`${url()}/auth/signin`, {
                signal: controller.signal,
                method: "POST",
                headers: {
                    'Authorization': 'Basic ' + encodedString
                }
            });
            let res = await data.json()
            if (data.status === 401) {
                addMessage(i18next.t("general.login.error"), "warning");
                setLoading(false);
            }  else if (data.status === 403) {
                addMessage(i18next.t("general.login.google_error"), "warning");
                setLoading(false);
            } else if (data.status !== 200) {
                addMessage(i18next.t("general.login.generic_error"), "warning")
                debug(`${i18next.t("general.error")}${data.status}: ${res.error}`)
                setLoading(false);
            } else {
                clearTimeout(timeout)
                username(userDataObj(res));
                cookies.set('user', res.token, {expires: 7});
                cookies.set('userdata', res.id, {expires: 7})
                cookies.set("path", "/logged_in_home")
                if (isProfileFilledIn(res) === true) {
                    setProfileFilledIn(true)
                }
                loggedIn(true);
                setUser("");
                setPass("");
                fetchSessionToken();
            }

        } catch (error) {
            debug(error)
            addMessage(i18next.t("general.login.error_api"), "warning")
            setLoading(false);
        }

    }

    const fetchSessionToken = async () => {

        try {
            let res = await sessionTokenFetch()
            if (res.status === 200) {
                //if successfully getting temp token, store it and redirect
                cookies.set("session", res.token, {expires: 1 / 24})
                await getUserTeam();

                history.push("logged_in_home");
            }
            if (res.status !== 200) {
                addMessage(i18next.t("login.error.token"), "warning");
            }

        } catch (error) {
            debug(error);
            addMessage(i18next.t("login.error.token"), "warning")
        }
        finally {
            setLoading(false)
        }
    }

    const getUserTeam = async () => {
        try {
            let data = await fetch(`${url()}/api/soccer/teams`, {
                method: "GET",
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })

            let res = await data.json();
            debug("userteam")
            userTeams(res);
            return true;
        } catch (error) {
            debug(error);
            userTeams([])
            addMessage(i18next.t("login.error.teams"), "warning");
            return false;
        }

    }

    return (
        <div>
            {/*<div className="segmentMessage">*/}
            {/*    <p className="loginerror"><i>{errormessage}</i></p>*/}
            {/*</div>*/}
            {/*<br/>*/}
            <Header as="h1" className="teal">{i18next.t("login_login")}</Header>
            <div className="altLoginButtons">
                        <GoogleIdentitySignin
                            setProfileFilledIn={data => setProfileFilledIn(data)}
                            setLoading={data => setLoading(data)}
                            username={data => username(data)}
                            getUserTeam={getUserTeam}
                            loggedIn={data => loggedIn(data)}
                        />
            </div>
            <br/>
            <Form className="loginForm" onSubmit={(e) => submitHandler(e)}>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.login.email")}</Label>
                    <Input placeholder={i18next.t("general.login.email")}
                           onChange={(e) => setUser(e.target.value.toLowerCase())}
                           value={user}/>
                </Form.Field>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.login.password")}</Label>
                    <Input type="password" placeholder={i18next.t("general.login.password")}
                           onChange={(e) => setPass(e.target.value)} value={pass}/>
                </Form.Field>
                <Button size="small" className="loginButton" type="submit">{i18next.t("login_login")}</Button>
                <Button size="small" className="loginButton" type="button"
                        onClick={() => history.push("/reset_password")}>{i18next.t("general.login.forgot_password")}</Button>
            </Form>
            <br/>
            <p>
                {i18next.t("login_or")}
                <br/>
                <HighlightTag
                    color="#00b5acf3"
                    bold={true}
                    underline={underline}
                    link={true}
                    size="h2"
                    onMouseOver={() => setUnderline(true)}
                    onMouseOut={() => setUnderline(false)}
                    onClick={() => history.push("/signup")}
                >
                    {i18next.t("login_signup")}
                </HighlightTag>
            </p>
            <Loader id="loginLoading" active={loading} inverted>{i18next.t("general.login.loading")}</Loader>
        </div>
    )
}

export default Login;
