import {useState, useEffect} from 'react';
import {Segment, Dropdown, Confirm, Header} from 'semantic-ui-react'
import Players from '../Players/Players';
import Managers from '../Managers/Managers';
import TeamName from '../TeamName/TeamName';
import i18next from 'i18next';
import {getPlayerData} from '../../../../lib/functions/TeamHelperFunctions';
import cookies from 'js-cookie';
import {url, debug} from '../../../../config';
import {addMessage} from '../../../../lib/functions/notifications';
import NewTeam from '../NewTeam/NewTeam';
import {RemoveTeam} from "../RemoveTeam/RemoveTeam";

const TeamsHome = ({team, updateTeamHandler, user}) => {

    const [players, setPlayers] = useState([]);
    const [managers, setManagers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState({
        teamName: i18next.t("soccer_team.manage_team.select_team"),
        teamId: null,
        owner: false
    });
    const [managedTeams, setManagedTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirm, setConfirm] = useState({name: "undefined", user: "undefined"});
    const [isOpen, setIsOpen] = useState(false);
    const [dimmerActive, setDimmerActive] = useState(false);
    const [showTeam, setShowTeam] = useState(false)

    const teamLimit = () => {
        let limit = 0
        let subs = user.subscriptions
        subs.forEach(sub => {
            if (sub.role === "Team") {
                limit = limit + 5
            }
        })
        if (managedTeams.length >= limit) {
            return false
        }
        return true
    }

    const teamSelectHandler = async (data) => {
        setLoading(true);
        setSelectedTeam({teamName: data.teamName, teamId: data.teamId, owner: data.owner});
        let playerData = await getPlayerData(data.teamId, true)

        const tplayers = [];
        const tmanagers = [];
        for (const member of playerData) {
            if (member.role === "Manager")
                tmanagers.push(member);
            else if (member.role === "Player")
                tplayers.push(member);
        }
        setPlayers(tplayers);
        debug(tplayers)
        setManagers(tmanagers);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);

        const fetchTeams = async () => {

            let data = await fetch(`${url()}/api/soccer/teams`, {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({
                    "content-type": 'application/json',
                    Authorization: `Bearer ${cookies.get("session")}`
                })
            });
            let res = await data.json();
            debug(res);
            // setTeamUsers(res);

            if (res.length > 0) {
                let teams = [];
                res.forEach(team => {
                    if (team.owner || team.role === "Manager") {
                        teams.push(team);
                    }
                })
                setManagedTeams(teams);
            } else {
                debug("no teams to manage found");
            }
            setLoading(false);
        }
        fetchTeams();
    }, [])

    const removeTeamMember = (userId, name, user) => {

        setConfirm({userId: userId, name: name, user: user});
        setIsOpen(true);
    }


    const confirmedRemoval = async () => {
        try {
            let data = await fetch(`${url()}/api/soccer/teams/${selectedTeam.teamId}/members?player=${confirm.userId}&pending=true`, {
                method: 'DELETE',
                headers: new Headers({
                    Authorization: `Bearer ${cookies.get("session")}`
                })
            });
            debug(data.status);

            let array;
            let userType;

            if (confirm.user === "staff") {
                array = [...managers]
            } else {
                array = [...players]
            }
            for (let i = 0; i < array.length; i++) {
                if (array[i].playerId === confirm.userId) {
                    debug(array[i].playerId + " " + confirm.userId)
                    array.splice(i, 1);
                    if (confirm.user === "staff") {
                        setManagers(array);
                        userType = "staff"
                    }
                    if (confirm.user === "player") {
                        setPlayers(array);
                        userType = "players"
                    }
                    debug(userType)
                    addMessage(i18next.t("soccer_team.manage_team.message_delete_success", {
                        name: confirm.name,
                        member: userType
                    }), "success")
                    setIsOpen(false);
                    window.scrollTo(0, 0);
                }
            }
        } catch (error) {
            addMessage(i18next.t("soccer_team.manage_team.message_delete_error"), "warning")
            debug(error);
            setIsOpen(false);
        }
    }

    return (
        <div>
            <Header as='h1' textAlign="left">
                <Header.Content>{i18next.t("soccer_team.manage_team.title")}</Header.Content>
            </Header>
            {team && <Segment basic textAlign="left">
                <div>

                        <Dropdown text={selectedTeam.teamName}>
                            <Dropdown.Menu>
                                {managedTeams.map((item, index) =>
                                    <Dropdown.Item key={index}
                                                   onClick={() => {
                                                       setShowTeam(true)
                                                       teamSelectHandler(item)
                                                   }
                                                   }>{item.teamName}
                                    </Dropdown.Item>
                                )}
                                {teamLimit() &&
                                    <Dropdown.Item onClick={() => setDimmerActive(true)}>Create new
                                        Team...</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>

                </div>
            </Segment>}
            {showTeam &&
                <RemoveTeam
                    selectedTeam={selectedTeam}
                    setSelectedTeam={(val) => setSelectedTeam(val)}
                    managedTeams={managedTeams}
                    setManagedTeams={(val) => setManagedTeams(val)}
                    setShowTeam={(val)=>setShowTeam(val)}
                />}
            <Segment basic textAlign="left">
                {showTeam &&
                    <TeamName
                        selectedTeam={selectedTeam}
                        updateTeamHandler={data => updateTeamHandler(data)}
                    />}
            </Segment>
            {showTeam &&
                <Segment basic textAlign="left">
                    <Managers
                        managers={managers}
                        removeTeamMember={(userId, name, user) => removeTeamMember(userId, name, user)}
                        selectedTeam={selectedTeam}
                        setManagers={data => setManagers(data)}
                        loading={loading}
                    />
                </Segment>}
            {showTeam &&
                <Segment basic textAlign="left">
                    <Players
                        players={players}
                        removeTeamMember={(userId, name, user) => removeTeamMember(userId, name, user)}
                        selectedTeam={selectedTeam}
                        setPlayers={data => setPlayers(data)}
                        loading={loading}
                    />
                </Segment>}
            <Confirm
                open={isOpen}
                header={i18next.t("soccer_team.manage.removal_confirm.header", {name: confirm.name})}
                content={i18next.t("soccer_team.manage.removal_confirm.desc", {member: confirm.user})}
                onCancel={() => {
                    addMessage(i18next.t("soccer_team.manage.removal_confirm.cancel", {name: confirm.name}), "info")
                    setIsOpen(false)
                }
                }
                onConfirm={confirmedRemoval}
            />
            <NewTeam
                dimmerActive={dimmerActive}
                setDimmerActive={data => setDimmerActive(data)}
                managedTeams={managedTeams}
                setManagedTeams={(val) => setManagedTeams(val)}
            />
        </div>
    )
}

export default TeamsHome;