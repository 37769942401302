import {Button, Label, Modal} from "semantic-ui-react";
import {debug} from "../../../../config";
import {DateTime} from "luxon";

export const ViewDetails = ({showDetails, setShowDetails}) => {

    debug(showDetails)

    return (
        <Modal open={true} size="small">
            <Modal.Header>{showDetails.user.name}</Modal.Header>
            <Modal.Content>
                <div>
                    <div className="subscriptionModalRow">
                        <Label>PIN:</Label>
                        <p>{showDetails.user.pin}</p>
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Email:</Label>
                        <p>{showDetails.user.email}</p>
                    </div>
                    <div className="subscriptionModalRow">
                    <Label>Club Association:</Label>
                        <p>{showDetails.user.associated_club}</p>
                    </div>
                    <div className="subscriptionModalRow">
                    <Label>Date of Birth</Label>
                        <p>{showDetails.user.date_of_birth === null ?
                            ""
                            :
                            DateTime.fromISO(showDetails.user.date_of_birth).toLocaleString(DateTime.DATE_SHORT)}</p>
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Gender</Label>
                        <p>{showDetails.user.gender}</p>
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Primary Position</Label>
                        <p>{showDetails.user.primary_position}</p>
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Secondary Position</Label>
                        <p>{showDetails.user.secondary_position}</p>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=>setShowDetails({show: false, user: undefined})}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}