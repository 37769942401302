import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                //app
                "general.refresh": "refreshing",
                "general.loading": "Loading",
                "general.error": "Error: ",
                "general.back_button": "Back",

                //errors
                "general.generic_error": "There was an error with performing this action.",
                "google.error": "Error: Unable to login using Google. Please ensure cookies are enabled and try again.",
                "microsoft.error": "Error: Unable to login using Microsoft. Please ensure cookies are enabled and try again.",
                "facebook.error": "Error: Unable to login using Facebook. Please ensure cookies are enabled and try again.",
                "login.error.token": "Error: Unable to fetch token.",
                "login.error.teams": "Error: Unable to fetch teams",
                "results.error.invalid_data": "Unknown",

                //Cover
                "layout.header": "ViPER™",
                "layout.home": "Home",
                "layout.login": "Log in",
                "layout.signup": "Sign up",

                "general.portal_name": "ViPER Portal",
                "soccer_standard.user_results.results_title": "ViPER Player results",

                //dashboard
                "dashboard.logout": "Logout",
                "dashboard.logged_in": "Logged in as ",
                "dashboard.results": "Results",

                "dashboard.viper_header": "ViPER Player Individual",
                "dashboard.desktop_results": "View Results",
                "dashboard.pairing": "Pair Headset",

                "dashboard.viper_team_header": "ViPER Player Team",
                "dashboard.team_management": "Manage Teams",
                "dashboard.team_results": "View Team Results",

                "dashboard.viper_account_header": "Account",
                "dashboard.profile": "View Profile",
                "dashboard.subscriptions": "My Subscriptions And Memberships",

                "dashboard.starting_positions": "Starting Positions",
                "dashboard.pairHeadset": "Pair App",
                "dashboard.coach_download": "Get Started",

                //login
                "login_login": "Log in",
                "login_facebook": "Log in with Facebook",
                "login_google": "Log in with Google",
                "login_microsoft": "Log in with Microsoft",
                "login_labelEmail": "Email",
                "login_labelPass": "Password",
                "login_buttonSubmit": "Log in",
                "login_buttonForgotPass": "Forgot Password",
                "login_or": "or",
                "login_signup": "Access your free account here",

                //session results
                "general.results_header": "ViPER Player Results",
                "general.results_date": "Date",
                "general.results_score": "Score",
                "general.results.scanning": "Scanning Away From The Ball",
                "general.results.averageScanPerSecond": "Average Scans Per Second",
                "general.results.averageDecisionTime": "Average Action Time",
                "general.results.loading": "Loading",
                "general.results.no_result": "No results found!",
                "general.results.positive": "Yes",
                "general.results.negative": "No",

                "general.results.exercise.aggregate_title": "Exercise Aggregate",
                "general.results.exercise.averages_title": "Exercise Averages",
                "general.results.exercise_success": "Succeeded:",
                "general.results.exercise_scanning": "Scanning Away From The Ball:",
                "general.results.exercise_actionTime": "Action Time:",
                "general.results.exercise_scanPerSecond": "Scans Per Second",
                "general.results.exercise_getCSV": "Download Results",

                "general.results.exercise_attemptHeader": "Attempt",
                "general.results.exercise_attemptSummary": "Summary",
                "general.results.execise_exerciseHeader": "Exercise",
                "general.results.exercise_aggregateButton": "Aggregate",

                //attempt
                "general.results.attempt.legend_header": "Legend",
                "general.results.attempt.legend_player": "Selected Player",
                "general.results.attempt.legend_ally": "Team Player",
                "general.results.attempt.legend_enemy": "Opposition Player",
                "general.results.attempt.legend_vision": "Vision",
                "general.results.attempt.legend_pass": "Pass",

                "general.results.attempt.legend.tooltip_vision": "Where the player was looking, length indicates time spent looking in that direction relative to the duration of the exercise",
                "general.results.attempt.legend.tooltip_pass": "The correct path(s) to pass the ball",

                //password reset
                "general.email_password_reset.submit": "Submit",
                "general.email_password_reset.reset": "Reset Password",
                "general.email_password_reset_label": "Email",
                "general.email_password_reset.success": "If an account with this email address exists, you will receive an email with a link to reset your password.",
                "general.email_password_reset.failure": "Unable to send email to server.",
                "general.email_password_reset.no_email": "Please enter an email address.",
                "general.email_password_reset.invalid_email": "Please enter a valid email address.",

                //new password
                "general.email_new_password.error": "Please enter a new password.",
                "general.email_new_password.confirm": "Successfully changed password.",
                "general.email_new_password.invalid": "Passwords do not match.",
                "general.email_new_password.title": "New Password",
                "general.email_new_password.password_label": "Password",
                "general.email_new_password.confirmPass_label": "Confirm Password",
                "general.email_new_password.submit": "Submit",

                //signup
                "general.signup.duplicate": "This email is already registered, please use a different one.",
                "general.signup.success": "Successfully created user {{name}}, login to continue.",
                "general.signup.error": "An error has occured.",
                "general.signup.failure": "Failed to create a profile. Please ensure all fields have been filled in.",

                "general.signup.header": "Free Account Sign Up",
                "general.signup.label_fname": "First Name",
                "general.signup.fname_desc": "First Name",
                "general.signup.label_sname": "Surname",
                "general.signup.sname_desc": "Surname",
                "general.signup.label_email": "Email",
                "general.signup.email_desc": "Email",
                "general.signup.label_password": "Password",
                "general.signup.password_desc": "Password",
                "general.signup.button.submit": "Submit",

                //subscriptions
                "sub_header": "My Subscriptions and Memberships",
                "sub_table1Header": "My Subscriptions",
                "sub_table1Sub": "Subscription",
                "sub_table1Status": "Status",
                "sub_table1ValidFrom": "Valid From",
                "sub_table1ValidTo": "Valid to",
                "sub_Table1Cancel": "Terminate",

                "sub_table2Header": "My Team Memberships",
                "sub_table2Sub": "Subscription",
                "sub_table2Role": "Role",
                "sub_table2Cancel": "Leave Team",
                "sub_contactInfo": "Do you need to renew or terminate a subscription? Contact us",
                "sub_contactInfo2": " here",

                //Login
                "general.button.update": "Update",
                "general.login": "Login",
                "general.login.facebook": "Login with Facebook",
                "general.login.google": "Login with Google",
                "general.login.microsoft": "Login with Microsoft",
                "general.login.email": "Email",
                "general.login.password": "Password",
                "general.login.forgot_password": "Forgot Password",
                "general.login.loading": "Signing in...",
                "general.login.error_api": "Unable to log in - There is a problem with connecting to the server",
                "general.login.error": "Login failed. Please check your username and password.",
                "general.login.google_error": "Login failed. Google Login is required for this email address.",
                "general.login.generic_error": "There was an issue with trying to login.",

                //profile
                "general.profile.title": "My Profile",
                "general.profile.email": "Email",
                "general.profile.pin": "User PIN",
                "general.profile.firstname": "First Name",
                "general.profile.firstname_placeholder": "first name *required*",
                "general.profile.lastname": "Last Name",
                "general.profile.lastname_placeholder": "second name *required*",
                "general.profile.club": "Club Association",
                "general.profile.club_placeholder": "Your Soccer Club",
                "general.profile.update": "Update Details",
                "general.profile.dob": "Date of Birth",
                "general.profile.gender": "Gender",
                "general.profile.player_position_1": "Primary Position",
                "general.profile.player_position_2": "Secondary Position",
                "general.profile.update_text": "Updating",
                "general.profile.loading_subs": "Loading Subscriptions",
                "general.profile.loading_teams": "Loading Team Memberships",
                "general.profile.select_text": "Select...",
                "general.profile.male_gender": "Male",
                "general.profile.female_gender": "Female",
                "general.profile.loader": "Updating",
                "general.profile.message_success": "Successfully updated",
                "general.profile.message_failure": "Failed to update",
                "general.profile.message_emptyFields": "Please fill in the required fields below before you continue",

                //subscriptions
                "general.subscriptions.title": "My Subscriptions and Memberships",
                "general.subscriptions.subs.title": "My Subscriptions",
                "general.subscriptions.subs.team": "ViPER Player (Team)",
                "general.subscriptions.subs.individual": "ViPER Player (Individual)",
                "general.subscriptions.subs.coach": "ViPER Coach",
                "general.subscriptions.subs.no_data": "You currently have no subscriptions.",
                "general.subscriptions.subs.subs": "Subscription",
                "general.subscriptions.subs.status": "Status",
                "general.subscriptions.subs.valid_from": "Valid From",
                "general.subscriptions.subs.valid_to": "Valid To",
                "general.subscriptions.subs.button": "Terminate",
                "general.subscriptions.subs.terminate_header": "Terminate Subscription?",
                "general.subscriptions.subs.terminate_content": "Are you sure you want to end this subscription? The subscription will remain valid until its end date.",
                "general.subscriptions.subs.terminate_confirm": "Terminate",

                "general.subscriptions.team.title": "My Team Memberships",
                "general.subscriptions.team.no_data": "You are not currently member of any teams.",
                "general.subscriptions.team.team": "Team",
                "general.subscriptions.team.role": "Role",
                "general.subscriptions.team.button": "Leave Team",
                "general.subscriptions.team.leave_header": "Leave Team?",
                "general.subscriptions.team.leave_content": "Are you sure you want to leave this team?",
                "general.subscriptions.team.leave_confirm": "Leave",

                //logged in home
                "general.loggedinhome.purchase": "Purchase",
                "general.loggedinhome.monthSub": "Monthly",
                "general.loggedinhome.3monthSub": "3 Months",
                "general.loggedinhome.annualSub": "Annual",
                "general.loggedinhome.canceled": "The payment was cancelled. Your credit card has not been charged.",
                "general.loggedinhome.success": "Thank you for purchasing ViPER. Your payment has been collected and your new subscription features are available as part of the main menu.",

                "general.loggedinhome.failed": "Failed to get payment details, aborted purchase attempt.",
                "general.loggedinhome.unauthorised": "You are not authorized to make purchases.",

                "general.loggedinhome.coachpoint1": "Immersive Tactics Board",
                "general.loggedinhome.coachpoint2": "Works in VR and/or Windows",
                "general.loggedinhome.coachpoint3": "Remote learning capabilities",
                "general.loggedinhome.coachpoint4": "Works with one or more players at the same time",
                "general.loggedinhome.coachpoint5": "Position specific visualization",

                "general.loggedinhome.playerpoint1": "Scanning/Awareness training",
                "general.loggedinhome.playerpoint2": "Collect our performance data",
                "general.loggedinhome.playerpoint3": "Connect to your team's dashboard",
                "general.loggedinhome.playerpoint4": "Receiving and passing forward game scenarios",

                "general.loggedinhome.teampoint1": "Scanning/Awareness training",
                "general.loggedinhome.teampoint2": "Collect your team performance data",
                "general.loggedinhome.teampoint3": "Team dashboard to monitor player performance",
                "general.loggedinhome.teampoint4": "Team or Individual player upload",

                "general.loggedinhome.price.coach": "$50 per month or $500 per year",
                "general.loggedinhome.price.player": "$40 per month",
                "general.loggedinhome.price.player_unavailable": "Currently only available in USD",

                "general.loggedinhome.price.US.coach_offer": "$15 per month",
                "general.loggedinhome.price.EU.coach_offer": "€15 per month",
                "general.loggedinhome.price.UK.coach_offer": "£15 per month",
                "general.loggedinhome.price.US.coach_offer_yearly": "$97 per year",
                "general.loggedinhome.price.EU.coach_offer_yearly": "€97 per year",
                "general.loggedinhome.price.UK.coach_offer_yearly": "£97 per year",

                "general.loggedinhome.downloadfree": "Download the FREE app and try it out for yourself!",

                "general.loggedinhome.mac_header": "Mac",
                "general.loggedinhome.mac_desc1": "Click",
                "general.loggedinhome.mac_desc2": "to download the Mac App, then drag the .dmg file into your applications folder.",
                "general.loggedinhome.mac_help": "Having trouble opening it? Click here",

                "general.loggedinhome.mac.help_modal1": "If you are having trouble with running the app, please make sure in your settings that apps from sources\n" +
                    "            other than the app store are enabled, which you can locate in System Preferences -> Security and Privacy -> General" +
                    "            It should look something like this:",
                "general.loggedinhome.mac.help_modal2": "In addition to this, you may need to navigate to this same window to allow the app the open, as shown\n" +
                    "                below:",

                //team management
                "soccer_team.manage_team.title": "Manage Teams",
                "soccer_team.manage_team.select_team": "Select Team to Manage",
                "soccer_team.manage_team.no_data": "No manageable teams found!",
                "soccer_team.manage_team.team_name": "Team Name",
                "soccer_team.manage_team.staff": "Staff",
                "soccer_team.manage_team.staff.no_data": "The team has currently no additional staff.",
                "soccer_team.manage_team.staff.name": "Name",
                "soccer_team.manage_team.staff.status": "Status",
                "soccer_team.manage_team.players": "Players",
                "soccer_team.manage_team.players.no_data": "The team has currently no players.",
                "soccer_team.manage_team.managers.name": "Players",
                "soccer_team.manage_team.managers.status": "Status",
                "soccer_team.manage_team.message_success": "Successfully updated team name. Refresh required for page to update.",
                "soccer_team.manage_team.message_error": "An error occured when trying to update team name.",
                "soccer_team.manage_team.message_invalid": "Please enter a name.",
                "soccer_team.manage_team.message_delete_success": "Successfully removed {{name}} from {{member}}",
                "soccer_team.manage_team.message_delete_error": "Failed to remove team member",

                "soccer_team.manage_team.add_staff": "Add New Staff",
                "soccer_team.manage_team.add_player": "Add New Player",
                "soccer_team.manage_team.submit": "Submit",
                "soccer_team.manage_team.cancel": "Cancel",

                "soccer_team.add.invalid_email": "Invalid Email.",
                "soccer_team.add.success": "An invitation has been sent to this email address to invite this {{memberType}}.",
                "soccer_team.add.failure.403": "cannot add this {{memberType}}. Ensure this user has an existing profile with this email and is not already in this team.",
                "soccer_team.add.failure.404": "Unable to add {{memberType}}, please check they have an existing profile.",
                "soccer_team.add.general_error": "An error occured when trying to add {{memberType}}.",

                "soccer_team.add.header": "Add a {{memberType}}",
                "soccer_team.add.instructions": "Enter the email address of a registered {{memberType}} to add them to the team.",

                "soccer_team.create_team_dimmer_header": "Create New Team",
                "soccer_team.create_team_desc": "Enter the name for the new team then click create to continue.",
                "soccer_team.create_team.label": "Name",
                "soccer_team.create_team.placeholder": "Team Name",
                "soccer_team.create_team.submit": "Create",
                "soccer_team.create_team.cancel": "Cancel",

                "soccer_team.manage.removal_confirm.header": "Comfirm removal of {{name}}",
                "soccer_team.manage.removal_confirm.desc": "Are you sure you wish to remove them from {{member}}?",
                "soccer_team.manage.removal_confirm.cancel": "Cancelled action to remove {{name}}",

                //team results
                "soccer_team.results.title": "Team Results",
                "soccer_team.results.select_team": "Select Team to Manage",
                "soccer_team.results.playerSelect_title": "Players",
                "soccer_team.results.select_placeholder": "Select Players",
                "soccer_team.results.desc": "Select a player with session results.",

                "soccer_team.results.table.header_player": "Player",
                "soccer_team.results.table.header_date": "Date",
                "soccer_team.results.table.header_score": "Score",
                "soccer_team.results.table.header_scanning": "Scanning Away From The Ball",
                "soccer_team.results.table.header_average_scan": "Average Scans Per Second",
                "soccer_team.results.table.header_average_decision": "Average Action Time",

                "soccer_team.results.button.show_results": "Show individual results",
                "soccer_team.results.button.show_comparison": "Show comparison table",
                "soccer_team.results.button.add_to_comparison": "Add to compare",

                //time Range
                "soccer_team.results.time_from": "From Date:",
                "soccer_team.results.time_to": "To Date:",
                "soccer_team.results.time_error": "Enter a date to From Date that is earlier than To Date",

                //comparison table
                "soccer_comparison.table_date": "Date",
                "soccer_comparison.table_score": "Score",
                "soccer_comparison.table_scanning": "Scanning Away From The Ball",
                "soccer_comparison.table_average_scan": "Average Scans Per Second",
                "soccer_comparison.table_average_decision": "Average Action Time",
                "soccer_comparison.table_success": "Succeeded",
                "soccer_comparison.table_scan": "Scans Per Second",
                "soccer_comparison.table_action_time": "Action Time",

                "soccer_comparison.button_CSV": "Download Results",
                "soccer_comparison.button_graph": "Show Graph",
                "soccer_comparison.button_removeItem": "Remove",
                "soccer_comparison.header.graph": "Graph",
                "soccer_comparison.button_closeGraph": "Close",
                "soccer_comparison.graph.exercise": "Exercise: ",
                "soccer_comparison.graph.attempt": "Attempt: ",

                //comparison dropdown
                "dropdown.attempt_summary": "Attempt: Summary",
                "dropdown.attempt_num": "Attempt: ",
                "dropdown.exercise_aggregate": "Exercise: Aggregate",
                "dropdown.exercise_num": "Exercise: ",
                "dropdown.attempt_menuItem": "Attempt ",
                "dropdown.exercise_menuItem": "Exercise ",
                "dropdown.exercise_menuItem_aggregate": "Aggregate",
                "dropdown.attempt.menuItem_summary": "Summary",

                //compareResults
                "soccer_comparison.no_data": "Unable to find any players to compare with!",
                "soccer_comparison.select_success": "Session selected.",
                "soccer_comparison.add_success": "Session added.",
                "soccer_comparison.select_remove": "Session removed.",
                "soccer_comparison.select_duplicate": "This session has already been selected.",
                "soccer_comparison.comparison_add": "Session added to comparison table",
                "soccer_comparison.comparison_duplicate": "This session has already been added to comparison table.",
                "soccer_comparison.comparison_no_data": "No data to compare with",
                "soccer_comparison.generic_error_message": "An error has occured, unable to remove",
                "soccer_comparison.graph_error": "An error has occured, unable to view graph.",
                "soccer_comparison.no_data_found": "No data found!",
                "soccer_comparison.error_data": "Error! No data!",

                //pairing
                "general.pairing.success": "Success",
                "general.pairing.unauthorised": "Not authorised",
                "general.pairing.invalid": "Code invalid or expired",
                "general.pairing.error": "Error: ",
                "general.pairing.charWarning": "Please enter 6 characters",

                "general.pairing.header": "Pair Headset",
                "general.pairing.subInstructions": "Enter the pairing code displayed in the headset:",
                "general.pairing.instructions": "When you enter your PIN on a headset not paired to your ViPER account, a pairing code will be displayed in the headset. After successfully entering that code here you need only enter your PIN on that headset in future.",

                //logged_in_home
                "general.loggedinhome.payment_true": "Payment successful!",
                "general.loggedinhome.payment_false": "Payment cancelled!",

                "coach.getStarted.heading": "Get Started",
                "coach.getStarted.windows.heading": "Windows",
                "coach.getStarted.windows.desc1": "Click ",
                "coach.getStarted.windows.desc2": "here",
                "coach.getStarted.windows.desc3": ' to download the desktop App, then run "ViPER Coach.exe" from the folder.',
                "coach.getStarted.meta.heading": "Meta (Virtual Reality)",
                "coach.getStarted.oculus.heading": "Oculus",
                "coach.getStarted.oculus.desc1": "Visualize ViPER Coach on the Meta Quest 2 VR headset.",
                "coach.getStarted.oculus.desc2": "Input 'ViPER Coach' in the search bar, inside your headset, and it will appear under the 'App Lab' section. Simply follow the instructions to download and install onto your headset.",
                "coach.getStarted.tutorial.heading": "Tutorials",
                "coach.getStarted.notice.heading": "Important Notice:",
                "coach.getStarted.notice.desc1": "The download is an open platform and may have multiple users inside the virtual space at the same time which can cause interference.",
                "coach.getStarted.notice.desc2": "For your own channel and personal use please purchase one of our low-cost ViPER Coach subscriptions.",

                //starting positions
                "coach.startingPositons.no_sets": "No Playersets found,",
                "coach.startingPositions.legend.header": "Legend",

                "coach.startingPositions.legend.home": "Home Team",
                "coach.startingPositions.legend.away": "Away Team",
                "coach.startingPositions.legend.gk": "Goalkeepers",
                "coach.startingPositions.legend.ball": "Ball",

                //coach pairheadset
                "coach.pairApp.header": "Pair App",
                "coach.pairApp.subInstructions": "Enter the pairing code displayed in the App:",
                "coach.pairApp.instructions": "When you enter your PIN on the app when not paired to your ViPER account, a pairing code will be displayed in the app. After successfully entering that code here you need only enter your PIN in the app in future.",

                "coach.pairApp.success": "Success",
                "coach.pairApp.unauthorised": "Not authorised",
                "coach.pairApp.invalid": "Code invalid or expired",
                "coach.pairApp.error": "Error: ",
                "coach.pairApp.charWarning": "Please enter 6 characters",

                //upload games
                "coachElite.upload.success": "File has been successfully uploaded, an email will be sent to you when it has been processed.",
                "coachElite.upload.failure": "Unable to upload files.",
                "coachElite.upload.header": "Upload Games",
                "coachElite.upload.content1": "This page allows upload of live game data, which then can be converted to ViPER Coach sequences. Supported upload formats include data from",
                "coachElite.upload.content.secondSpectrumUrl": "Second Spectrum",
                "coachElite.upload.content2": "or data in ViPER Coach game data format. The game data files must by packed to ZIP prior to upload.",
                "coachElite.upload.uploadTypeDefault": "Select upload type",
                "coachElite.upload.secondSpectrumFormat": "Sample game data file in Second Spectrum format",
                "coachElite.upload.pixelmillFormat": "Sample game data file in Pixelmill game data format",

                //view upload games
                "coachElite.viewUploadGames.header": "View Uploaded Games",
                "coachElite.viewUploadGames.refresh": "Refresh",
                "coachElite.viewUploadGames.table.fileName": "File Name",
                "coachElite.viewUploadGames.table.gameName": "Game Name",
                "coachElite.viewUploadGames.table.description": "Description",
                "coachElite.viewUploadGames.table.processStatus": "Process Status",
                "coachElite.viewUploadGames.table.modify": "Modify",

                //view game sequences
                "coachElite.viewGameSequences.refresh": "Refresh",
                "coachElite.viewGameSequences.header": "View Game Sequences",
                "coachElite.viewGameSequences.table.name": "Name",
                "coachElite.viewGameSequences.table.description": "Description",
                "coachElite.viewGameSequences.table.processStatus": "Process Status",
                "coachElite.viewGameSequences.table.tags": "Tags",
                "coachElite.viewGameSequences.table.edit": "Edit",
                "coachElite.viewGameSequences.optionRemove": "Remove Sequence",
                "coachElite.viewGameSequences.optionEdit": "Edit tags",

            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18next;
