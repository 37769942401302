import {useState} from "react";
import {Button, Confirm} from "semantic-ui-react";
import {url, debug} from "../../../../config";
import cookies from "js-cookie";
import './RemoveTeam.css'
import {addMessage} from "../../../../lib/functions/notifications";

export const RemoveTeam = ({selectedTeam, setSelectedTeam, setShowTeam, managedTeams, setManagedTeams}) => {

    const [isOpen, setIsOpen] = useState(false)

    const submitHandler = async () => {
        try {
            let data = await fetch(`${url()}/api/soccer/teams/${selectedTeam.teamId}`, {
                method: "DELETE",
                headers: new Headers({
                    "content-type": "application/json",
                    'authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (data.status === 204) {
                addMessage(`Successfully deleted ${selectedTeam.teamName}`, "success")
                await setSelectedTeam("empty")
                let arr = managedTeams
                for (let i = 0; i < arr.length; i++) { //find and remove entry manually to prevent having to call API
                    if (arr[i].teamId === selectedTeam.teamId) {
                        arr.splice(i, 1);
                        setManagedTeams(arr)
                    }
                }
                setShowTeam(false)
            } else {
                addMessage(`Unable to delete ${selectedTeam.teamName}`, "warning")
            }
        } catch (e) {
            debug(e)
            addMessage(`Failed to delete ${selectedTeam.teamName}: ${e}`)
        } finally {
            setIsOpen(false)

        }
    }

    return (
        <div className="removeTeamButton">
            {selectedTeam.owner &&
                <Button onClick={() => setIsOpen(true)}>Remove Team</Button>}
            <div>
                <Confirm
                    open={isOpen}
                    onCancel={() => setIsOpen(false)}
                    onConfirm={submitHandler}
                    header={`Confirm deletion of ${selectedTeam.teamName}`}
                    content={`Are you sure you wish to remove ${selectedTeam.teamName}? This action cannot be undone.`}
                />
            </div>
        </div>
    )
}