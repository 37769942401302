import DatePicker from "react-datepicker";
import { Label } from "semantic-ui-react";
import i18next from "i18next";
import '../TimeRange/TimeRange.css'

const TimeRange = ({ fromDate, setFromDate, toDate, setToDate }) => {



    return (
        <div className="timeRange">
            <div>
                <Label size="small">{i18next.t("soccer_team.results.time_from")}</Label>
                <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
            <div>
                <Label size="small">{i18next.t("soccer_team.results.time_to")}</Label>
                <DatePicker
                    selected={toDate}
                    onChange={date => setToDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        </div >
    )
}

export default TimeRange;