import cookies from 'js-cookie';
import {debug, url} from '../../config';

export const teamFetch = async () => {

    try {
        let data = await fetch(`${url()}/api/soccer/teams`, {
            method: "GET",
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${cookies.get("session")}`
            })
        })
        let res = await data.json();

        return res;

    } catch (error) {
        debug(error)
        return error;
    }
}

export const sessionTokenFetch = async () => {

    try {
        let data = await fetch(`${url()}/auth/token_signin`, {
            method: "POST",
            headers: new Headers({
                "content-type": "application/json",
                // "cookie": `access_token=${cookies.get("user")}`
                'Authorization': `Bearer ${cookies.get("user")}`
            }) //will be changed back to using cookie header when this is fixed backend
        })
        if (data.status === 200) {
            let res = await data.json();
            res.status = 200;
            return res;
        }

    } catch (error) {
        debug({ error });
        return error;
    }
}


export const sortProfileValues = (arrayOfValues) => {
    arrayOfValues.forEach(val => {
        if (val === null) {
            val = "";
        }

    })
}

export const basicEmailCheck = (email) => {
    for (let i = 0; i < email.length; i++) {
        if (email[i] === "@") {
            return true;
        }
    }
    return false;
}

export const fetchUserData = async (userId) => {

    let data = await fetch(`${url()}/auth/user/${userId}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${cookies.get('session')}`
        }
    });
    let res = await data.json();
    return res;
}

export const userDataObj = (data) => {
    const obj = {
        name: data.name,
        pin: data.pin,
        id: data.id,
        email: data.email,
        associateClub: data.associated_club,
        features: data.features,
        gender: data.gender,
        primary_position: data.primary_position,
        secondary_position: data.secondary_position,
        date_of_birth: data.date_of_birth,
        subscriptions: data.subscriptions,
        isAdmin: data.admin
    }
    return obj;
}