import React, { useEffect, useMemo, useState } from 'react';
import cookies from 'js-cookie';
import { useTable } from 'react-table'
import { DateTime } from 'luxon';
import Result from '../Result/Result'
import './SessionResultsSummary.css'
import i18next from 'i18next';
import { url, debug } from '../../../config';

import {
    Header,
    Table,
    Button,
    Loader
} from "semantic-ui-react";

let SessionResult = {
    'averageScanningPercentage': '',
    'datePosted': '',
    'outOf': 0,
    'averageScanningfrequency': 0.0,
    'score': 0,
    'sessionId': 0,
    'AverageDecisionTime': 0.0
}

// Because of some deprecated stuff I don't understand, we have to do this.
// const RefButton = React.forwardRef((props, ref) => (
//     <div>
//         <button className={'ui button'} {...props} ref={ref} />
//     </div>
// ))

function getBrowserLocales(options = {}) {
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly
            ? trimmedLocale.split(/[-_]/)[0]
            : trimmedLocale;
    });
}

function SessionResultsSummary() {
    const [sessionResults, setSessionResults] = useState([SessionResult]);
    const [numOfPages, setNumOfPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSessionId, setSelectedSessionId] = useState(undefined);
    const [validData, setValidData] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const browserLocale = getBrowserLocales()[0];

    const tableButtons = (pages) => {
        let buttonArray = []

        for (let i = 0; i < pages; i++) {
            buttonArray.push(i);
        }
        setNumOfPages(buttonArray);
    }

    const data = useMemo(
        () => sessionResults,
        [sessionResults]
    );

    const columns = useMemo(
        () => [
            {
                Header: `${i18next.t("general.results_date")}`,
                accessor: 'datePosted',
                Cell: ({ cell: { value } }) => {
                    let posted = DateTime.fromISO(value);
                    return (<>{
                        posted.setLocale(browserLocale).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                    }</>)
                }
            },
            {
                Header: `${i18next.t("general.results_score")}`,
                accessor: 'score',
                Cell: ({ cell: { row } }) => {
                    return <>{row.values.score + '/' + row.original.outOf}</>
                },
                // style: { textAlign: 'center'}
            },
            //originally "average scan %"
            {
                Header: `${i18next.t("general.results.scanning")}`,
                accessor: 'averageScanningPercentage',
                Cell: ({ cell: { value } }) => {
                    return (<>{
                        new Intl.NumberFormat(browserLocale, { maximumSignificantDigits: 3 }).format(value) + "%"
                    }</>)
                },
                // style: { textAlign: 'right' }
            },
            {
                Header: `${i18next.t("general.results.averageScanPerSecond")}`,
                accessor: 'averageScanningFrequency',
                Cell: ({ cell: { value } }) => {
                    return (<>{
                        new Intl.NumberFormat(browserLocale, { maximumSignificantDigits: 3 }).format(value)
                    }</>)
                },
                // style: { textAlign: 'center' }
            },
            {
                Header: `${i18next.t("general.results.averageDecisionTime")}`,
                accessor: 'averageDecisionTime',
                Cell: ({ cell: { value } }) => {
                    return (<>{
                        new Intl.NumberFormat(browserLocale, { maximumSignificantDigits: 3 }).format(value) + "s"
                    }</>)
                },
                // style: { textAlign: 'right' }
            },
        ],
        [browserLocale]
    );

    const {
        getTableProps,
        getTableBodyProps,
        // headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });


    useEffect(() => {

        fetch(`${url()}/api/soccer/results?page=${currentPage}&per_page=5`, {
            method: 'GET',
            mode: "cors",
            // credentials: 'include',
            headers: new Headers({
                "content-type": 'application/json',
                // 'cookie': `access_token=${cookies.get("session")}`
                Authorization: `Bearer ${cookies.get("session")}`
            })
        })

            .then(res => res.json())
            .then(response => {
                // debug(response);
                // response = null;
                tableButtons(response.pages);
                debug(response.data)
                setLoaded(true);
                if (Object.keys(response.data) !== 0) {
                    setSessionResults(response.data);
                    debug(response.data)
                    if (response.data.length > 0) {
                        setValidData(true);
                    }

                } else {
                    debug(response.data)
                    setValidData(false);
                }
            })
            .catch(error => debug(error));
    }, [currentPage]);

    return (
        <div>
            <Header as='h1' textAlign='left'>
                <Header.Content>{i18next.t("general.results_header")}</Header.Content>
            </Header>
            <div className="loading">
                {!loaded &&
                    <Loader active>{i18next.t("general.results.loading")}</Loader>
                }
            </div >
            {validData &&
                <Table unstackable selectable {...getTableProps()}>
                    <Table.Header className="tableCenter">
                        {/*{headerGroups.map(headerGroup => (*/}
                        {/*    <Table.Row {...headerGroup.getHeaderGroupProps()}>*/}
                        {/*        {headerGroup.headers.map(column => (*/}
                        {/*            <Table.HeaderCell {...column.getHeaderProps({ textAlign: 'center' })}>*/}
                        {/*                {column.render('Header')}*/}
                        {/*            </Table.HeaderCell>*/}
                        {/*        ))}*/}
                        {/*    </Table.Row> */}
                        {/*))}*/}
                        <Table.HeaderCell>{i18next.t("general.results_date")}</Table.HeaderCell>
                        <Table.HeaderCell>{i18next.t("general.results_score")}</Table.HeaderCell>
                        <Table.HeaderCell>{i18next.t("general.results.scanning")}</Table.HeaderCell>
                        <Table.HeaderCell>{i18next.t("general.results.averageScanPerSecond")}</Table.HeaderCell>
                        <Table.HeaderCell>{i18next.t("general.results.averageDecisionTime")}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body className="tableCenter" {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <Table.Row className={selectedSessionId === row.original.sessionId ? "selected" : "notSelected"} onClick={() => setSelectedSessionId(row.original.sessionId)} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <Table.Cell {...cell.getCellProps(cell.column.style ?? '')}>
                                                {cell.render('Cell')}
                                            </Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                    <Table.Footer className="tableTest4" fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                {numOfPages.map(page => {
                                    return (
                                        <Button key={page} onClick={() => setCurrentPage(page + 1)} className={currentPage === page + 1 ? "active" : ""}  >{page + 1}</Button>
                                    )
                                })}

                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            }
            {loaded === true && validData === false &&
                <p>{i18next.t("general.results.no_result")}</p>
            }

            {selectedSessionId !== undefined &&
                <Result sessionId={selectedSessionId} />
            }
        </div>
    )
}

export default SessionResultsSummary;