import {useEffect, useState} from "react";
import './ViewUploadedGames.css'
import {url} from "../../../config";
import cookies from "js-cookie";
import {Button, Dropdown, Header, Loader, Menu, Table} from "semantic-ui-react";
import {addMessage} from "../../../lib/functions/notifications";
import i18next from "i18next";
import {SequenceModal} from "../../modals/SequenceModal";
import {DeleteModal} from "../../modals/DeleteModal";
import {DescriptionModal} from "../../modals/DescriptionModal";

export const ViewUploadedGames = () => {

    const [userUploads, setUserUploads] = useState([])
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(undefined)
    const [openModal, setOpenModal] = useState({description: false})


    const getUserUploads = async () => {
        setLoading(true)
        try {
            const data = await fetch(`${url()}/coach/games`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            const res = await data.json()
            setUserUploads(res)
        } catch (e) {
            addMessage(`Unable to fetch uploads: ${e.message}`)
        } finally {
            setLoading(false)
        }
    }

    const handleItemSelect = (data, modalType) => {
        setActive(data)
        if (modalType === "sequence") {
            setOpenModal({sequence: true})
        }
        if (modalType === "description") {
            if (data.status !== 'Complete') {
                addMessage(`Unable to select: ${data.file_name} status is ${data.status}`, 'warning')
                return
            }
            setOpenModal({description: true})
        }
        if (modalType === "delete") {
            setOpenModal({delete: true})
        }
    }


    useEffect(() => {
        getUserUploads()
    }, [])

    if (loading) {
        return <Loader id='uploadLoader' active>{i18next.t("general.refresh")}</Loader>
    } else {
        return (
            <div>
                <Header as="h1">View Uploaded Games</Header>
                <Button onClick={() => getUserUploads()}>{i18next.t("coachElite.viewUploadGames.refresh")}</Button>
                {userUploads.length > 0 ?
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{i18next.t("coachElite.viewUploadGames.table.fileName")}</Table.HeaderCell>
                                <Table.HeaderCell>{i18next.t("coachElite.viewUploadGames.table.gameName")}</Table.HeaderCell>
                                <Table.HeaderCell>{i18next.t("coachElite.viewUploadGames.table.description")}</Table.HeaderCell>
                                <Table.HeaderCell>{i18next.t("coachElite.viewUploadGames.table.processStatus")}</Table.HeaderCell>
                                <Table.HeaderCell>{i18next.t("coachElite.viewUploadGames.table.modify")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {userUploads.map(row =>
                                <Table.Row
                                    className={active === row.id ? "selected" : "notSelected"}
                                    disabled={row.status === 'Pending' || row.status === 'Processing'}
                                    key={row.id}
                                >
                                    <Table.Cell>{row.file_name}</Table.Cell>
                                    <Table.Cell>{row.category === null ? "N/A" : row.category}</Table.Cell>
                                    <Table.Cell>{row.description}</Table.Cell>
                                    <Table.Cell>{row.status}</Table.Cell>
                                    <Table.Cell>
                                        <Menu compact>
                                            {
                                                <Dropdown
                                                    item
                                                    text="Select Option"
                                                    disabled={row.status === 'Pending' || row.status === 'Processing'}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => handleItemSelect(row, "description")}>Edit
                                                            Description</Dropdown.Item>
                                                        {row.status !== 'Failed' &&
                                                            <Dropdown.Item
                                                                onClick={() => handleItemSelect(row, "sequence")}>Create
                                                                Sequence</Dropdown.Item>}
                                                        <Dropdown.Item onClick={() => handleItemSelect(row, "delete")}>Delete
                                                            Game</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </Menu>
                                    </Table.Cell>
                                </Table.Row>
                            )
                            }
                        </Table.Body>
                    </Table>
                    :
                    <p>No data found.</p>
                }
                {openModal.description &&
                    <DescriptionModal
                        active={active}
                        setActive={(val) => setActive(val)}
                        setLoading={(val) => setLoading(val)}
                        setOpenModal={(val) => setOpenModal(val)}
                    />

                }
                {openModal.sequence &&
                    <SequenceModal
                        active={active}
                        setOpenModal={(val) => setOpenModal(val)}
                    />
                }
                {openModal.delete &&
                    <DeleteModal
                        active={active}
                        setOpenModal={(val) => setOpenModal(val)}
                        loading={loading}
                        setLoading={(val) => setLoading(val)}
                        runFunction={() => getUserUploads()}
                    />
                }
            </div>
        )
    }
}