import {Button, Modal} from "semantic-ui-react";
import {url} from "../../config";
import cookies from "js-cookie";
import {addMessage} from "../../lib/functions/notifications";

export const DeleteModal = ({active, setOpenModal, runFunction}) => {

    const deleteGame = async () => {
        try {
            let res = await fetch(`${url()}/coach/game/${active.id}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (res.status === 200) {
                addMessage("Sequence successfully deleted", "success")
                setOpenModal(false)
            } else {
                let error = await res.json()
                throw error
            }
        } catch (e) {
            addMessage(`Error: Game doesn't exist`, 'warning')
        } finally {
            if (runFunction !== undefined) {
                runFunction()
            }
        }
    }

    return (
        <Modal
            size="tiny"
            open
        >
            <Modal.Header>
                Confirm deleting {active.category}
            </Modal.Header>
            <Modal.Content>
                Are you sure you wish to delete {active.category}? This can't be undone.
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => deleteGame()}>Delete</Button>
                <Button onClick={() => setOpenModal({delete: false})}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}