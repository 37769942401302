import {Button, Dimmer, Segment} from "semantic-ui-react";
import './ImgView.css'

export const ImgView = ({setShowImage, showImage}) => {

    return (
        <Dimmer active={true} page>
            <Segment id="imgSegment">
                <img id="imgView"
                     onClick={() => setShowImage({show: false, src: undefined, alt: undefined})}
                     src={showImage.src}
                     alt={showImage.alt}
                />
                <div>
                    <Button id="imgButton" onClick={() => setShowImage({show: false, src: undefined})}>Close</Button>
                </div>
            </Segment>
        </Dimmer>
    )
}