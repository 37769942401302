import {useEffect, useState} from 'react';
import PlayerSelect from '../PlayerSelect/PlayerSelect';
import TimeRange from '../TimeRange/TimeRange';
import cookies from 'js-cookie';
import {DateTime} from 'luxon';
import {getPlayerData, teamsUserManages} from '../../../../lib/functions/TeamHelperFunctions'
import {Dropdown, Segment, Header, Button, Icon} from 'semantic-ui-react';
import i18next from 'i18next';
import ResultsList from '../ResultsList/ResultsList';
import {getTeamPlayers} from '../../../../lib/functions/TeamHelperFunctions';
import {addMessage} from '../../../../lib/functions/notifications';
import '../TeamResultsMain/TeamResultsMain.css'
import {url} from '../../../../config';

const TeamResultsMain = ({team}) => {

    const [teamPlayers, setTeamPlayers] = useState();
    const [players, setPlayers] = useState();
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(i18next.t("soccer_team.results.select_team"));
    const [teamId, setTeamId] = useState(0);
    const [allResults, setAllResults] = useState([]);
    const [numOfPages, setNumOfPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [loadedPlayers, setLoadedPlayers] = useState(false);
    const [fromDate, setFromDate] = useState(new Date(2020, 0, 1));
    const [toDate, setToDate] = useState(new Date());
    const [showTimeRange, setShowTimeRange] = useState(false);

    useEffect(() => { //reset to default time values if filter is closed
        if (!showTimeRange) {
            setFromDate(new Date(2020, 0, 1))
            setToDate(new Date(Date.now() + (3600 * 1000 * 24)))
        }
    }, [showTimeRange])

    const tableButtons = (pages) => {
        let buttonArray = [];

        for (let i = 0; i < pages; i++) {
            buttonArray.push(i);
        }
        setNumOfPages(buttonArray);
    }

    useEffect(() => {
        //update selected players array
        if (teamPlayers !== undefined) {
            let arr = [];
            for (let i = 0; i < teamPlayers.length; i++) {
                if (teamPlayers[i].active === true)
                    arr.push(teamPlayers[i])
            }
            setSelectedPlayers(arr);
        }

    }, [teamPlayers])

    useEffect(() => {

        const results = async () => {

            if (fromDate.getTime() >= toDate.getTime()) {
                addMessage(i18next.t("soccer_team.results.time_error"), "warning");
                return;
            }

            if (selectedPlayers.length > 0) {

                let playerids = selectedPlayers.map(val => val.playerId);
                playerids.join();

                const timeStart = DateTime.fromJSDate(fromDate).toISODate()
                const timeEnd = DateTime.fromJSDate(toDate).toISODate()

                let data = await fetch(`${url()}/api/soccer/results?page=${currentPage}&per_page=5&players=${playerids}&timeStart=${timeStart}&timeEnd=${timeEnd}&team=${teamId}`, {
                    method: "GET",
                    headers: new Headers({
                        "content-type": 'application/json',
                        // 'cookie': `access_token=${cookies.get("session")}`
                        Authorization: `Bearer ${cookies.get("session")}`
                    })
                });
                let res = await data.json();

                tableButtons(res.pages);
                setAllResults(res.data);
                // debug(res.data);
            }
        }
        results();

    }, [selectedPlayers, currentPage, fromDate, toDate, teamId])

    useEffect(() => {

        if (teamPlayers !== undefined) {

            const playerOptions = () => {
                let team = getTeamPlayers(teamPlayers);
                let players = team.map(player => ({
                    data: player,
                    key: player.playerId,
                    text: player.playerName,
                    value: player.playerId
                }));
                setPlayers(players);
                setLoadedPlayers(true);
            }
            playerOptions()
        }

    }, [teamPlayers])

    const teamSelectHandler = async (data) => {

        setSelectedTeam(data.teamName);
        let playerData = await getPlayerData(data.teamId)
        setTeamId(data.teamId)
        setTeamPlayers(playerData);
    }

    return (
        <div>
            <Header as="h1">{i18next.t("soccer_team.results.title")}</Header>
            {selectedTeam &&
                <Segment basic textAlign="left">
                    <Dropdown text={selectedTeam}>
                        <Dropdown.Menu>
                            {teamsUserManages(team).map((item, index) =>
                                <Dropdown.Item key={index}
                                               onClick={() => teamSelectHandler(item)}>{item.teamName}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Segment>}

            {teamsUserManages(team).length > 0 ?
                <Segment basic textAlign="left">
                    <PlayerSelect team={team}
                                  teamPlayers={teamPlayers}
                                  setTeamPlayers={data => setTeamPlayers(data)}
                                  selectedTeam={selectedTeam}
                                  players={players}
                    />
                </Segment>
                :
                <Segment>
                    <p>{i18next.t("soccer_comparison.no_data")}</p>
                </Segment>}

            {loadedPlayers &&
                <Segment basic>
                    <div className="row">
                        {allResults &&
                            <Button icon active={showTimeRange} onClick={() => setShowTimeRange(!showTimeRange)}><Icon
                                name="filter"/></Button>}
                        {showTimeRange && <TimeRange
                            fromDate={fromDate}
                            toDate={toDate}
                            setFromDate={date => setFromDate(date)}
                            setToDate={date => setToDate(date)}
                            showTimeRange={showTimeRange}
                        />}
                        {/* <br /> */}
                    </div>
                    <ResultsList allResults={allResults}
                                 setAllResults={data => setAllResults(data)}
                                 setCurrentPage={data => setCurrentPage(data)}
                                 currentPage={currentPage}
                                 teamPlayers={teamPlayers}
                                 pagination={numOfPages}
                                 fromDate={fromDate}
                                 setFromDate={date => setFromDate(date)}
                                 toDate={toDate}
                                 setToDate={date => setToDate(date)}
                    />
                </Segment>
            }
        </div>
    )
}

export default TeamResultsMain;