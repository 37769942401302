import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import i18next from 'i18next';
import {
    Button,
    Divider,
    Grid,
    Header,
    Icon,
    Input,
    Menu,
} from "semantic-ui-react";

import "./Dashboard.css";
import {debug} from "../config";


const Dashboard = ({children, user, team, logout}) => {

    const [display, setDisplay] = useState("none");
    const [menu, setMenu] = useState({session_results: true});
    const [playerFeatures, setPlayerFeatures] = useState(false);
    const [teamFeatures, setTeamFeatures] = useState(false);
    const [managerFeatures, setManagerFeatures] = useState(false);
    const [coachFeatures, setCoachFeatures] = useState(false);
    const [coachEliteFeatures, setCoachEliteFeatures] = useState(false);
    const [adminFeatures, setAdminFeatures] = useState(false);

    let history = useHistory();

    const handleToggleDropdownMenu = () => {
        if (display === "none") {
            setDisplay("flex");
        } else {
            setDisplay("none");
        }
    }

    const handleMobMenuItemChange = (path) => {
        setDisplay("none");
        history.push(path);
    }

    useEffect(() => { //handles menu by changing the state based on path

        let path = history.location.pathname;

        if (path === "/session_results") {
            setMenu({session_results: true});
        }
        if (path === "/profile") {
            setMenu({profile: true});
        }
        if (path === "/pair_headset") {
            setMenu({pair_headset: true})
        }
        if (path === "/subscriptions") {
            setMenu({subscriptions: true})
        }
        if (path === "/team_management") {
            setMenu({teamManagement: true})
        }
        if (path === "/team_results") {
            setMenu({teamResults: true})
        }
        if (path === "/logged_in_home") {
            setMenu({loggedIn: true})
        }
        if (path === "/coach_pair_headset") {
            setMenu({coachPairHeadset: true})
        }
        if (path === "/starting_positions") {
            setMenu({startingPositons: true})
        }
        if (path === "/coach_download") {
            setMenu({coachDownload: true})
        }
        if (path === "/sequences") {
            setMenu({sequences: true})
        }
        if (path === '/upload_sequence') {
            setMenu({uploadSequences: true})
        }
        if (path === "/admin/users") {
            setMenu({adminUsers: true})
        }
        if (path === "/view_uploaded_games") {
            setMenu({viewUploadedGames: true})
        }
        if (path === "/view_game_sequences") {
            setMenu({viewGameSequences: true})
        }
    }, [history.location.pathname]);

    useEffect(() => {
        //set available menu options dependant on subscriptions/admin status
        if (user.isAdmin) {
            setAdminFeatures(true)
        }

        team.forEach(team => {
            if (team.owner === true || team.role === "Manager") {
                setTeamFeatures(true)
                setManagerFeatures(true)
            }
            if (team.role === "Player") {
                setTeamFeatures(true)
            }
        })

        let features = user.subscriptions;

        const subscriptionIsInActive = (expiry) => {
            if (expiry.toString() > new Date().toISOString()) {
                return true
            }
            return false;
        }

        if (features === undefined) {
            debug(features)
            return;
        } else {
            debug(features)
            features.forEach(element => {
                    if (element.owner === true || element.role === "Manager") {
                        // if (element.expiry.toString() < new Date().toString()) {
                        setTeamFeatures(true)
                        setManagerFeatures(true)
                        // }
                    }
                    if (element.role === "Team") {
                        setManagerFeatures(true)
                    }
                    if (element.role === "Player") {
                        setTeamFeatures(true)
                    }
                    if (element.role === "Individual" && subscriptionIsInActive(element.expiry)) {
                        setPlayerFeatures(true);
                    }
                    if (element.role === "Coach" && subscriptionIsInActive(element.expiry)) {
                        setCoachFeatures(true);
                    }
                    if (element.role === "CoachElite" && subscriptionIsInActive(element.expiry)) {
                        setCoachFeatures(true);
                        setCoachEliteFeatures(true);
                    }
                }
            )
        }

    }, [user.subscriptions, user.isAdmin, team])

    const teamMenuHandler = () => {
        if (teamFeatures || managerFeatures) {
            return false;
        }
        return false;
    } //return true if either teamFeatures or managerFeatures is true

    return (
        <>
            <div className="App">
                <Grid padded className="tablet computer only">
                    <Menu borderless inverted fluid fixed="top">
                        <Menu.Item header as="a" onClick={() => history.push("/logged_in_home")}>
                            {i18next.t("general.portal_name")}
                        </Menu.Item>
                        <Menu.Menu position="right">
                            <Menu.Item as="a"
                                       onClick={() => history.push("/profile")}>{i18next.t("dashboard.logged_in")}{`${user.name} (${user.pin})`}</Menu.Item>
                            <Menu.Item as="a" onClick={logout}>{i18next.t("dashboard.logout")}</Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Grid>
                <Grid padded className="mobile only">
                    <Menu borderless inverted fluid fixed="top">
                        <Menu.Item header as="a">
                            {i18next.t("general.portal_name")}
                        </Menu.Item>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                <Button
                                    basic
                                    inverted
                                    icon
                                    toggle
                                    onClick={handleToggleDropdownMenu}
                                >
                                    <Icon name="content"/>
                                </Button>
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu
                            borderless
                            fluid
                            inverted
                            vertical
                            style={{display: display}}
                        >
                            {/*Mobile Menu*/}
                            {playerFeatures && <Menu.Item>
                                <p className="mobileMenuTitle">{i18next.t("dashboard.viper_header")}</p>
                            </Menu.Item>}
                            {playerFeatures && <Menu.Item as="a" active={menu.session_results}
                                                          onClick={() => handleMobMenuItemChange("/session_results")}>
                                <p className="mobileItem">{i18next.t("dashboard.results")}</p>
                            </Menu.Item>}
                            {playerFeatures && <Menu.Item as="a" active={menu.pair_headset}
                                                          onClick={() => handleMobMenuItemChange("/pair_headset")}>
                                <p className="mobileItem">{i18next.t("dashboard.pairing")}</p>
                            </Menu.Item>}
                            {/*{teamMenuHandler() && <Menu.Item>

                                <p className="mobileMenuTitle">{i18next.t("dashboard.viper_team_header")}</p>
                            </Menu.Item>}
                            {managerFeatures && <Menu.Item as="a" active={menu.teamManagement}
                                                           onClick={() => handleMobMenuItemChange("/team_management")}>
                                <p className="mobileItem">{i18next.t("dashboard.team_management")}</p>
                            </Menu.Item>}
                            {teamFeatures && <Menu.Item as="a" active={menu.teamResults}
                                                        onClick={() => handleMobMenuItemChange("/team_results")}>
                                <p className="mobileItem">{i18next.t("dashboard.team_results")}</p>
                            </Menu.Item>}*/}

                            {coachFeatures && <Menu.Item>
                                <p className="mobileMenuTitle">{i18next.t("general.subscriptions.subs.coach")}</p>
                            </Menu.Item>}
                            {coachFeatures && <Menu.Item as="a" active={menu.coachDownload}
                                                         onClick={() => handleMobMenuItemChange("/coach_download")}>
                                <p className="mobileItem">Get Started</p>
                            </Menu.Item>}
                            {coachFeatures && <Menu.Item as="a" active={menu.startingPositons}
                                                         onClick={() => handleMobMenuItemChange("/starting_positions")}>
                                <p className="mobileItem">Starting Positions</p>
                            </Menu.Item>}
                            {coachFeatures && <Menu.Item as="a" active={menu.sequences}
                                                         onClick={() => handleMobMenuItemChange("/sequences")}>
                                <p className="mobileItem">Sequences</p>
                            </Menu.Item>}
                            {coachFeatures && <Menu.Item as="a" active={menu.coachPairHeadset}
                                                         onClick={() => handleMobMenuItemChange("/coach_pair_headset")}>
                                <p className="mobileItem">{i18next.t("dashboard.pairHeadset")}</p>
                            </Menu.Item>}
                            {coachFeatures && <Menu.Item>
                                <p className="mobileMenuTitle">ViPER Coach Elite</p>
                            </Menu.Item>}
                            {coachEliteFeatures && <Menu.Item as="a" active={menu.uploadSequences}
                                                         onClick={() => handleMobMenuItemChange("/upload_sequence")}>
                                <p className="mobileItem">Upload Games</p>
                            </Menu.Item>
                            }
                            {coachEliteFeatures && <Menu.Item as="a" active={menu.viewUploadedGames}
                                                         onClick={() => handleMobMenuItemChange("/view_uploaded_games")}>
                                <p className="mobileItem">View Uploaded Games</p>
                            </Menu.Item>
                            }
                            {coachEliteFeatures && <Menu.Item as="a" active={menu.viewGameSequences}
                                                              onClick={() => handleMobMenuItemChange("/view_game_sequences")}>
                                <p className="mobileItem">View Game Sequences</p>
                            </Menu.Item>
                            }
                            <Menu.Item>
                                <p className="mobileMenuTitle">{i18next.t("dashboard.viper_account_header")}</p>
                            </Menu.Item>
                            <Menu.Item as="a" active={menu.profile}
                                       onClick={() => handleMobMenuItemChange("/profile")}>
                                <p className="mobileItem">{i18next.t("dashboard.profile")}</p>
                            </Menu.Item>
                            <Menu.Item as="a" active={menu.subscriptions}
                                       onClick={() => handleMobMenuItemChange("/subscriptions")}>
                                <p className="mobileItem">{i18next.t("dashboard.subscriptions")}</p>
                            </Menu.Item>
                            <Menu.Item as="a" onClick={logout}>
                                <p className="mobileItem">{i18next.t("dashboard.logout")}</p>
                            </Menu.Item>
                            <Divider fitted/>
                            <Menu.Item>
                                <Input placeholder="Search..." size="small"/>
                            </Menu.Item>
                        </Menu>
                    </Menu>
                </Grid>
                <Grid padded>
                    <Grid.Column
                        tablet={3}
                        computer={3}
                        only="tablet computer"
                        id="sidebar"
                    >
                        {adminFeatures && <Header dividing size="medium" as="h3">
                            Admin
                        </Header>}
                        {adminFeatures && <Menu vertical borderless fluid text>
                            <Menu.Item active={menu.adminUsers} as="a"
                                       onClick={() => history.push("/admin/users")}
                            >Users</Menu.Item>
                        </Menu>}
                        {playerFeatures && <Header dividing size="medium" as="h3">
                            {i18next.t("dashboard.viper_header")}
                        </Header>}
                        {playerFeatures && <Menu vertical borderless fluid text>
                            <Menu.Item active={menu.session_results} as="a"
                                       onClick={() => history.push("/session_results")}>{i18next.t("dashboard.desktop_results")}</Menu.Item>
                            <Menu.Item active={menu.pair_headset} as="a" onClick={() => {
                                history.push("/pair_headset");
                            }}>{i18next.t("dashboard.pairing")}</Menu.Item>
                        </Menu>}
                        {teamMenuHandler() && <Header dividing size="medium" as="h3">
                            {i18next.t("dashboard.viper_team_header")}
                        </Header>}
                        {teamMenuHandler() && <Menu vertical borderless fluid text>
                            {managerFeatures && <Menu.Item active={menu.teamManagement} as="a"
                                                           onClick={() => history.push("/team_management")}>{i18next.t("dashboard.team_management")}</Menu.Item>}
                            {teamFeatures && <Menu.Item active={menu.teamResults} as="a"
                                                        onClick={() => history.push("/team_results")}>{i18next.t("dashboard.team_results")}</Menu.Item>}
                        </Menu>}
                        {coachFeatures && <Header dividing size="medium" as="h3">
                            {i18next.t("general.subscriptions.subs.coach")}
                        </Header>}
                        {coachFeatures && <Menu vertical borderless fluid text>
                            <Menu.Item active={menu.coachDownload} as="a"
                                       onClick={() => history.push("/coach_download")}>{i18next.t("dashboard.coach_download")}</Menu.Item>
                            <Menu.Item active={menu.coachPairHeadset} as="a"
                                       onClick={() => history.push("/coach_pair_headset")}>{i18next.t("dashboard.pairHeadset")}</Menu.Item>
                            <Menu.Item active={menu.startingPositons} as="a"
                                       onClick={() => history.push("/starting_positions")}>{i18next.t("dashboard.starting_positions")}</Menu.Item>
                            <Menu.Item active={menu.sequences} as="a"
                                       onClick={() => history.push("/sequences")}>Sequences</Menu.Item>
                        </Menu>}
                        {coachEliteFeatures && <Header dividing size="medium" as="h3">
                            {/* {"ViPER Coach Elite"} */}
                        </Header>}
                        {coachEliteFeatures && <Menu vertical borderless fluid text>
                            <Menu.Item active={menu.uploadSequences} as="a"
                                       onClick={() => history.push("/upload_sequence")}>Upload Games</Menu.Item>
                            <Menu.Item active={menu.viewUploadedGames} as="a"
                                       onClick={()=> history.push("/view_uploaded_games")}>View Uploaded Games</Menu.Item>
                            <Menu.Item active={menu.viewGameSequences} as="a"
                                       onClick={()=> history.push("/view_game_sequences")}>View Game Sequences</Menu.Item>
                        </Menu> }
                        <Header dividing size="medium" as="h3">
                            {i18next.t("dashboard.viper_account_header")}
                        </Header>
                        <Menu vertical borderless fluid text>
                            <Menu.Item active={menu.profile} as="a"
                                       onClick={() => history.push("/profile")}>{i18next.t("dashboard.profile")}</Menu.Item>
                            <Menu.Item active={menu.subscriptions} as="a"
                                       onClick={() => history.push("/subscriptions")}>{i18next.t("dashboard.subscriptions")}</Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        tablet={13}
                        computer={13}
                        floated="right"
                        id="content"
                    >
                        {children}
                    </Grid.Column>
                </Grid>
            </div>
        </>
    )
}

export default Dashboard;
