let apiUrl = undefined

export const url = () => { //handler to determine url used for API
    const environment = process.env.REACT_APP_PM_BUILD_TYPE;

    if (apiUrl !== undefined) {
        return apiUrl
    }

    switch (environment) {
        case "local":
            debug("local")
            apiUrl = 'http://localhost:5000'
            break
        case "dev":
            debug("dev")
            apiUrl = process.env.REACT_APP_PM_API_DEV_EC2
            break
        case "test":
            debug("test")
            apiUrl = process.env.REACT_APP_PM_API_TEST_SERVER
            break
        case "prod":
            debug("prod")
            apiUrl = process.env.REACT_APP_PM_API_PROD_EC2
            break
        default:
            debug('warning: env not found')
            //check if being run locally and change to dev, otherwise default to prod
            if (window.location.hostname === "localhost") {
                apiUrl = process.env.REACT_APP_PM_API_DEV_EC2
            } else {
                apiUrl = process.env.REACT_APP_PM_API_PROD_EC2
            }
        // apiUrl = 'http://localhost:5000' //change to use any API on local
    }
    return apiUrl
}

export const debug = (consolelog) => { //use to have logs in console which only show when not on production
    if (apiUrl !== process.env.REACT_APP_PM_API_PROD_EC2) {
        console.log(consolelog)
    }
}

export const Google = {
    CLIENT_ID: '1077433981882-jm5aiaruc0vaq03u46fg9siaphefvuof.apps.googleusercontent.com'
};

export const Facebook = {
    DEBUG_USER_TOKEN: 'OCAeNXm5Oah5KYje8MuJ5kYCJxZCn0GYmdyrJyXmlXyjiNtdZCcZBtbBzswY59FWgWXCApmrPUMrD2N0wnR5mzs9XSNZASpq4ZD',
    APP_ID: '3416764491758641',
    APP_SECRET: '399f81aed87d633fde64823280d08121',
    ACCESS_TOKEN: () => {
        return 'OC|' + Facebook.APP_ID + '|' + Facebook.APP_SECRET
    }
}

export const msalConfig = {
    auth: {
        clientId: "cfd55152-3662-40aa-9e0e-ae21b945bdf3",
        redirectUri: "localhost:3000",
        authority: "https://login.microsoftonline.com/afde6fcb-49f3-4e0a-91f0-4ac920e49613"
    }, scopes: ["user.readBasic.all"], cache: {
        cacheLocation: "sessionStorage", storeAuthStateInCookie: false,
    },
}

// export const loginRequest = {
//   scopes: ["user.read"]
// }

// export const loginRequest = {
//   scopes: [
//     "user.readbasic.all"
//   ]
// }

//test
// export const Stripe = {
//
//     KEY: 'pk_test_51IsQeNLmyktSctDBEPZheWAZ4ptiFPVwUaBjOwTGwSqk1uMN74njI0VBOrrO3ibkghiXwcx3D54yz1cnZs6MNFhr00OiusYFiK',
//     PRODUCT_VIPER_INDIVIDUAL: 'price_1J0OKuLmyktSctDBRt61Mxr8',
//     PRODUCT_VIPER_TEAM: 'price_1J0OLZLmyktSctDBkka95CQJ',
//     PRODUCT_VIPER_COACH_MONTHY: 'price_1K7j5sLmyktSctDBLoU3vHiT',
//     PRODUCT_VIPER_COACH_ANNUAL: 'price_1K7j5sLmyktSctDBTeIZd7Jv'
// }

//live
export const Stripe = {
    KEY: "pk_live_51IsQeNLmyktSctDBUpgnn0gk8H7ffTFpE2op5EGEmTYgOVDzyrc1MrWqLODHcHjtCPHvjt4l22zKKhCyk2zdB4d800vgRlMULd",
    US: {
        PRODUCT_VIPER_INDIVIDUAL: 'price_1J0OVsLmyktSctDBMzFTAyfD',
        PRODUCT_VIPER_TEAM: 'price_1J0OZ9LmyktSctDBbsiX9zQd',
        PRODUCT_VIPER_COACH_ANNUAL: 'price_1M2GGWLmyktSctDBc7gbaYlI',
        PRODUCT_VIPER_COACH_MONTHLY: "price_1OuF3tLmyktSctDBtYfEoulM",
        PRODUCT_VIPER_COACH_3_MONTH_PLAN: 'price_1KkoEkLmyktSctDBuK6G6gDa'
    },
    UK: {
        PRODUCT_VIPER_COACH_ANNUAL: 'price_1M2GFCLmyktSctDBXZ1B9U3R',
        PRODUCT_VIPER_COACH_MONTHLY: "price_1OuEcCLmyktSctDBDtDwdmwH",
        PRODUCT_VIPER_COACH_3_MONTH_PLAN: 'price_1KhYlvLmyktSctDBP6CMZyCE',
    },
    EU: {
        PRODUCT_VIPER_COACH_ANNUAL: 'price_1M2B3yLmyktSctDBqK6TQ1iF',
        PRODUCT_VIPER_COACH_MONTHLY: "price_1OuEXCLmyktSctDBhXTggphT",
        PRODUCT_VIPER_COACH_3_MONTH_PLAN: 'price_1Kko30LmyktSctDBy8nWPeFK'
    }
}
