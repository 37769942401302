import {Dropdown, Menu} from "semantic-ui-react";
import './ListOfSequences.css'

export const ListOfSequences = ({steps, setSequencePosition}) => {

    const buttonHandler = () => { //
        const numOfPages = Math.ceil(steps.length / 10) //Paginate the selectable sequences to multiples of 10
        let pages = []
        for (let i = 0; i < numOfPages; i++) {
            pages.push({page: i, maxPage: +`${i + 1}0`, sequences: []}) //maxPage is a number that is always 10 more than i
            for (let j = i * 10; j < pages[i].maxPage; j++) {
                if (j < steps.length) {                 //ensures we never have more selectable sequences than exist
                    pages[i].sequences.push(j + 1)
                }
            }
        }
        return (
            <div id="btnContainer">
                <Menu compact={true}>
                    {pages.map((page, index) =>
                        <div key={index}>
                            <Dropdown item
                                      text={page.sequences[0] + " to " + page.sequences[page.sequences.length - 1]}>
                                <Dropdown.Menu>
                                    {pages[index].sequences.map(btn =>
                                        <Dropdown.Item key={btn}
                                                       onClick={() => setSequencePosition(btn - 1)}>{btn}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </Menu>
            </div>
        )
    }
    return buttonHandler()
}