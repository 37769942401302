import { useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Label, Input, Header } from 'semantic-ui-react'
import i18next from 'i18next';
import { basicEmailCheck } from '../../../lib/functions/LoginHelperFunctions';
import { url, debug } from '../../../config';
import { addMessage } from '../../../lib/functions/notifications';

const PasswordReset = () => {

    const [email, setEmail] = useState("");

    let history = useHistory();


    const submitHandler = async (e) => {
        e.preventDefault(); //prevent re-render upon click

        if (email.trim() === "") {
            addMessage(i18next.t("general.email_password_reset.no_email"), "warning")
            setEmail("");
            return;
        }
        if (!basicEmailCheck(email)) {
            addMessage(i18next.t("general.email_password_reset.invalid_email"), "warning")
            return;
        }

        try {

            let data = await fetch(`${url()}/auth/forgot_password?email=${email}`);
            debug(data)
            addMessage(i18next.t("general.email_password_reset.success"), "success")
        } catch (error) {
            addMessage(i18next.t("general.email_password_reset.failure"), "success")
            debug(error);
        }
    }

    return (
        <div>
            <Header as="h1" className="teal">{i18next.t("general.email_password_reset.reset")}</Header>
            <Form className="loginForm" onSubmit={(e) => submitHandler(e)}>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.email_password_reset_label")}</Label>
                    <Input placeholder='Email' onChange={(e) => setEmail(e.target.value)} value={email} />
                </Form.Field>
                <Button size="small" className="loginButton" type="submit">{i18next.t("general.email_password_reset.submit")}</Button>
                <Button size="small" className="loginButton" type="button" onClick={() => history.push("/login")}>{i18next.t("general.back_button")}</Button>
            </Form>
        </div>
    );
};

export default PasswordReset;