import {Dropdown, Header, List, Loader, Menu} from "semantic-ui-react";
import i18next from "i18next";
import React, {useEffect, useRef, useState} from "react";
import {debug, url} from "../../../config";
import cookies from "js-cookie";
import * as d3 from "d3";
import {ListOfSequences} from "./ListOfSequences";

export const Sequences = () => {

    const [sequences, setSequences] = useState([]);
    const [showLegend, setShowLegend] = useState(true);
    const [data, setData] = useState({name: null, steps: [{positions: [{x: 1, y: 1}]}]})
    const [selected, setSelected] = useState({})
    const [sequencePosition, setSequencePosition] = useState(0);
    const [loading, setLoading] = useState(false)

    const svgRef = useRef();

    useEffect(() => {

        const getSequenceData = async () => {
            setLoading(true)
            try {
                let data = await fetch(`${url()}/coach/sequence`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': `Bearer ${cookies.get("session")}`
                    })
                })
                let res = await data.json()
                let categories = {};
                res.forEach(val => {
                    if (!(val.category in categories)) {
                        categories[val.category] = [];
                    }

                    categories[val.category].push({name: val.name, id: val.id, steps: val.steps});
                });
                debug(categories)
                setSequences(categories)
            } catch (e) {
                debug(e)
            } finally {
                setLoading(false)
            }
        }
        getSequenceData()
    }, [])

    const getSequence = async (sequence_id) => {
        try {
            setLoading(true)
            let data = await fetch(`${url()}/coach/sequence/${sequence_id}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            let res = await data.json()
            // console.log(res)
            setData(res)
        } catch (e) {
            debug(e)
        } finally {
            setLoading(false)
        }
    }

    const getPlayerPositionAndNumber = (data) => { //using name
        return {team: data.name[0], position: data.name.slice(1), number: data.number & 0x7f}
    }

    useEffect(() => {
        const players = data.steps[sequencePosition].positions
        debug(players)
        const svg = d3.select(svgRef.current);
        svg
            .selectAll('circle').remove();
        svg
            .selectAll('text').remove();
        svg
            .select('g>g#players')
            .selectAll()
            .data(players)
            .enter()
            .append('circle')
            .attr("class", (d) => {
                // console.log(d.number & 0x100)
                const v = getPlayerPositionAndNumber(d)
                if (v.position === "1") {
                    return "gk"
                }
                // if (d.number & 0x200 !== 0) {
                //     return "home"
                // }
                if (v.team === "H") {
                    return "home"
                }
                if (v.team === "A") {
                    return "away"
                }
                if (v.number === 0) {
                    return "ball"
                }
                return ""
            })
            .attr('cx', d => d.x)
            .attr('cy', d => -d.y)
            .attr("r", d => d.number === 0 ? 50 : 110) //makes ball radius smaller than players

        svg
            .select("g>g#text")
            .selectAll()
            .data(players)
            .enter()
            .append("text")
            .attr("font-size", "150")
            .attr('x', (d) => { //checks player number, changes adjustment if it is single digit to center
                const v = getPlayerPositionAndNumber(d)
                if (d.number < 10) {
                    return d.x = d.x - 50
                }
                if (v.number < 10) {
                    return d.x = d.x - 50
                }
                if (d.number > 11 && d.number < 21) {
                    return d.x - 50
                }
                return d.x - 100
            })
            .attr('y', d => -d.y + 50)
            .attr('fill', "white")
            .text((d) => {
                if (d.number === 0) { //returns each team's player number unless it is 0 (the ball)
                    return
                }
                if ((d.number & 0x100) === 0) { // if legacy data
                    if (d.number > 11) {
                        return `${d.number.toString() - 11}`
                    } else {
                        return d.number.toString()
                    }
                } else {
                    return (d.number & 0x7f).toString()
                }
            })

    }, [data, sequencePosition])

    const translation = "translate(0, 0) scale(0.01)"; //centers

    if (loading) {
        return (
            <div className="startLoading">
                <Loader active={true}>{i18next.t("general.results.loading")}</Loader>
            </div>
        )
    } else {
        return (
            <div>
                <Header as="h1">Sequences</Header>
                {sequences.length !== 0 ?
                    <Menu compact={true}>
                        {
                            Object.keys(sequences).map((player_category, index) =>
                                <div key={index}>
                                    <Dropdown item text={player_category}>
                                        <Dropdown.Menu>
                                            {sequences[player_category].map((sequence, sequence_index) =>
                                                <Dropdown.Item active={false} key={sequence_index}
                                                               onClick={() => {
                                                                   setLoading(true)
                                                                   setSelected(sequence)
                                                                   getSequence(sequence.id)
                                                                   setSequencePosition(0) //reset sequence position whenever data is updated
                                                                   setLoading(false)
                                                               }
                                                               }>{sequence.name}</Dropdown.Item>
                                            )}
                                        </ Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )
                        }
                        <Menu.Item onClick={() => setShowLegend(!showLegend)}>
                            Legend
                        </Menu.Item>
                    </Menu> :
                    <p>{i18next.t("coach.startingPositons.no_sets")}</p>
                }
                {data.name !== null &&
                    <div className="graphics">
                        <div className="coachMenu">
                            <Header as="h2">{data.name}</Header>
                            <ListOfSequences
                                steps={selected.steps}
                                setSequencePosition={(val) => setSequencePosition(val)}
                            />
                            <Header as="h2">{sequencePosition + 1}</Header>
                        </div>
                        <React.Fragment>
                            <svg ref={svgRef} viewBox="-52.5 -35 105 70" id="pitch">
                                <g transform={translation}>
                                    <g id="players"/>
                                    <g id="text"/>
                                </g>
                            </svg>
                        </React.Fragment>
                    </div>
                }
                {!showLegend && <div className="legend coachMenu">
                    <Header as="h4">{i18next.t("coach.startingPositions.legend.header")}</Header>
                    <List>
                        <List.Item>
                            <div className="legendList">
                                <p className="home bold">▉</p>
                                <p>{i18next.t("coach.startingPositions.legend.home")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="away">▉</p>
                                <p>{i18next.t("coach.startingPositions.legend.away")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="gk">▉</p>
                                <p>{i18next.t("coach.startingPositions.legend.gk")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="ball">▉</p>
                                <p>{i18next.t("coach.startingPositions.legend.ball")}</p>
                            </div>
                        </List.Item>
                    </List>
                </div>}
            </div>
        )
    }
}