import {debug, Google, url} from "../../../../config";
import cookies from "js-cookie";
import {useEffect} from "react";
import {sessionTokenFetch, userDataObj} from "../../../../lib/functions/LoginHelperFunctions";
import {isProfileFilledIn} from "../../../../lib/functions/ProfileHelperFunctions";
import {addMessage} from "../../../../lib/functions/notifications";
import {useHistory} from "react-router-dom";
import i18next from "i18next";

export const GoogleIdentitySignin = ({loggedIn, username, setProfileFilledIn, getUserTeam, setLoading}) => {

    // const [isSignedIn, setIsSignedIn] = useState(false);

    let history = useHistory();

    function handleCredentialResponse(response) {
        debug("Encoded JWT ID token: " + response.credential);
        setLoading(true);
        let id_token = response.credential
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `${url()}/auth/google_signin`);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.timeout = 20000
        xhr.onload = async function () {
            try {
                let res = JSON.parse(xhr.responseText);
                debug(res)
                username(userDataObj(res));
                cookies.set('userdata', res.id, {expires: 7})
                cookies.set("user", res.token, {expires: 7})
                cookies.set("path", "/logged_in_home")

                if (isProfileFilledIn(res) === true) {
                    setProfileFilledIn(true)
                }
                let session = await sessionTokenFetch();
                if (session.status === 200) {
                    //if successfully getting temp token, store it and redirect
                    cookies.set("session", session.token, {expires: 1 / 24})
                    const team = await getUserTeam();
                    if (team) {
                        loggedIn(true);
                        history.push("/logged_in_home");
                    }
                } else {
                    addMessage("An error occured when trying to login", "warning")
                    debug(res)
                }
            } catch (e) {
                debug(e);
                addMessage("Unable to login to google at this time.", "warning")
                setLoading(false)
            }
        };
        xhr.ontimeout = (e) => {
            debug(e)
            addMessage(i18next.t("general.login.error_api"), "warning")
            setLoading(false)
        }
        xhr.send('idtoken=' + id_token);
    }

    // useEffect(() => {
    const googleInitialise = () => {
        window.google.accounts.id.initialize({
            client_id: Google.CLIENT_ID,
            callback: handleCredentialResponse
        });
    }
    googleInitialise()

    useEffect(()=> {
        window.google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {theme: "outline", size: "large"}  // customization attributes
        );
        window.google.accounts.id.prompt();
    }, [])
    // }, [])

    return (
        <div id="buttonDiv"></div>
    )

}