import { useState, useEffect } from 'react';
import { Button, Input, Header, Label, Loader, Dropdown, Form } from 'semantic-ui-react'
import cookies from 'js-cookie';
import i18next from 'i18next';
import { playerPositions } from './profileData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { checkProfileValue, isProfileFilledIn, isDateOfBirthValid } from '../../lib/functions/ProfileHelperFunctions';
import '../Profile/Profile.css'
import { url, debug} from '../../config';
import { addMessage } from '../../lib/functions/notifications';

const Profile = ({ user, setUser, setProfileFilledIn }) => {

    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [gender, setGender] = useState("");
    const [firstPosition, setFirstPosition] = useState("");
    const [secondPosition, setSecondPosition] = useState("");
    const [loading, setLoading] = useState(false);
    const [userTeam, setUserTeam] = useState("");
    const [isPlayer, setIsPlayer] = useState(false);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        for (let i = 0; i < user.subscriptions.length; i++) {
            if (user.subscriptions[i].role === "Individual" || user.subscriptions[i].role === "Player") {
                setIsPlayer(true);
            }
        }
    }, [user.subscriptions])

    useEffect(() => {
        if (isProfileFilledIn(user) === false) {
            addMessage(i18next.t("general.profile.message_emptyFields"), "warning")
        }
    }, [user])

    useEffect(() => {

        if (/\s/.test(user.name)) {

            let surnameIndex = user.name.indexOf(" ");
            setFirstName(user.name.slice(0, surnameIndex));
            setSurname(user.name.slice(surnameIndex + 1))
        } else {
            setFirstName(user.name);
        }
        if (user.associateClub === null || user.associateClub === undefined) {
            debug("No userTeam")
        } else {
            // debug("setUserTeam")
            setUserTeam(user.associateClub)
        }
        if (user.date_of_birth !== null) {
            setDate(DateTime.fromISO(user.date_of_birth).toJSDate());
        }
        else {
            setDate(new Date());
        }
        if (user.gender !== null) {
            let string = user.gender;
            string = string[0].toUpperCase() + string.substr(1);
            setGender(string);
        } else {
            setGender(i18next.t("general.profile.select_text"));
        }
        if (user.primary_position !== null) {
            setFirstPosition(user.primary_position);
        } else {
            setFirstPosition(i18next.t("general.profile.select_text"));
        }
        if (user.secondary_position !== null) {
            setSecondPosition(user.secondary_position);
        } else {
            setSecondPosition(i18next.t("general.profile.select_text"));
        }
    }, [user]);

    const genderHandler = (localeString) => {
        if (localeString === i18next.t("general.profile.male_gender")) {
            return "male";
        }
        else if (localeString === i18next.t("general.profile.female_gender")) {
            return "female";
        } else if (localeString === i18next.t("general.profile.select_text")) {
            return;
        } else {
            debug(localeString)
            throw new Error("localeString is not a valid enum.")
            //No values other than "male" or "female" should be sent to the backend. if error is thrown, there is an issue
            //with the string being checked, as they should always be the above.
        }
    }

    const updateUser = async (e) => {
        e.preventDefault()

        let fullName = firstName + " " + surname;

        let userGender = genderHandler(gender)

        let dataObj = {
            name: fullName,
            primary_position: firstPosition,
            secondary_position: secondPosition,
            gender: userGender,
            subscriptions: user.subscriptions,
        }

        if (isProfileFilledIn(dataObj) === false /*|| isDateOfBirthValid(date)*/) {
            addMessage(i18next.t("general.profile.message_emptyFields"), "warning")
            return;
        }

        if (isDateOfBirthValid(date)) {
            if (isPlayer) {
                addMessage(i18next.t("general.profile.message_emptyFields"), "warning")
                return;
            }
        }

        let userData;
        if (!isPlayer) { //if user is not a player, only pass name and associated club. Else send all fields
            userData = {
                name: fullName,
                associated_club: userTeam,
            }
        } else {
            userData = {
                name: fullName,
                associated_club: userTeam,
                primary_position: firstPosition,
                secondary_position: secondPosition,
                gender: userGender,
                date_of_birth: DateTime.fromJSDate(date).toISODate(),
            }
        }
        try {
            setLoading(true);

            let data = await fetch(`${url()}/auth/user/${user.id}`, {
                method: "PATCH",
                body: JSON.stringify(userData),

                headers: new Headers({
                    "content-type": "application/json",
                    'authorization': `Bearer ${cookies.get("session")}`
                })
            })
            let res = await data.json();
            let code = data.status;
            debug(code);
            debug(res);
            if (code === 200) {
                //display confirmation of success
                addMessage(i18next.t("general.profile.message_success"), "success")
                let newUser = user;
                newUser.name = fullName;
                newUser.associateClub = userTeam;
                newUser.primary_position = firstPosition;
                newUser.secondary_position = secondPosition;
                newUser.gender = gender;
                newUser.date_of_birth = DateTime.fromJSDate(date).toISODate();
                localStorage.setItem('userdata', JSON.stringify(newUser));
                setUser(newUser);
                setLoading(false);
                setProfileFilledIn(true);
            } else { //if code is anything but 200 but not an exception
                debug(code);
                addMessage(i18next.t("general.generic_error"), "warning")
            }
        } catch (error) {
            debug(error);
            //display error message
            addMessage(i18next.t("general.profile.message_failure"), "warning")
            setLoading(false);
        }
    }
    return (
        <div>
            <Header as='h1' icon textAlign='left'>
                <Header.Content>{i18next.t("general.profile.title")}</Header.Content>
            </Header>
            <div>
                <Form>
                    <Form.Field required>
                        <Label color="green">{i18next.t("general.profile.pin")}</Label>
                        <Form.Input readOnly >{user.pin}</Form.Input>
                    </Form.Field>
                    <Form.Field required>
                        <Label color="green">{i18next.t("general.profile.email")}</Label>
                        <Form.Input readOnly >{user.email}</Form.Input>
                    </Form.Field>
                    <Form.Field required>
                        <Label color={checkProfileValue(firstName) === null ? "red" : "green"} >
                            {i18next.t("general.profile.firstname")}
                        </Label>
                        <Input placeholder={i18next.t("general.profile.firstname_placeholder")} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </Form.Field>
                    <Form.Field required>
                        <Label color={checkProfileValue(surname) === null ? "red" : "green"}>
                            {i18next.t("general.profile.lastname")}
                        </Label>
                        <Input placeholder={i18next.t("general.profile.lastname_placeholder")} value={surname} onChange={(e) => setSurname(e.target.value)} />
                    </Form.Field>
                    <Form.Field >
                        <Label color="green">{i18next.t("general.profile.club")}</Label>
                        <Input placeholder="Your soccer club" value={userTeam} onChange={(e) => setUserTeam(e.target.value)} />
                    </Form.Field>
                    {isPlayer &&
                        <Form.Field required>
                            <Label color={isDateOfBirthValid(date) ? "red" : "green"}>
                                {i18next.t("general.profile.dob")}
                            </Label>
                            <Input>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </Input>
                        </Form.Field>
                    }
                    {isPlayer &&
                        <Form.Field required>
                            <Label color={checkProfileValue(gender) === null ? "red" : "green"}>
                                {i18next.t("general.profile.gender")}
                            </Label>
                            <Input>
                                <div className="formDropDown">
                                    <Dropdown text={gender}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setGender(i18next.t("general.profile.male_gender"))}>{i18next.t("general.profile.male_gender")}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setGender(i18next.t("general.profile.female_gender"))}>{i18next.t("general.profile.female_gender")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Input>
                        </Form.Field>
                    }
                    {isPlayer &&
                        <Form.Field required>
                            <Label color={checkProfileValue(firstPosition) === null ? "red" : "green"}>
                                {i18next.t("general.profile.player_position_1")}
                            </Label>
                            <Input>
                                <div className="formDropDown">
                                    <Dropdown fluid text={firstPosition}>
                                        <Dropdown.Menu>
                                            {playerPositions.map((position, index) =>
                                                <Dropdown.Item key={index} onClick={() => setFirstPosition(position.short)}>{position.long}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Input>
                        </Form.Field>
                    }
                    {isPlayer &&
                        <Form.Field required>
                            <Label color={checkProfileValue(secondPosition) === null ? "red" : "green"}>
                                {i18next.t("general.profile.player_position_2")}
                            </Label>
                            <Input>
                                <div className="formDropDown">
                                    <Dropdown text={secondPosition}>
                                        <Dropdown.Menu>
                                            {playerPositions.map((position, index) =>
                                                <Dropdown.Item key={index} onClick={() => setSecondPosition(position.short)}>{position.long}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Input>
                        </Form.Field>
                    }
                    <Button id="nameButton" onClick={(e) => updateUser(e)} >{i18next.t("general.profile.update")}</Button>
                    <Loader id="profileLoading" active={loading}>{i18next.t("general.profile.loader")}</Loader>
                </Form>
            </div>
        </div>
    )
}

export default Profile;