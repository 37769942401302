import {Button, Dropdown, Form, Label, Menu, Modal} from "semantic-ui-react";
import {useState} from "react";
import {debug, url} from "../../config";
import cookies from "js-cookie";
import {addMessage} from "../../lib/functions/notifications";

export const AddEditDeleteTags = ({setModifyTags, userTags, setUserTags, setLoading}) => {

    const [tagOption, setTagOption] = useState("Modify Tag")
    const [input, setInput] = useState("")
    const [selectedTag, setSelectedTag] = useState({text: "Select a tag"})

    // useEffect(() => {
    //     if (input.length > 0) {
    //         setInput("")
    //     }
    // }, [tagOption])

    const addTag = async () => {
        try {
            let data = await fetch(`${url()}/coach/tags`, {
                method: "POST",
                body: JSON.stringify({tag: input}),
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`,
                }
            });
            debug(data.status)
            switch (data.status) {
                case 200:
                    const res = await data.json()
                    let ret = userTags
                    ret.push({key: res.id, text: res.tag, value: res.tag})
                    setUserTags(ret)
                    addMessage(`Successfully added ${input}`, 'success')
                    break
                default:
                    addMessage(`Unable to add ${input}`, "warning")
                    break
            }
        } catch (error) {
            addMessage(`Error: ${error.message}`, 'warning')
            debug(error)
        } finally {
            setInput("")
            setLoading(true)
        }
    }

    const editTag = async () => {
        try {
            let data = await fetch(`${url()}/coach/tags/${selectedTag.key}`, {
                method: "PATCH",
                body: JSON.stringify({tag: input}),
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`,
                }
            });
            debug(data.status)
            switch (data.status) {
                case 200:
                    for (let i = 0; i < userTags.length; i++) {
                        if (userTags[i].key === selectedTag.key) {
                            let ret = userTags
                            ret[i].text = input
                            ret[i].value = input
                            setUserTags(ret)
                            break;
                        }
                    }
                    addMessage(`Successfully changed to ${input}`, 'success')
                    break
                default:
                    addMessage(`Unable to change to ${input}`, "warning")
                    break
            }
        } catch (error) {
            addMessage(`Error: ${error.message}`, 'warning')
            debug(error)
        } finally {
            setInput("")
            setLoading(true)
        }
    }

    const deleteTag = async () => {
        try {
            let data = await fetch(`${url()}/coach/tags/${selectedTag.key}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${cookies.get("session")}`,
                }
            });
            debug(data.status)
            switch (data.status) {
                case 200:
                    for (let i = 0; i < userTags.length; i++) {
                        if (userTags[i].key === selectedTag.key) {
                            let arr = userTags
                            arr.splice(i, 1);
                            setUserTags(arr)
                            break;
                        }
                    }
                    addMessage(`Successfully deleted ${selectedTag.text}`, 'success')
                    break
                default:
                    addMessage(`Unable to delete ${selectedTag.text}`, "warning")
                    break
            }
        } catch (error) {
            addMessage(`Error: ${error.message}`, 'warning')
            debug(error)
        } finally {
            setInput("")
            setLoading(true)
        }
    }

    return (
        <Modal
            size="tiny"
            open
        >
            <Modal.Header>
                <p>{tagOption}</p>
            </Modal.Header>
            <Modal.Content>
                {tagOption === "Modify Tag" &&
                    <p>Select an option.</p>
                }
                {tagOption === "Add Tag" &&
                    <Form>
                        <Form.Field>
                            <Label>Add Tag</Label>
                            <Form.Input placeholder="Tag" onChange={(e) => setInput(e.target.value)}/>
                        </Form.Field>
                        <Button onClick={() => addTag()}>Submit</Button>
                    </Form>
                }
                {tagOption === "Edit Tag" &&
                    <Form>
                        <Form.Field>
                            <Menu compact>
                                <Dropdown item text={selectedTag.text}>
                                    <Dropdown.Menu>
                                        {userTags.map(item =>
                                            <Dropdown.Item onClick={() => setSelectedTag(item)}
                                                           key={item.key}>{item.text}</Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu>
                        </Form.Field>
                        <Form.Field>
                            <Label>Edit Tag</Label>
                            <Form.Input placeholder="Tag" onChange={(e) => setInput(e.target.value)}/>
                        </Form.Field>
                        <Button onClick={() => editTag()}>Submit</Button>
                    </Form>
                }
                {tagOption === "Delete Tag" &&
                    <Form>
                        <Form.Field>
                            <Menu compact>
                                <Dropdown item text={selectedTag.text}>
                                    <Dropdown.Menu>
                                        {userTags.map(item =>
                                            <Dropdown.Item onClick={() => setSelectedTag(item)}
                                                           key={item.key}>{item.text}</Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu>
                        </Form.Field>
                        <Button onClick={() => deleteTag()}>Confirm</Button>
                    </Form>

                }
            </Modal.Content>
            <Modal.Actions>
                {tagOption !== "Add Tag" && <Button onClick={() => setTagOption("Add Tag")}>Add</Button>}
                {tagOption !== "Edit Tag" && <Button onClick={() => setTagOption("Edit Tag")}>Edit</Button>}
                {tagOption !== "Delete Tag" && <Button onClick={() => setTagOption("Delete Tag")}>Delete</Button>}
                <Button onClick={() => setModifyTags(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}