import {useState} from 'react'
import cookies from 'js-cookie';
import {Form, Input, Segment} from 'semantic-ui-react'
import '../../User/Pairing/PairHeadset.css'
import i18next from 'i18next';
import {url, debug} from '../../../config';
import {Header} from "semantic-ui-react";
import {addMessage} from "../../../lib/functions/notifications";

const PairHeadsetCoach = () => {

    const [code, setCode] = useState("");
    const [errormessage, setEM] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault(); //prevent re-render upon click

        try {
            let response = await fetch(`${url()}/coach/pairing/${code}`, {
                method: "PATCH",
                mode: 'cors',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${cookies.get("session")}`,
                }
            });
            debug(response.status)
            switch (response.status) {
                case 200:
                    addMessage(i18next.t("coach.pairApp.success"), "success")
                    debug(errormessage)
                    break
                case 401:
                    addMessage(i18next.t("coach.pairApp.unauthorised"), "warning")
                    debug(errormessage)
                    break
                case 410:
                    addMessage(i18next.t("coach.pairApp.invalid"), "warning")
                    debug(errormessage)
                    break
                default:
                    addMessage(i18next.t("coach.pairApp.error") + response.status, "warning")
                    debug(errormessage)
                    break
            }
        } catch (error) {
            debug(error)
        }
    }

    const handleChange = async (e) => {
        setCode(e.target.value.toUpperCase());
        if (e.target.value.length !== 6) {
            setEM(i18next.t("coach.pairApp.charWarning"))
        } else {
            setEM("")
        }
    }

    let inputfield = code.length === 6 ?
        <Input action='Submit' onChange={handleChange} value={code} maxLength="6" autoCapitalize="characters"/> :
        <Input placeholder='enter code' onChange={handleChange} value={code} maxLength="6" autoCapitalize="characters"/>

    return (
        <div>
            <Header as='h1' textAlign='left'>
                <Header.Content>{i18next.t("coach.pairApp.header")}</Header.Content>
            </Header>

            <Segment basic textAlign="left">
                <br/><b>{i18next.t("coach.pairApp.subInstructions")}</b><br/><br/>
                <Form className="paircodeform" onSubmit={handleSubmit}>
                    <Form.Field inline={true} className="field">
                        {inputfield}<i>{errormessage}</i>
                    </Form.Field>
                </Form>
                <br/><br/><br/>
                <p className="phwidth">{i18next.t("coach.pairApp.instructions")}</p>
            </Segment>
        </div>
    )
}

export default PairHeadsetCoach;