import { useState, useEffect, useCallback } from 'react';
import Attempts from '../Attempts/Attempts';
import cookies from 'js-cookie';
import AttemptsButtons from '../AttemptsButtons/AttemptsButtons';
import D3Graph from '../PitchResultChart/PitchResultChart';
import ExerciseButtons from '../ExerciseButtons/ExerciseButtons';
import ExerciseAggregate from '../ExerciseAggregate/ExerciseAggregate';
import { Loader } from 'semantic-ui-react';
import './Result.css'
import i18next from 'i18next';
import { url, debug } from '../../../config';


const Result = (sessionId) => {
    const [results, setResults] = useState();
    const [exercise, setExercise] = useState(-1);
    const [attempt, setAttempt] = useState(-1);
    const [exerciseSelected, setExerciseSelected] = useState(true);
    const [loading, setLoading] = useState(false);

    debug(results)

    const exerciseHandler = (index) => {
        if (exerciseSelected) {
            setExerciseSelected(false);
        }
        setAttempt(-1);
        setExercise(index);
    }

    const setExerciseAggregate = () => {
        setExerciseSelected(true);
        setExercise(-1);
    }

    const attemptHandler = (index) => {
        setAttempt(index);
    }

    const getResults = useCallback(async () => {
        setLoading(true);

        let res = await fetch(`${url()}/api/soccer/results/${sessionId.sessionId}`, {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                "content-type": 'application/json',
                // 'cookie': `access_token=${cookies.get("user")}`
                Authorization: `Bearer ${cookies.get("session")}`
            })
        });

        if (res.status === 200) {
            debug("ok!")
            setLoading(false);
        }
        return res.json();
    }, [sessionId])

    useEffect(() => {
        if (exerciseSelected) {
            setExercise(-1);
        }
        setAttempt(-1);
    }, [getResults, exerciseSelected])

    useEffect(() => {
        getResults().then((data) => {
            setResults(data);
        });
    }, [getResults]);

    if (loading) {
        return (
            <Loader size="massive" className="centered" active inline>{i18next.t("general.loading")}</Loader>
        )
    } else {

        return (
            <div className="resultsContainer">
                {results && <ExerciseButtons
                    whatToDo={exerciseSelected ? "" : results.exercises[exercise].whatToDo}
                    exerciseSelect={exerciseHandler} aggregateSelected={setExerciseAggregate}
                    exerciseNum={results.exercises} results={results} exerciseState={exercise} />}


                <div className="summary">
                    <div className="attemptData">
                        {!exerciseSelected && <AttemptsButtons attempt={results.exercises[exercise].plays}
                            selectAttempt={attemptHandler} attemptState={attempt} />}

                        {!exerciseSelected ? <Attempts results={results} exercise={exercise} attempt={attempt} attemptSelected={attempt !== -1}
                            exerciseSelected={exerciseSelected} />
                            :
                            <ExerciseAggregate results={results} />}
                    </div>
                    {!exerciseSelected && <D3Graph exercise={exercise} attempt={attempt} results={results} />}
                </div>
            </div>
        )
    }
}

export default Result;