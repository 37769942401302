import {Header, Segment} from "semantic-ui-react";
import './GetStarted.css'
import React, {useState} from "react";
import i18next from "i18next";
// eslint-disable-next-line
import {HighlightTag} from "../../../StyledComponents";
import {MacHelp} from "../../Purchase/MacHelp/MacHelp";


const GetStarted = () => {

    const [macHelpModal, setMacHelpModal] = useState(false)

    return (
        <div>
            <Header as="h1">
                Get Started with ViPER Coach
            </Header>
            <p>Download the FREE app and try it out for yourself!</p>
            <div id="coachDownloadVideo">
                <iframe
                        src="https://www.youtube.com/embed/5EY0rZWFo84?si=G2mw2HhfZRfnTrBV"
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        title="Viper Coach"
                        allowfullscreen
                />
            </div>
            <Segment basic textAlign="left" className="getStartedSegment">
                <Header as="h2">
                    {i18next.t("coach.getStarted.windows.heading")}
                </Header>
                <p>{i18next.t("coach.getStarted.windows.desc1")}
                    <a
                        href={process.env.REACT_APP_PM_COACH_DOWNLOAD}
                        download
                    >{i18next.t("coach.getStarted.windows.desc2")}</a>
                    {i18next.t("coach.getStarted.windows.desc3")}</p>
            </Segment>
            <Segment basic textAlign="left" className="getStartedSegment">
                <Header as="h2">
                    {i18next.t("general.loggedinhome.mac_header")}
                </Header>
                <p>
                    {i18next.t("general.loggedinhome.mac_desc1")}
                    <a
                        href={process.env.REACT_APP_PM_COACH_DOWNLOAD_MAC}
                        download
                        target="_blank"
                        rel="noreferrer"
                    > here </a>
                    {i18next.t("general.loggedinhome.mac_desc2")}
                </p>
                <HighlightTag
                    italic
                    link
                    color="#3399ff"
                    onClick={() => setMacHelpModal(true)}
                >{i18next.t("general.loggedinhome.mac_help")}</HighlightTag>
            </Segment>
            <Segment basic textAlign="left" className="getStartedSegment">
                <Header as="h2">
                    {i18next.t("coach.getStarted.meta.heading")}
                </Header>
                    <p>{i18next.t("coach.getStarted.oculus.desc1")}</p>
                    <p>{i18next.t("coach.getStarted.oculus.desc2")}</p>
            </Segment>
            <Segment basic>
              <Header as="h2">
                {i18next.t("coach.getStarted.tutorial.heading")}
              </Header>
              <div className="purchaseWidth">
                <div style={{marginBottom: "15px"}}>
                  <h3>General Controls</h3>
                  <iframe
                    src="https://www.youtube.com/embed/PsLSvbphuCQ?si=mXrvaHwHtcbwV0cO"
                    width="560"
                    height="315"
                    title="General Controls"
                    frameborder="0"
                    allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>

                <div style={{marginBottom: "15px"}}>
                  <h3>Free Play Mode</h3>
                  <iframe
                      src="https://www.youtube.com/embed/BGexbp_6m1Q?si=QaMOPADX05L7ngpz"
                      width="560"
                      height="315"
                      title="Free Play Mode"
                      frameborder="0"
                      allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                </div>
                <div style={{marginBottom: "15px"}}>
                  <h3>Animation Sequence</h3>
                  <iframe
                    src="https://www.youtube.com/embed/I4FMWiY_loQ?si=xTm23AlrYDFlEkSW"
                    width="560"
                    height="315"
                    title="Creating animation sequence"
                    frameborder="0"
                    allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
                <div>
                  <h3>Connecting with other users</h3>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/e-tuf5WseD0?si=NTZIdPouVtNUxcOI"
                    title="Connecting with other users"
                    frameborder="0"
                    allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            </Segment>
            <Segment basic textAlign="left" className="getStartedSegment">
                <Header as="h3">{i18next.t("coach.getStarted.notice.heading")}</Header>
                <p>{i18next.t("coach.getStarted.notice.desc1")}</p>
                <br/>
                <p>{i18next.t("coach.getStarted.notice.desc2")}</p>
            </Segment>
            <MacHelp macHelpModal={macHelpModal} setMacHelpModal={(val) => setMacHelpModal(val)}/>
            </div>
    )
};

export default GetStarted;
