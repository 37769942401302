import { Table, Dropdown } from 'semantic-ui-react'
import { useEffect, useState } from 'react';
import i18next from 'i18next';

const ComparisonDropDown = ({ exercise, attempt, setAttempt, setExercise }) => {

    const [exerciseVal, setExcerciseVal] = useState("");
    const [attemptVal, setAttemptVal] = useState("");

    useEffect(() => {
        if (exercise === -1) {
            setExcerciseVal(i18next.t("dropdown.exercise_aggregate"))
        } else {
            setExcerciseVal(`${i18next.t("dropdown.exercise_num")}${exercise + 1}`)
        }

    }, [exercise]);

    useEffect(() => {
        if (attempt === -1) {
            setAttemptVal(i18next.t("dropdown.attempt_summary"))
        } else {
            setAttemptVal(`${i18next.t("dropdown.attempt_num")}${attempt + 1}`)
        }
    }, [attempt])

    return ( //due to the possibility of results having variable length, this is hardcoded
        <Table.HeaderCell id="teamResultDropdown">
            <Dropdown text={exerciseVal}>
                <Dropdown.Menu>
                    <Dropdown.Item key={-1} onClick={() => setExercise(-1)}>{i18next.t("dropdown.exercise_menuItem_aggregate")}</Dropdown.Item>
                    <Dropdown.Item key={1} onClick={() => setExercise(0)}>{i18next.t("dropdown.exercise_menuItem") + 1}</Dropdown.Item>
                    <Dropdown.Item key={2} onClick={() => setExercise(1)}>{i18next.t("dropdown.exercise_menuItem") + 2}</Dropdown.Item>
                    <Dropdown.Item key={3} onClick={() => setExercise(2)}>{i18next.t("dropdown.exercise_menuItem") + 3}</Dropdown.Item>
                    <Dropdown.Item key={4} onClick={() => setExercise(3)}>{i18next.t("dropdown.exercise_menuItem") + 4}</Dropdown.Item>
                    <Dropdown.Item key={5} onClick={() => setExercise(4)}>{i18next.t("dropdown.exercise_menuItem") + 5}</Dropdown.Item>
                    <Dropdown.Item key={6} onClick={() => setExercise(5)}>{i18next.t("dropdown.exercise_menuItem") + 6}</Dropdown.Item>
                    <Dropdown.Item key={7} onClick={() => setExercise(6)}>{i18next.t("dropdown.exercise_menuItem") + 7}</Dropdown.Item>
                    <Dropdown.Item key={8} onClick={() => setExercise(7)}>{i18next.t("dropdown.exercise_menuItem") + 8}</Dropdown.Item>
                    <Dropdown.Item key={9} onClick={() => setExercise(8)}>{i18next.t("dropdown.exercise_menuItem") + 9}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {exercise !== -1 &&
                <Dropdown text={attemptVal}>
                    <Dropdown.Menu>
                        <Dropdown.Item key={-1} onClick={() => setAttempt(-1)}>{i18next.t("dropdown.attempt.menuItem_summary")}</Dropdown.Item>
                        <Dropdown.Item key={0} onClick={() => setAttempt(0)}>{i18next.t("dropdown.attempt_menuItem") + 1}</Dropdown.Item>
                        <Dropdown.Item key={1} onClick={() => setAttempt(1)}>{i18next.t("dropdown.attempt_menuItem") + 2}</Dropdown.Item>
                        <Dropdown.Item key={2} onClick={() => setAttempt(2)}>{i18next.t("dropdown.attempt_menuItem") + 3}</Dropdown.Item>
                        <Dropdown.Item key={3} onClick={() => setAttempt(3)}>{i18next.t("dropdown.attempt_menuItem") + 4}</Dropdown.Item>
                        <Dropdown.Item key={4} onClick={() => setAttempt(4)}>{i18next.t("dropdown.attempt_menuItem") + 5}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}

        </Table.HeaderCell>
    )

}

export default ComparisonDropDown;