import axios from "axios";
import cookies from "js-cookie";

export const uploadFile = async (url, body, setPercentage) => {
        const config = {
            onUploadProgress: (progressEvent) => {
                const percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                if (percent <= 100) {
                    setPercentage(percent);
                }
            },
            headers: {
                Authorization: 'Bearer ' + cookies.get("session")
            }
        };
        const {data} = await axios.post(
            url,
            body,
            config
        );
        return data;
};