import { Grid, Header, Button } from 'semantic-ui-react';
import { getCSVData } from '../../../lib/functions/CSVFunctions';
import i18next from 'i18next';


const AttemptResults = ({ results, exercise, attempt }) => {

    return (
        <div className="result">
            <Header as="h3" className="title">
            {i18next.t("general.results.exercise.averages_title")}
            </Header>
            <Grid className="grid">
                <Grid.Row id="row"><p>{i18next.t("general.results.exercise_success")}</p>
                    <p>{results.exercises[exercise].plays[attempt].success ? i18next.t("general.results.positive") : i18next.t("general.results.negative")}</p>
                </Grid.Row>
                <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanning")}</p>
                    <p>{results.exercises[exercise].plays[attempt].scanningPercentage.toPrecision(2)}%</p>
                </Grid.Row>
                <Grid.Row id="row"><p>{i18next.t("general.results.exercise_actionTime")}</p>
                    <p>{results.exercises[exercise].plays[attempt].decisionTime.toPrecision(1)}s</p>
                </Grid.Row>
                <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanPerSecond")}</p>
                    <p>{results.exercises[exercise].plays[attempt].scanningFrequency.toPrecision(1)}</p>
                </Grid.Row>
                <Grid.Row>
                    <Button onClick={() => getCSVData(results)}>{i18next.t("general.results.exercise_getCSV")}</Button>
                </Grid.Row>
            </Grid>
        </div>
    )

}

export default AttemptResults;