import React, {useState} from "react";
import {uploadFile} from "./UploadWidget/upload";
import {Wrapper, WidgetWrapper, ProgressBar, Count} from "./UploadWidget/WidgetStyles";

export const UploadWidget = ({path, onSuccess, onFailure, uploadType, uploadDescription}) => {
    const [percentage, setPercentage] = useState(0);
    const [file, setFile] = useState(null)
    const [isUploading, setIsUploading] = useState(false)

    const handleFile = async (e) => {
        try {
            const targetFile = (e.target.files[0])
            setFile(targetFile)
            // console.log(targetFile)
            setIsUploading(true)
            const formData = new FormData();
            formData.append('file', targetFile)
            formData.append('uploadType', uploadType)
            formData.append('uploadDescription', uploadDescription)
            await uploadFile(path, formData, setPercentage);
            onSuccess();
        } catch (e) {
            onFailure(e);
        } finally {
            setPercentage(0);
            setIsUploading(false)
            setFile(null)
        }
    };

    return (
        <Wrapper>
            <WidgetWrapper isUploading={isUploading}>
                <div className="content">
                    {file === null ?
                        <p>Select a file...</p>
                        :
                        <p>Uploading {file.name}</p>
                    }
                    <ProgressBar percentage={percentage} className="progress_bar">
                        <div/>
                    </ProgressBar>
                </div>
                {isUploading &&
                    <Count>{percentage}%</Count>
                }
                <input disabled={uploadType === null || uploadDescription === null || uploadDescription === ''} onChange={(e) => handleFile(e)} type="file" name="file"/>
            </WidgetWrapper>
        </Wrapper>
    );
}