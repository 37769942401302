import { useState } from 'react';
import {
    Table,
    Button,
    Dimmer,
    Loader,
    Header
} from 'semantic-ui-react';
import {debug} from "../../../../config";
import i18next from 'i18next';
import AddNewMember from '../AddNewMember/AddNewMember'

const Players = ({ players, removeTeamMember, selectedTeam, setPlayers, loading }) => {

    const [DimmerHandler, setDimmerHandler] = useState(false);
    debug(players)

if (loading) {
    return (
        <>
        <Header as="h2">{i18next.t("soccer_team.manage_team.players")}</Header>
        <Loader active inline>Loading</Loader>
    </>
    )
}

    return (
        <div>
            {/* <div className="row"> */}
            <Header as="h2">{i18next.t("soccer_team.manage_team.players")}</Header>
            {/* <p>{`[${players.length}]`}</p>
            </div> */}
            {players.length === 0 &&
                <p>{i18next.t("soccer_team.manage_team.players.no_data")}</p>
            }
            <div>
                {players.length > 0 &&
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{i18next.t("soccer_team.manage_team.managers.name")}</Table.HeaderCell>
                                <Table.HeaderCell>{i18next.t("soccer_team.manage_team.managers.status")}</Table.HeaderCell>
                                <Table.HeaderCell>{" test"}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {players.map((player, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>{player.playerName}</Table.Cell>
                                    <Table.Cell>{player.status}</Table.Cell>
                                    <Table.Cell><Button onClick={() => removeTeamMember(player.playerId, player.playerName, "player")}>Remove</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>

                    </Table>
                }
                <Button onClick={() => setDimmerHandler(true)}>{i18next.t("soccer_team.manage_team.add_player")}</Button>

                <Dimmer active={DimmerHandler} onClickOutside={() => setDimmerHandler(false)} page>
                    <AddNewMember
                        memberType="player"
                        numberOfUsers={players.length}
                        teamId={selectedTeam.teamId}
                        handleClose={() => setDimmerHandler(false)}
                        setUsers={data => setPlayers(data)}
                        users={players}
                    />
                </Dimmer>
            </div>
        </div>
    )

}

export default Players;