import {useState} from 'react';
import {Button, Header, Input} from 'semantic-ui-react';
import i18next from 'i18next';
import cookies from 'js-cookie';
import {url, debug} from '../../../../config';
import {addMessage} from '../../../../lib/functions/notifications';

const TeamName = ({selectedTeam, updateTeamHandler}) => {

    const [teamName, setTeamName] = useState("");

    const updateTeamName = async (team_id) => {
        debug(teamName)

        if (team_id === null) {
            debug("cannot change null");
            return;
        }
        if (teamName.trim().length === 0) {
            addMessage(i18next.t("soccer_team.manage_team.message_invalid"), "warning")
            setTeamName("");
            return;
        }

        try {

            let data = await fetch(`${url()}/api/soccer/teams/${team_id}`, {
                method: "PATCH",
                body: JSON.stringify({teamName: teamName}),
                headers: new Headers({
                    "content-type": "application/json",
                    "Authorization": `Bearer ${cookies.get("session")}`
                })
            })
            // let res = await data.json();
            debug(data);
            if (data.status === 200) {
                updateTeamHandler(teamName);
                addMessage(i18next.t("soccer_team.manage_team.message_success"), "success")
            } else {
                addMessage(i18next.t("soccer_team.manage_team.message_error"), "warning")
            }

        } catch (error) {
            debug(error);
        }

    }

    return (
        <div>
            <Header as="h2">{selectedTeam.teamName}</Header>
            <Input type="text" value={teamName} placeholder="Change Team Name"
                   onChange={(e) => setTeamName(e.target.value)}/>
            <Button onClick={() => updateTeamName(selectedTeam.teamId)}>{i18next.t("general.button.update")}</Button>
        </div>
    )

}

export default TeamName;