import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config';
import { BrowserRouter as Router } from "react-router-dom";
import {ReactNotifications} from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(<I18nextProvider i18n={i18n}>
        <MsalProvider instance={msalInstance}>
            <Router>
                <ReactNotifications />
                <App />
            </Router>
        </MsalProvider>
    </I18nextProvider>
    , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
