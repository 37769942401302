import {Button, Dropdown, Form, Header, Label, Menu, Modal, Segment} from "semantic-ui-react";
import {
    checkSeconds,
    convertMilisToMinsAndSeconds,
    convertMinsAndSecondsToFramesByHalf
} from "../../lib/functions/TimeConvertions";
import {addMessage} from "../../lib/functions/notifications";
import {url} from "../../config";
import cookies from "js-cookie";
import {useState} from "react";
import {Tags} from "./Tags";

export const SequenceModal = ({active, setOpenModal}) => {

    const [sequenceStart, setSequenceStart] = useState("")
    const [sequenceStartSecond, setSequenceStartSecond] = useState("")
    const [sequenceEnd, setSequenceEnd] = useState("")
    const [sequenceEndSecond, setSequenceEndSecond] = useState("")
    const [sequenceName, setSequenceName] = useState("")
    const [sequenceDescription, setSequenceDescription] = useState("")
    const [periodData, setPeriodData] = useState({})

    const [half, setHalf] = useState("Pick period")
    const [tagsOpen, setTagsOpen] = useState(false)
    const [tags, setTags] = useState([])

    const getPeriod = (num) => {
        if (num === 1) {
            return '1st'
        }
        if (num === 2) {
            return '2nd'
        }
        if (num === 3) {
            return '1st - Overtime'
        }
        if (num === 4) {
            return '2nd - Overtime'
        }
        return num.toString()
    }

    const handleHalf = (gameHalf, start, end) => {
        const startTime = gameHalf === 1 ? 0 : gameHalf === 2 ? 45 : gameHalf === 3 ? 90 : 105
        const endTime = convertMilisToMinsAndSeconds(end - start, startTime)
        setPeriodData({startmin: startTime.toString(), startsec: "00", endmin: endTime[0], endsec: endTime[1]})
        setHalf(gameHalf.toString())
    }

    const createSequence = async () => {
        //frame start and add start frames to the result
        const periodStart = active.periods[half - 1].start_idx
        const periodEnd = active.periods[half - 1].end_idx

        const frameStart = convertMinsAndSecondsToFramesByHalf(
            `${sequenceStart}:${sequenceStartSecond.length === 0 ? "0" : sequenceStartSecond}`, active.fps, half, periodStart, periodEnd)
        const frameEnd = convertMinsAndSecondsToFramesByHalf(
            `${sequenceEnd}:${sequenceEndSecond.length === 0 ? "0" : sequenceEndSecond}`, active.fps, half, periodStart, periodEnd)
        // these variables below allow us to handle checks on the end time
        const pDataEndStr = periodData.endmin + periodData.endsec
        const pSeqEndStr = sequenceEnd + sequenceEndSecond
        if (sequenceName.length < 1) {
            addMessage('Sequence requires a name', 'warning')
            return
        }
        if (sequenceDescription.length < 1) {
            addMessage('Sequence requires a brief description', 'warning')
            return
        }
        if (sequenceStart === "") {
            addMessage('Sequence requires a start time', 'warning')
            return
        }
        if (sequenceEnd === "") {
            addMessage('Sequence requires an end time', 'warning')
            return
        }
        if (frameEnd - frameStart < 1) {
            addMessage('Sequence start time must be before the end time', 'warning')
            return
        }
        if (+pDataEndStr < +pSeqEndStr) {
            addMessage(`Enter a time which is between ${periodData.start} minutes and ${periodData.end} minutes.`, 'warning')
            return
        }
        if (frameStart < periodStart) {
            addMessage(`Enter a time which is between ${periodData.start} minutes and ${periodData.end} minutes.`, 'warning')
            return
        }
        const timeLimit = (active.fps * 60) * 5 //change this to increase or decreate max time allowed for sequence. Default: 5 minutes
        if (frameEnd - frameStart > timeLimit) {
            addMessage('Sequence cannot be longer than 5 minutes', 'warning')
            return
        }
        const newSequence = {
            name: sequenceName,
            description: sequenceDescription,
            start_frame: frameStart,
            end_frame: frameEnd,
            game_id: active.id,
            tags: tags
        }
        try {
            let res = await fetch(`${url()}/coach/game/sequence`, {
                method: 'POST',
                body: JSON.stringify(newSequence),
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (res.status === 200) {
                addMessage(`Successfully created sequence`, 'success')
            } else {
                const err = await res.json()
                addMessage(`Error: ${err.message}`, 'warning')
            }
        } catch (e) {
            addMessage(`Error: ${e.message}`, 'warning')
        } finally {
            setTagsOpen(false)
        }

    }

    return (
        <>
            <Modal
                size="tiny"
                open
            >
                <Modal.Header>
                    <Header>Modify {active.category}</Header>
                    <p>Sequences</p>
                </Modal.Header>
                <Modal.Content>
                    <Segment basic>
                        <p>Create a sequence:</p>
                        <Menu compact>
                            <Dropdown
                                item
                                text={half}>
                                <Dropdown.Menu>
                                    {
                                        active.periods.map(period =>
                                            <Dropdown.Item
                                                text={getPeriod(period.number)}
                                                key={period.number}
                                                onClick={() => handleHalf(period.number, period.start_time, period.end_time)}
                                            />
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu>
                    </Segment>
                    {half !== 'Pick period' &&
                        <Form>
                            <Form.Field required>
                                <Label>{"Name"}</Label>
                                <Form.Input
                                    placeholder={"name"}
                                    value={sequenceName}
                                    onChange={(e) => setSequenceName(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <Label>{"Description"}</Label>
                                <Form.Input
                                    placeholder={"Description"}
                                    value={sequenceDescription}
                                    onChange={(e) => setSequenceDescription(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field required>
                                <Label>{"Start Time"}</Label>
                                <div className="row">
                                    <Form.Input
                                        placeholder={periodData.startmin}
                                        maxLength="3"
                                        value={sequenceStart}
                                        onChange={(e) => setSequenceStart(e.target.value.replace(/\D/g, ''))
                                        }
                                    />
                                    <p>:</p>
                                    <Form.Input
                                        placeholder={periodData.startsec}
                                        maxLength="2"
                                        value={sequenceStartSecond}
                                        onChange={(e) => {
                                            const val = e.target.value.replace(/\D/g, '')
                                            setSequenceStartSecond(checkSeconds(val))
                                        }
                                        }
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field required>
                                <Label>{"End Time"}</Label>
                                <div className="row">
                                    <Form.Input
                                        placeholder={periodData.endmin}
                                        maxLength="3"
                                        value={sequenceEnd}
                                        onChange={(e) => setSequenceEnd(e.target.value.replace(/\D/g, ''))
                                        }
                                    />
                                    <p>:</p>
                                    <Form.Input
                                        placeholder={periodData.endsec}
                                        maxLength="2"
                                        value={sequenceEndSecond}
                                        onChange={(e) => {
                                            const val = e.target.value.replace(/\D/g, '')
                                            setSequenceEndSecond(checkSeconds(val))
                                        }
                                        }
                                    />
                                </div>
                            </Form.Field>
                            <Button
                                onClick={() => setTagsOpen(true)}
                            >Select Tags...</Button>
                        </Form>}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => createSequence()}>Create</Button>
                    <Button onClick={() => setOpenModal({sequence: false})}>Close</Button>
                </Modal.Actions>
            </Modal>
            {tagsOpen &&
                <Tags
                    setTags={(val) => setTags(val)}
                    tags={tags}
                    setTagsOpen={(val) => setTagsOpen(val)}
                    existingSequence={{id: 0, isTrue: false}} //This is used so tags knows if it is an existing sequence
                />}
        </>
    )
}