import {Button, Header, Segment} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import cookies from 'js-cookie';
import './ConfirmAddTeamMember.css'
import {url, debug} from "../../../../config";
import {addMessage} from "../../../../lib/functions/notifications";


const ConfirmAddTeamMember = () => {

    let history = useHistory();

    const getParam = (value) => {
        let param = history.location.search;
        let findParams = new URLSearchParams(param.substring(1));
        return findParams.get(value)
    }

    const redirect = (delay) => {
        setTimeout(() => {
            history.push("/profile")
        }, delay)
    }

    const confirm = async () => {

        try {
            let findParams = new URLSearchParams(history.location.search.substring(1));

            let data = await fetch(`${url()}/api/soccer/teams/confirm?uid=${getParam("uid")}`, {
                method: 'POST',
                body: JSON.stringify({role: findParams.get("role")}),
                headers: new Headers({
                    "content-type": "application/json",
                    'authorization': `Bearer ${cookies.get("session")}`
                })
            })
            let res = await data.json()
            const status = data.status;
            debug(res);

            if (status === 200) {
                //positive response, redirect
                addMessage("Successfully added! Redirecting...", "success")
            } else if (status === 409) {
                //user already exists
                addMessage("You have already been added to this team. Redirecting...", "info")
            } else if (status === 400) {
                //missing body/malformed json
                addMessage("Invalid data sent.", "warning")
            } else if (status === 500) {
                addMessage("Interal server error.", "warning")
            } else {
                debug(res)
                addMessage("Cannot find this request.", "warning")
            }
        } catch (error) {
            debug(error)
        } finally {
            redirect(2000)
        }
    }
    return (
        <div className="center">
            <Segment>
                <Header as="h2">Confirm adding to Team</Header>
                <p>Do you wish to be added to team {getParam("team")} as a {getParam("role")}?</p>
                <Button onClick={confirm}>Confirm</Button>
            </Segment>
        </div>
    )
}
export default ConfirmAddTeamMember;