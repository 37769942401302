import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Header, Menu, Segment } from "semantic-ui-react";
import i18next from 'i18next';

import "./Cover.css";

const Cover = ({ children }) => {

  const [menu, setMenu] = useState({ home: true });
  const history = useHistory();

  useEffect(() => { //handles menu by changing the state based on path

    let path = history.location.pathname;
    let mounted = true;

    if (mounted === true) {

      if (path === "/signup") {
        setMenu({ signup: true });
      }
      if (path === "/login") {
        setMenu({ login: true });
      }
      if (path === "/") {
        setMenu({ home: true })
      }
    }

    return function cleanup() {
      mounted = false;
    }

  }, [history.location.pathname]);


  return (
    <>
      <div className="App">
        <Segment inverted vertical textAlign="center">
          <Container as="nav">
            <Header inverted as="h1">
              {i18next.t("layout.header")}
            </Header>
            <Menu borderless compact inverted>
              {/* <Menu.Item active={menu.home} onClick={() => history.push("/")}>{i18next.t("layout.home")}</Menu.Item> */}
              <Menu.Item active={menu.login} onClick={() => history.push("/login")}>{i18next.t("layout.login")}</Menu.Item>
              <Menu.Item active={menu.signup} onClick={() => history.push("/signup")}>{i18next.t("layout.signup")}</Menu.Item>
            </Menu>
          </Container>
          <Container className="content">
            {children}
          </Container>
        </Segment>
      </div>
    </>
  )
}

export default Cover;
