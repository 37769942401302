import cookies from 'js-cookie';
import {debug, url} from '../../config';

export const getPlayerData = async (teamId, pending) => {

    try {
        let pendingPlayerQuery = ""
        if (pending) {
            pendingPlayerQuery = "?pending=true"
        }
        let data = await fetch(`${url()}/api/soccer/teams/${teamId}${pendingPlayerQuery}`, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${cookies.get("session")}`
            })
        });

        let res = await data.json();
        return res;

    } catch (error) {
        debug(error)
        return error;
    }

}

export const teamsUserManages = (userTeams) => {

    if (userTeams === undefined) {
        debug("undefined value")
        return [];
    }

    let arrayOfTeams = [];

    userTeams.forEach(team => {
        if (team.role === "Manager" || team.owner === true) {
            arrayOfTeams.push(team);
        }
    });
    return arrayOfTeams;
};

export const getTeamPlayers = (userTeams) => {
    if (userTeams === undefined) {
        debug("undefined value")
        return [];
    }

    let arrayOfTeams = [];

    userTeams.forEach(team => {
        if (team.role === "Player") {
            arrayOfTeams.push(team);
        }
    });
    return arrayOfTeams;
}


export const getUserData = async (sessionId, userId, timeFrom, timeTo) => {

    let urlWithParams; //handler for time filter
    if (timeFrom === undefined || timeTo === undefined) {
        urlWithParams = `${url()}/api/soccer/results/${sessionId}?player=${userId}`
    } else {
        urlWithParams = `${url()}/api/soccer/results/${sessionId}?player=${userId}&timeStart=${timeFrom}&timeEnd=${timeTo}`
    }
    let data = await fetch(urlWithParams, {
        method: 'GET',
        mode: 'cors',
        headers: new Headers({
            "content-type": 'application/json',
            // 'cookie': `access_token=${cookies.get("user")}`
            Authorization: `Bearer ${cookies.get("session")}`
        })
    });
    let res = await data.json();
    return res;
};