import { Grid, Header, Button } from 'semantic-ui-react';
import { getCSVData } from '../../../lib/functions/CSVFunctions';
import i18next from 'i18next';

const ExerciseAverages = ({ results, exercise, exerciseSelected }) => {

    return (
        <div className="result">
            {!exerciseSelected && <div>

                <Header as="h3" className="title">
                {i18next.t("general.results.exercise.averages_title")}
                </Header>
                <Grid className="grid">

                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_success")}</p>
                        <p>{results.exercises[exercise].score}/{results.exercises[exercise].outOf}</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanning")}</p>
                        <p>{results.exercises[exercise].averageScanningPercentage.toPrecision(2)}%</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_actionTime")}</p>
                        <p>{results.exercises[exercise].averageDecisionTime.toPrecision(1)}s</p>
                    </Grid.Row>
                    <Grid.Row id="row"><p>{i18next.t("general.results.exercise_scanPerSecond")}</p>
                        <p>{results.exercises[exercise].averageScanningFrequency.toPrecision(1)}</p>
                    </Grid.Row>
                    <Grid.Row>
                        <Button onClick={() => getCSVData(results)}>{i18next.t("general.results.exercise_getCSV")}</Button>
                    </Grid.Row>
                </Grid>
            </div>}
        </div>
    )

}

export default ExerciseAverages;