import { Button, Header } from 'semantic-ui-react'
import { DateTime } from 'luxon';
import i18next from 'i18next';

import '../ExerciseButtons/ExerciseButtons.css'

const ExerciseButtons = ({ whatToDo, exerciseSelect, aggregateSelected, results, exerciseState }) => {

    return (
        <div>
            <div className="results">
                <div className="grid">
                    <Header as="h4" id="exerciseText">{DateTime.fromISO(results.datePosted).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Header>
                    <Header as="h2" id="exerciseText">{i18next.t("general.results.execise_exerciseHeader")}</Header>
                </div>
                <div className="grid">
                    <div className="buttonRow">
                        <Button.Group>
                            {results.exercises.map((item, index) =>
                                <Button id="button" key={index} className={exerciseState === index ? "active" : ""} onClick={() => exerciseSelect(index)}>{index + 1}</Button>)}
                            <Button id="button" key={-1} className={exerciseState === -1 ? "active" : ""} onClick={() => aggregateSelected()}>{i18next.t("general.results.exercise_aggregateButton")}</Button>
                        </Button.Group>
                    </div>
                </div>
            </div>
            <div className="whatToDo"><Header as="h3">{whatToDo}</Header></div>
        </div>
    )

}

export default ExerciseButtons;