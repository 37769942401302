import i18next from "i18next";
import { useState } from "react"
import { Button, Form, Header, Label, Input, Loader } from "semantic-ui-react";
import cookies from "js-cookie";
import { url, debug } from "../../../../config";
import { addMessage } from "../../../../lib/functions/notifications";

const AddNewMember = ({ memberType, handleClose, teamId, numberOfUsers }) => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        setLoading(true)
        let emailFormat = false; //checks if email contains @ as a basic client side check
        for (let i = 0; i < email.length; i++) {
            if (email[i] === "@") {
                emailFormat = true;
            }
        }
        if (!emailFormat) {
            addMessage(i18next.t("soccer_team.add.invalid_email"), "warning")
            // handleClose()
            setLoading(false)
            return;
        }

        let role;
        // debug(numberOfUsers)

        if (numberOfUsers >= 4 && memberType === "staff member") {
            addMessage("You cannot add more than 4 staff members.", "warning")
            setLoading(false)
            debug("more than 4 staff")
            return;
        }
        // debug(memberType)
        if (memberType === "staff member") {
            role = "Manager";
        } else {
            role = "Player"; //this is necessary as API looks for the "Player" role, not "player""
        }

        let data = await fetch(`${url()}/api/soccer/teams/${teamId}/members?player=${email}&role=${role}`, {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${cookies.get("session")}`
            })
        });

        // debug(data)
        if (data.status === 200) { //success
            addMessage(i18next.t("soccer_team.add.success", { memberType: memberType }), "success")
        } else if (data.status === 403) { //forbidden
            addMessage(i18next.t("soccer_team.add.failure.403", { memberType: memberType }), "warning")
        } else if (data.status === 404) { //not found
            addMessage(i18next.t("soccer_team.add.failure.404", { memberType: memberType }), "warning")
        } else {
            addMessage(i18next.t("soccer_team.add.general_error", { memberType: memberType }), "warning")
        }
        setLoading(false)
        window.scrollTo(0, 0);
        handleClose();
    }

    if (loading) {
        return <Loader active={true} size="massive">Loading</Loader>
    } else {
        return (
            <div>
                <Form onSubmit={submitHandler}>
                    <Header as="h2" inverted>{`Add a ${memberType}`}</Header>
                    <p>{`Enter the email address of a registered ${memberType} to add them to the team.`}</p>
                    <Form.Field inline={true} className="field">
                        <Label className="teal label">{i18next.t("general.login.email")}</Label>
                        <Input placeholder={i18next.t("general.login.email")} onChange={(e) => setEmail(e.target.value.trim())} value={email} />
                    </Form.Field>
                    <Button type="submit">{i18next.t("soccer_team.manage_team.submit")}</Button>
                    <Button type="button" onClick={handleClose}>{i18next.t("soccer_team.manage_team.cancel")}</Button>
                </Form>
            </div >
        )
    }
}

export default AddNewMember;