import styled from "styled-components";

export const Wrapper = styled.div`
  //width: 100%;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WidgetWrapper = styled.div`
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 8px 25px #3558c738;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    :hover {
      cursor: ${({isUploading}) => isUploading ? "wait" : "pointer"};
    }
  }

  .content {
    height: 100%;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 10px;
  background: #e8eef4;
  margin-top: 20px;
  position: relative;

  div {
    width: ${({percentage}) => `${percentage}%`};
    transition: width 1s;
    height: 100%;
    background: #2553ff;
    border-radius: 14px;
  }
`;

export const Count = styled.p`
  font-size: 20px;
  margin-left: 50px;
`;
