import { useState } from 'react';
import {
    Table,
    Button,
    Dimmer,
    Loader,
    Header
} from 'semantic-ui-react';
import i18next from 'i18next';
import AddNewMember from '../AddNewMember/AddNewMember'

const Managers = ({ managers, removeTeamMember, selectedTeam, setManagers, loading }) => {

    const [DimmerHandler, setDimmerHandler] = useState(false);

    if (loading) {
        return (
            <>
                <Header as="h2">{i18next.t("soccer_team.manage_team.staff")}</Header>
                <Loader active inline >Loading</Loader>
            </>
        )
    } else {

        return (
            <div>
                {/* <div className="row"> */}
                <Header as="h2">{i18next.t("soccer_team.manage_team.staff")}</Header>
                {/* <p>{`[${managers.length}/4]`}</p>
                </div> */}
                {managers.length === 0 &&
                    <p>{i18next.t("soccer_team.manage_team.staff.no_data")}</p>
                }
                <div>
                    {managers.length > 0 &&

                        <Table celled selectable columns="3">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{i18next.t("soccer_team.manage_team.staff.name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{i18next.t("soccer_team.manage_team.staff.status")}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {managers.map((manager, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>{manager.playerName}</Table.Cell>
                                        <Table.Cell>{manager.status}</Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={() => removeTeamMember(manager.playerId, manager.playerName, "staff")}>Remove</Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    }
                    <Button onClick={() => setDimmerHandler(true)}>{i18next.t("soccer_team.manage_team.add_staff")}</Button>
                    <Dimmer active={DimmerHandler} onClickOutside={() => setDimmerHandler(false)} page>
                        <AddNewMember
                            memberType="staff member"
                            numberOfUsers={managers.length}
                            teamId={selectedTeam.teamId}
                            handleClose={() => setDimmerHandler(false)}
                            setUsers={data => setManagers(data)}
                            users={managers}
                        />
                    </Dimmer>
                </div>
            </div>
        )
    }
}

export default Managers;