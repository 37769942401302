import {useState} from 'react';
import {Header, Loader} from 'semantic-ui-react';
import ExerciseButtons from '../../../session_results/ExerciseButtons/ExerciseButtons';
import AttemptsButtons from '../../../session_results/AttemptsButtons/AttemptsButtons';
import Attempts from '../../../session_results/Attempts/Attempts';
import ExerciseAggregate from '../../../session_results/ExerciseAggregate/ExerciseAggregate';
import PitchResultChart from '../../../session_results/PitchResultChart/PitchResultChart';

const IndividualResult = ({individualResult, getUser, loading}) => {

    const [exercise, setExercise] = useState(-1);
    const [attempt, setAttempt] = useState(-1);
    const [exerciseSelected, setExerciseSelected] = useState(true);

    // debug(individualResult)

    const exerciseHandler = (index) => {
        if (exerciseSelected) {
            setExerciseSelected(false);
        }
        setAttempt(-1);
        setExercise(index);
    }

    const setExerciseAggregate = () => {
        setExerciseSelected(true);
        setExercise(-1);
    }

    const attemptHandler = (index) => {
        setAttempt(index);
    }

    if (loading) {
        return (
            <Loader size="massive" className="centered" active inline>Loading</Loader>
        )
    }

    return (
        <div className="resultsContainer">
            {individualResult && <Header as="h2" textAlign="center">{getUser(individualResult.userId)}</Header>}
            {individualResult && <ExerciseButtons
                whatToDo={exerciseSelected ? "" : individualResult.exercises[exercise].whatToDo}
                exerciseSelect={exerciseHandler} aggregateSelected={setExerciseAggregate}
                exerciseNum={individualResult.exercises} results={individualResult} exerciseState={exercise}
            />
            }
            {individualResult &&
                <div className="summary">
                    <div className="attemptData">
                        {!exerciseSelected &&
                            <AttemptsButtons
                                attempt={individualResult.exercises[exercise].plays}
                                selectAttempt={attemptHandler} attemptState={attempt}/>}

                        {!exerciseSelected ? <Attempts results={individualResult} exercise={exercise} attempt={attempt}
                                                       attemptSelected={attempt !== -1}
                                                       exerciseSelected={exerciseSelected}/>
                            :
                            <ExerciseAggregate results={individualResult}/>}
                    </div>
                    {!exerciseSelected &&
                        <PitchResultChart exercise={exercise} attempt={attempt} results={individualResult}/>}
                </div>}
        </div>
    )
}

export default IndividualResult;