import { UploadWidget } from "../../../lib/upload_component/UploadWidget";
import './UploadGames.css'
import {debug, url} from "../../../config";
import { Header, Segment, Dropdown, Form, Menu } from "semantic-ui-react";
import { addMessage } from "../../../lib/functions/notifications";
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import i18next from "i18next";

export const UploadGames = () => {

    let history = useHistory()
    const [uploadType, setUploadType] = useState({ text: i18next.t("coachElite.upload.uploadTypeDefault"), type: null });
    const [uploadDescription, setUploadDescription] = useState("")

    const onSuccess = () => {
        addMessage(i18next.t("coachElite.upload.success"), 'info')
        history.push('/view_uploaded_games')
    }
    const onFailure = (e) => {
        debug(e)
        if (e.response) {
            debug(e.response.data.error)
            // if (e.response.data.error.includes('403')) {
            //     addMessage('Unable to upload: Selected file is too large.', 'warning', 7)
            //     return
            // }
            addMessage(e.response.data.error, 'warning', 7)
        } else {
            addMessage(i18next.t("coachElite.upload.failure"), 'warning')
        }
        history.push('/upload_sequence')
    }

    return (
        <div>
            <Header as="h1">
                {i18next.t("coachElite.upload.header")}
            </Header>
            <p>
                {i18next.t("coachElite.upload.content1")}
                {" "}
                <a href="https://www.secondspectrum.com/index.html">{i18next.t("coachElite.upload.content.secondSpectrumUrl")}</a>
                {" "}
                {i18next.t("coachElite.upload.content2")}
            </p>
            <Segment basic>
                <Menu compact>
                    <Dropdown
                        item
                        text={uploadType.text}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => setUploadType({ text: "Second Spectrum", type: "secondspectrum" })}>Second
                                Spectrum</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setUploadType({ text: "Pixel Mill", type: "pixelmill" })}>Pixel
                                Mill</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
            </Segment>
            {uploadType.type !== null && <Segment basic className="column">
                <Form>
                    <Form.Field required>
                        <Form.Input
                            placeholder={"Description (required)"}
                            value={uploadDescription}
                            onChange={(e) => setUploadDescription(e.target.value)}
                        />
                    </Form.Field>
                </Form>
            </Segment>}
            {uploadType.type !== null && uploadType.type === 'secondspectrum' &&
                <Segment basic>
                    <a
                        href={process.env.REACT_APP_PM_SECOND_SPECTRUM_EXAMPLE}
                        target="_blank"
                        rel="noreferrer"
                    // download
                    >{i18next.t("coachElite.upload.secondSpectrumFormat")}</a>
                </Segment>
            }
            {uploadType.type !== null && uploadType.type === 'pixelmill' &&
                <Segment basic>
                    <a
                        href={process.env.REACT_APP_PM_COACH_GAME_DATA_EXAMPLE}
                        target="_blank"
                        rel="noreferrer"
                    //download
                    >{i18next.t("coachElite.upload.pixelmillFormat")}</a>
                </Segment>
            }
                <Segment basic disabled={uploadType.type === null || uploadDescription === ''} className="column">
                    <UploadWidget
                        path={`${url()}/coach/upload`}
                        onSuccess={() => onSuccess()}
                        onFailure={(e) => onFailure(e)}
                        uploadType={uploadType.type}
                        uploadDescription={uploadDescription}
                    />
                </Segment>

        </div>
    )
}