import {Button, List, Modal, Popup} from 'semantic-ui-react';
import {Header} from 'semantic-ui-react';
import '../AttemptsButtons/AttemptsButtons.css'
import '../PitchResultChart/PitchResultChart.css'
import i18next from 'i18next';
import React, {useState} from "react";

const AttemptsButtons = ({attempt, selectAttempt, attemptState}) => {

    const [showLegend, setShowLegend] = useState(false)

    return (
        <div className="attempt">
            <Header as="h2">{i18next.t("general.results.exercise_attemptHeader")}</Header>
            <div className="grid2">
                <Button.Group className="attempts" compact>
                    <Button id="button" key={-1} className={attemptState === -1 ? "active" : ""}
                            onClick={() => selectAttempt(-1)}>{i18next.t("general.results.exercise_attemptSummary")}</Button>
                    {attempt.map((item, index) =>
                        <Button id="button" key={index} className={attemptState === index ? "active" : ""}
                                onClick={() => selectAttempt(index)}>{index + 1}</Button>
                    )}
                    <Button onClick={() => setShowLegend(!showLegend)}>Legend</Button>
                </Button.Group>
            </div>

            <Modal open={showLegend} size="mini">
                <Modal.Header>{i18next.t("general.results.attempt.legend_header")}</Modal.Header>
                <Modal.Content>
                    <List>
                        <List.Item>
                            <div className="legendList">
                                <p className="player legendSize">▉</p>
                                <p>{i18next.t("general.results.attempt.legend_player")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="friend legendSize">▉</p>
                                <p>{i18next.t("general.results.attempt.legend_ally")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="foe legendSize">▉</p>
                                <p>{i18next.t("general.results.attempt.legend_enemy")}</p>
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="segment vision legendSize">▉</p>
                                <p>{i18next.t("general.results.attempt.legend_vision")}</p>
                                <Popup
                                    trigger={<p className="toolTip">?</p>}
                                    content={i18next.t("general.results.attempt.legend.tooltip_vision")}
                                />
                            </div>
                        </List.Item>
                        <List.Item>
                            <div className="legendList">
                                <p className="segment targetAngle legendSize">▉</p>
                                <p>{i18next.t("general.results.attempt.legend_pass")}</p>
                                <Popup
                                    trigger={<p className="toolTip">?</p>}
                                    content={i18next.t("general.results.attempt.legend.tooltip_pass")}
                                />
                            </div>
                        </List.Item>
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowLegend(false)}>Close</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default AttemptsButtons;