import {useEffect, useState} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
} from "react-router-dom";
import 'semantic-ui-less/semantic.less';
import cookies from 'js-cookie';

import {teamFetch, sessionTokenFetch, fetchUserData, userDataObj} from './lib/functions/LoginHelperFunctions'
import Cover from "./layouts/Cover";
import Dashboard from "./layouts/Dashboard";
import PairHeadset from './components/User/Pairing/PairHeadset'
import SessionResultsSummary from './components/session_results/SessionResultsSummary/SessionResultsSummary'
import MySubs from './components/User/MySubs/MySubs'
import Login from "./components/User/Login/Login";
import PasswordReset from './components/User/PasswordReset/PasswordReset';
import SignUp from './components/User/SignUp/SignUp';
import Profile from './components/Profile/Profile';
import TeamsHome from './components/Teams/TeamManagement/TeamsHome/TeamsHome';
import TeamResultsMain from './components/Teams/TeamResults/TeamResultsMain/TeamResultsMain';
import NewPassword from './components/User/NewPassword/NewPassword';
import SessionExpired from './components/User/SessionExpired/SessionExpired';
import i18next from 'i18next';
import {Loader} from 'semantic-ui-react';
import {isProfileFilledIn} from './lib/functions/ProfileHelperFunctions';
import ConfirmAddTeamMember from './components/Teams/TeamManagement/ConfirmAddTeamMember/ConfirmAddTeamMember';
import {debug, url} from './config';
import PairHeadsetCoach from './components/coach/PairHeadset/PairHeadsetCoach';
import StartingPositions from './components/coach/StartingPositions/StartingPositions';
import Purchase from './components/Purchase/Purchase';
import GetStarted from "./components/coach/GetStarted/GetStarted";
import {Users} from "./components/Admin/Subscriptions/Users/Users";
import {Sequences} from "./components/coach/Sequences/Sequences";
import {UploadGames} from "./components/coach/UploadGames/UploadGames";
import {ViewUploadedGames} from "./components/coach/ViewUploadedGames/ViewUploadedGames";
import {ViewGameSequences} from "./components/coach/ViewGameSequences/ViewGameSequences";

export default function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(false);
    const [profileFilledIn, setProfileFilledIn] = useState(false);

    let history = useHistory();

    const redirectHandler = (JSX, layout) => {

        if (layout === "confirm") { //layout that persists regardless of being logged in or not
            return (
                <Cover>
                    {JSX}
                </Cover>
            )
        }

        if (loggedIn) {
            if (profileFilledIn) { //if user is logged in and profile is filled in

                if (layout === "dashboard") {
                    return (
                        <Dashboard setUser={data => setUser(data)} user={user} team={team} logout={logoutHandler}>
                            {JSX}
                        </Dashboard>
                    );
                } else if (layout === "cover") {
                    return (
                        <Cover>
                            {JSX}
                        </Cover>
                    )
                }

            } else { //if user is logged in, but not filled in profile
                return <Redirect to="/profile"/>
            }

        } else {
            //if user is not logged in
            return <Redirect to="/login"/>
        }
    }

    const logoutHandler = () => { //clear all cookies and set as logged out

        setLoggedIn(false);
        setUser({});
        setProfileFilledIn(false);
        cookies.remove("user");
        cookies.remove("session");
        localStorage.removeItem('userdata');
        cookies.remove("path");
    }

    const updateTeamHandler = (newName) => {
        const updatedTeam = team;
        team.teamName = newName;
        setTeam(updatedTeam)
    }

    useEffect(()=> {
        //try to make sure page will clear all data correctly on login page
        if (!loggedIn) {
            debug('Clearing user')
            setUser({})
        }
    }, [loggedIn])

    useEffect(() => {

        if (history.location.search === "?success=true" && loggedIn === true) {
            if (user !== "placeholder") {
                // console.log("running fetch user features after 5 seconds elapses")

                setTimeout(async () => { //should only be called on purchase, will update features after 5 seconds
                    // console.log("running fetch user features")

                    let data = await fetch(`${url()}/auth/user/${user.id}`, {
                        method: "GET",
                        headers: new Headers({
                            "content-type": 'application/json',
                            Authorization: `Bearer ${cookies.get("session")}`
                        })
                    })

                    let res = await data.json();
                    // console.log(res);
                    let updatedUser = user;
                    updatedUser.features = res.features;
                    setUser(updatedUser)

                }, 5000)
            }
        }

    }, [user, history.location.search, loggedIn])

    useEffect(() => { //checks user has filled profile, locks user to profile page if they have not done so.
        let path = history.location.pathname;
        let param = history.location.search;

        if (profileFilledIn) {
            cookies.set("path", path + param);
        } else {
            cookies.get("Path", "/profile");
        }

    }, [history, profileFilledIn])

    useEffect(() => {
        const checkLoginState = async () => {
            //check state of login upon refresh or returning to page and restore the session if true
            if (cookies.get('user') !== undefined) {
                if (cookies.get('user') !== null) {
                    // console.log("running checkLoginState")
                    setLoading(true)
                    let sessionToken = await sessionTokenFetch();
                    cookies.set("session", sessionToken.token, {expires: 1 / 24});
                    let teamData = await teamFetch(cookies.get('userdata'));
                    let userData = await fetchUserData(cookies.get('userdata'));
                    if (isProfileFilledIn(userData) === true) {
                        setProfileFilledIn(true)
                    }
                    setTeam(teamData);
                    setUser(userDataObj(userData));
                    setLoggedIn(true);
                    setLoading(false);
                } else {
                    debug("invalid user cookie")
                    setLoggedIn(false);
                }
            }
        }
        checkLoginState();
    }, []);

    useEffect(() => { //checks valid cookie exists for session whilst logged in, if not fetches new one
        const refreshSessionToken = async () => {
            let sessionToken = await sessionTokenFetch();
            if (sessionToken !== undefined) {
                cookies.set("session", sessionToken.token, {expires: 1 / 24});
            } else {
                debug("invalid user cookie")
                cookies.remove('user');
            }
            //maybe due to it always running, it does not have access to an updated user state
        }

        setInterval(() => {
            if (cookies.get("session") === undefined) {
                if (cookies.get("user") !== undefined) { //if session is expired and user token exists
                    refreshSessionToken();
                    debug("refreshing session token")
                }
            }
        }, 1000); //Check every second.

    }, []);


    if (loading) {
        return (
            <Loader active size="massive">{i18next.t("general.refresh")}</Loader>
        )
    } else {

        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/profile"/>
                    </Route>
                    <Route exact path="/logged_in_home">
                        {redirectHandler(<Purchase user={user} setUser={data => setUser(data)}/>, "dashboard")}
                        {/* {redirectHandler(<Header as="h1">Home</Header>, "dashboard")} */}
                    </Route>
                    <Route exact path="/profile">
                        {loggedIn ?
                            <Dashboard setUser={data => setUser(data)} user={user} team={team} logout={logoutHandler}>
                                <Profile user={user} setUser={data => setUser(data)}
                                         setProfileFilledIn={data => setProfileFilledIn(data)}/>
                            </Dashboard>
                            :
                            <Redirect to="/login"/>
                        }
                    </Route>
                    <Route exact path="/pair_headset">
                        {redirectHandler(<PairHeadset/>, "dashboard")}
                    </Route>
                    <Route exact path="/session_results">
                        {redirectHandler(<SessionResultsSummary/>, "dashboard")}
                    </Route>
                    <Route exact path="/subscriptions">
                        {redirectHandler(<MySubs user={user}/>, "dashboard")}
                    </Route>
                    <Route exact path="/login">
                        {!loggedIn ?
                            <Cover>
                                <Login
                                    loggedIn={value => setLoggedIn(value)}
                                    username={value => setUser(value)}
                                    userTeams={data => setTeam(data)}
                                    setProfileFilledIn={data => setProfileFilledIn(data)}
                                    logoutHandler={logoutHandler}
                                />
                            </Cover>
                            :
                            <Redirect to={cookies.get("path") === "/login" ? "/profile" : cookies.get("path")}/>
                        } {/*redirect will use cookie defined path */}
                    </Route>
                    <Route exact path="/reset_password">
                        <Cover>
                            <PasswordReset/>
                        </Cover>
                    </Route>
                    <Route exact path="/new_password">
                        <Cover>
                            <NewPassword/>
                        </Cover>
                    </Route>
                    <Route exact path="/signup">
                        <Cover>
                            <SignUp/>
                        </Cover>
                    </Route>
                    <Route exact path="/team_management">
                        {redirectHandler(<TeamsHome team={team} updateTeamHandler={data => updateTeamHandler(data)}
                                                    user={user}/>, "dashboard")}
                    </Route>
                    <Route exact path="/confirm_add">
                        {redirectHandler(<ConfirmAddTeamMember/>, "confirm")}
                    </Route>
                    <Route exact path="/team_results">
                        {redirectHandler(<TeamResultsMain team={team}/>, "dashboard")}
                    </Route>
                    <Route exact path="/session_expired">
                        <SessionExpired/>
                    </Route>
                    <Route exact path="/coach_pair_headset">
                        {redirectHandler(<PairHeadsetCoach/>, "dashboard")}
                    </Route>
                    <Route exact path="/starting_positions">
                        {redirectHandler(<StartingPositions/>, "dashboard")}
                    </Route>
                    <Route exact path="/coach_download">
                        {redirectHandler(<GetStarted/>, "dashboard")}
                    </Route>
                    <Route exact path="/sequences">
                        {redirectHandler(<Sequences/>, "dashboard")}
                    </Route>
                    <Route exact path="/upload_sequence">
                        {redirectHandler(<UploadGames/>, "dashboard")}
                    </Route>
                    <Route exact path="/view_uploaded_games">
                        {redirectHandler(<ViewUploadedGames/>, "dashboard")}
                    </Route>
                    <Route exact path="/view_game_sequences">
                        {redirectHandler(<ViewGameSequences/>, "dashboard")}
                    </Route>
                    <Route exact path="/admin/users">
                        {redirectHandler(<Users user={user}/>, "dashboard")}
                    </Route>
                    {redirectHandler()}
                </Switch>
            </Router>
        );
    }
}
