export const convertMilisToMinsAndSeconds = (millis, half) => {
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    // return (minutes + half) + ':' + (seconds < 10 ? '0' : '') + seconds
    return [(minutes + half), seconds < 10 ? '0' : '' + seconds]
}

export const convertMinsAndSecondsToFrames = (time, fps) => {
    console.log(typeof (time))
    if (time.length < 3) {
        time = "0:" + time
    }
    let [minutes, seconds] = time.split(':')
    return ((+minutes * 60) + +seconds) * fps
}

export const convertMinsAndSecondsToFramesByHalf = (time, fps, half, periodStart, periodEnd) => {
    if (time.length < 3) {
        time = "0:" + time
    }
    const halfMod = half === "1" ? 0 : half === "2" ? 45 : half === "3" ? 90 : half === "4" ? 105 : 0
    let [minutes, seconds] = time.split(':')
    const ret = (((+minutes - halfMod) * 60) + +seconds) * fps + periodStart
    if (ret < periodEnd) {
        return ret
    } else {
        return periodEnd
    }
}

export const checkSeconds = (time) => {
    if (+time > 59) {
        return "59"
    }
    return time
}