import React from 'react';
import AttemptResults from '../AttemptResults/AttemptResults';
import ExerciseAverages from "../ExerciseAverages/ExerciseAverages";

const Attempts = ({ results, exercise, attempt, attemptSelected, exerciseSelected }) => {

    if (attemptSelected) {
        return (
            <AttemptResults results={results} exercise={exercise} attempt={attempt} />
        )
    } else {
        return (
            <ExerciseAverages results={results} exercise={exercise} exerciseSelected={exerciseSelected} />
        )
    }
}

export default Attempts;