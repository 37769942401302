import {Button, Dropdown, Input, Label, Modal} from "semantic-ui-react";
import {DateTime} from "luxon";
import DatePicker from "react-datepicker";
import {useState} from "react";
import {addMessage} from "../../../../lib/functions/notifications";
import {url} from "../../../../config";
import cookies from "js-cookie";
import {debug} from "../../../../config";

export const AddSubscription = ({dialog, setDialog}) => {

    const [startDate, setStartDate] = useState(DateTime.now().toJSDate())
    const [endDate, setEndDate] = useState(DateTime.now().plus({months: 1}).toJSDate())
    const [selectedSub, setSelectedSub] = useState("Select...")

    // console.log(dialog)

    const userSubscriptions = () => {
        const sub = dialog.sub.map(sub => {
            //add condition checking if subscription is active
            return sub.role
        })
        let list = ["Coach", "CoachElite", "Individual", "Team"]
        let subscriptionList = []
        list.forEach(subscription => {
            if (!sub.includes(subscription)) {
                subscriptionList.push(subscription)
            }
        })
        return subscriptionList
    }

    const createNewSubscription = async () => {

        if (selectedSub === "Select...") { //prevent API call if subscription not selected
            addMessage("You must select a subscription type", "warning")
            return
        }
        debug(JSON.stringify({
            type: selectedSub,
            from: DateTime.fromJSDate(startDate).toISODate(),
            to: DateTime.fromJSDate(endDate).toISODate()
        }))
        try {
            let data = await fetch(`${url()}/api/admin/subscription/create/${dialog.userId}`, {
                method: 'POST',
                body: JSON.stringify({
                    type: selectedSub,
                    from: DateTime.fromJSDate(startDate).toISODate(),
                    to: DateTime.fromJSDate(endDate).toISODate()
                }),
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('session')}`
                })
            })
            if (data.status === 200) {
                addMessage(`${selectedSub} subscription added to ${dialog.user}'s account.`, "success")
            } else {
                let res = await data.json()
                debug(res)
                addMessage(`Unable to add ${selectedSub} to ${dialog.user}'s account.`, "success")
            }
        } catch (e) {
            debug(e)
            addMessage('Unable to create subscription', 'warning')
        } finally {
            setDialog({addSub: false, showSub: false, user: undefined, sub: undefined})
        }
    }

    return (
        <Modal open={true} size="small">
            <Modal.Header>Add New Subscription</Modal.Header>
            <Modal.Content>
                <div>
                    <div className="subscriptionModalRow">
                        <Label>User:</Label>
                        <Input>{dialog.user}</Input>
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Type:</Label>
                        {userSubscriptions().length > 0 ?
                            <Dropdown text={selectedSub}>
                                <Dropdown.Menu>
                                    {userSubscriptions().map((sub, index) =>
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => setSelectedSub(sub)}
                                        >{sub}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            <p>{`${dialog.user} has every type of subscription.`}</p>}
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Start:</Label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd/MM/yyyy"
                            dropdownMode="select"
                        />
                    </div>
                    <div className="subscriptionModalRow">
                        <Label>Expiry:</Label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd/MM/yyyy"
                            dropdownMode="select"
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => createNewSubscription()}>
                    Confirm
                </Button>
                <Button onClick={() => setDialog({
                    add: false,
                    show: false,
                    user: undefined,
                    sub: undefined
                })}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}