import cookies from 'js-cookie';
// import i18next from 'i18next';
import {debug, url} from '../../config';
import { DateTime } from 'luxon';

export const getMemberships = async () => {

    try {
        let data = await fetch(`${url()}/api/soccer/teams`, {
            method: "GET",
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${cookies.get("session")}`
            })
        });
        let res = await data.json();
        return res;

    } catch (error) {
        debug("failed to fetch memberships")
        debug(error);
        return null;
    }

}

export const getSubscriptions = async (subscriptions) => {

    try {
        if (subscriptions === undefined) {

            let data = await fetch(`${url()}/api/subscriptions`, {
                method: 'GET',
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            });
            let res = await data.json();
            subscriptions = res
        }
        const today = DateTime.now().toUTC()
        let subArray = [];

        subscriptions.forEach(sub => {
            if (sub.role === "Coach" || sub.role === "CoachElite" || sub.role === "Individual" || sub.role === "Team") {
                const expiry = DateTime.fromISO(sub.expiry)
                const start = DateTime.fromISO(sub.start)
                debug(sub)
                sub.status = today < start || today >= expiry ? "Inactive" : "Active"
                subArray.push(sub)
            }
        });
        return subArray;
    } catch (error) {
        debug(error)
    }
}