import styled from "styled-components";

export const InfoMessage = styled.i`
color: ${props => props.error ? "#ff0000" : "#00ca05"};
color: ${props => props.colour};
font-weight: "bold";
`;

export const NeutralMessage = styled.i`
color: ${props => props.colour};
font-weight: "bold";
`;

export const HighlightTag = styled.i`
text-decoration: ${props => props.underline ? "underline" : ""};
 font-weight: ${props => props.bold ? "bold" : "normal"};
font-style: ${props => props.italic ? "italic" : "normal"};
font-size: ${props =>
        props.size === "h1" ? "2em"
            : props.size === "h2" ? "1.5em"
                : props.size === "h3" ? "1.17em"
                    : props.size === "h4" ? "1em"
                        : props.size === "h5" ? "0.83em"
                            : props.size === "h6" ? "0.67em"
                                : props.size}; 
//defining size as a header will give appropriate size, 
//else provide your own custom size to the prop
cursor: ${props => props.link ? "pointer" : ""};
color: ${props => props.color};

`;