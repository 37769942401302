import React, { useEffect, useRef } from 'react';
import {debug} from "../../../config";
import * as d3 from 'd3';

import './PitchResultChart.css'

const PitchResultChart = ({ exercise, attempt, results }) => {
    const svgRef = useRef();

    debug(results)
    // debug(results.constants.player.y)
    // debug(results.constants.player.x)

    const segWidth = 2.0;
    const segOffset = 3.0;


    useEffect(() => {

        const offset = results.constants.offset;
        const offx = offset.x;
        const offy = offset.y;

        function degToRad(degrees) {
            return degrees * (Math.PI / 180);
        }

        const etl_pawns = (results) => {
            let friend;
            let foe;
            let passer;
            let nets;

            const getPassers = (passerArray, passerIndicies) => {
                //if showing summary of exercise, we use the passerIndicies array to select the passers from the passerArray
                let newArray = [];
                passerIndicies.forEach(element => {
                    newArray.push(passerArray[element])
                });
                return newArray;
            }

            if (attempt === -1) {
                friend = results.nominals.scenarios[exercise].friends;
                foe = results.nominals.scenarios[exercise].foes;
                passer = getPassers(results.constants.passers, results.exercises[exercise].passerIndices);
                nets = results.nominals.scenarios[exercise].nets;
            } else {
                friend = results.exercises[exercise].plays[attempt].players.friends;
                foe = results.exercises[exercise].plays[attempt].players.foes;
                passer = results.exercises[exercise].plays[attempt].passers
                nets = results.nominals.scenarios[exercise].nets;
            }

            let array = [];
            array.push(...friend.map(function (d) {
                return { "x": d.x - offx, "y": d.y - offy, "friend": true }
            }))
            array.push(...foe.map(function (d) {
                return { "x": d.x - offx, "y": d.y - offy, "foe": true }
            }))
            array.push(...passer.map(function (d) {
                return { "x": d.x - offx, "y": d.y - offy, "friend": true }
            }))
            array.push(...nets.map(function (d) {
                return { "x": d.x - offx, "y": d.y - offy, "friend": true }
            }))
            array.push({ 'x': results.constants.player.x - offx, 'y': results.constants.player.y - offy, 'player': true });
            return array;
        }

        function etl_segments(results) {
            let segs;

            if (attempt === -1) {
                segs = results.exercises[exercise].scanDirections;
            } else {
                segs = results.exercises[exercise].plays[attempt].scanDirections;
            }

            const maxseglength = d3.max(segs);
            const maxLineLength = 52.5 - (results.constants.player.x - offx) - segOffset;
            let scaler = d3.scaleLinear().domain([0.0, maxseglength]).range([segOffset, maxLineLength]);

            return segs.map(function (d, i) {
                return {
                    x1: segOffset * Math.cos(degToRad(segWidth)),
                    y1: segOffset * Math.sin(degToRad(segWidth)),
                    x2: scaler(d) * Math.cos(degToRad(segWidth)),
                    y2: scaler(d) * Math.sin(degToRad(segWidth)),
                    angle: (i * 6.0) + 3.0,
                    length: d
                }
            }).filter(d => d.length > 0.0);
        }

        function etl_target_angles(results) {
            let segs;

            if (attempt === -1) {
                segs = results.exercises[exercise].targetAngles;
            } else {
                segs = results.exercises[exercise].plays[attempt].targetAngles;
            }

            const maxLineLength = 52.5 - (results.constants.player.x - offx) - segOffset;

            return segs.map(function (d) {
                return {
                    x1: segOffset * Math.cos(degToRad(segWidth)),
                    y1: segOffset * Math.sin(degToRad(segWidth)),
                    x2: maxLineLength * Math.cos(degToRad(segWidth)),
                    y2: maxLineLength * Math.sin(degToRad(segWidth)),
                    angle: d
                }
            });
        }

        const svg = d3.select(svgRef.current);

        svg
            .selectAll(`circle`).remove();
        svg
            .selectAll('polygon').remove();

        svg
            .select('g>g#points')
            .selectAll()
            .data(etl_pawns(results))
            .enter()
            .append('circle')
            .classed('foe', d => d.foe)
            .classed('friend', d => d.friend)
            .classed('player', d => d.player)
            .attr('r', 1)
            .attr('cx', d => d.x)
            .attr('cy', d => -d.y);

        svg
            .select('g>g#segments')
            .selectAll()
            .data(etl_segments(results))
            .enter()
            .append('polygon')
            .classed('segment', true)
            // .attr('transform', d => `translate(${results.constants.player.x - offx} ${results.constants.player.y - offy}) rotate(${d.angle}) `)
            .attr('transform', d => `translate(${results.constants.player.x - offx} ${ - (results.constants.player.y - offy)}) rotate(${d.angle}) `)
            .attr('points', d => `
            ${d.x1},${d.y1}
            ${d.x2},${d.y2}
            ${d.x2},${-d.y2}
            ${d.x1},${-d.y1}`);

        svg
            .select('g>g#targetAngles')
            .selectAll()
            .data(etl_target_angles(results))
            .enter()
            .append('polygon')
            .classed('targetAngle', true)
            .attr('transform', d => `translate(${results.constants.player.x - offx} ${ - (results.constants.player.y - offy)}) rotate(${d.angle}) `)
            .attr('points', d => `
            ${d.x1},${d.y1}
            ${d.x2},${d.y2}
            ${d.x2},${-d.y2}
            ${d.x1},${-d.y1}`);

    }, [exercise, attempt, results]);

    const translation = "translate(52.5, 35)";
    return (
        <div className="graphic">
            <React.Fragment>
                <svg ref={svgRef} viewBox="41 0 64 70" id="results">
                    <g transform={translation}>
                        <g id="segments" />
                        <g id="targetAngles" />
                        <g id="points" />
                    </g>
                </svg>
            </React.Fragment>
        </div>
    )
}

export default PitchResultChart;
