import {Button, Input, Label, Modal} from "semantic-ui-react";
import {debug, url} from "../../../../config";
import {useEffect, useState} from "react";
import {DateTime} from 'luxon';
import DatePicker from "react-datepicker";
import './SubscriptionSelection.css'
//import i18next from "i18next";
import {addMessage} from "../../../../lib/functions/notifications";
import cookies from "js-cookie";


export const SubscriptionSelection = ({dialog, setDialog}) => {

    const [startDate, setStartDate] = useState(new Date())
    const [expiryDate, setExpiryDate] = useState(new Date())
    const [modify, setModify] = useState(false)

    useEffect(() => { //set date as needed
        if (dialog.sub !== undefined) {
            setStartDate(DateTime.fromISO(dialog.sub.start).toJSDate())
            setExpiryDate(DateTime.fromISO(dialog.sub.expiry).toJSDate())
        }
    }, [dialog.sub])

    const submitHandler = async (e) => {
        e.preventDefault()
        const start = DateTime.fromISO(dialog.sub.start).toJSDate()
        const expiry = DateTime.fromISO(dialog.sub.expiry).toJSDate()

        if (startDate.toString() === start.toString() && expiryDate.toString() === expiry.toString()) {
            addMessage("Start and expiry dates have not changed.", "info")
            return
        }

        try {

            let data = await fetch(`${url()}/api/admin/subscription/modify/${dialog.sub.id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    from: DateTime.fromJSDate(startDate).toISODate(),
                    to: DateTime.fromJSDate(expiryDate).toISODate(),
                    userId: dialog.userId
                }),
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('session')}`
                })
            })

            if (data.status === 200) {
                addMessage(`Successfully updated ${dialog.user}'s ${dialog.sub.role} subscription.`, "success")
                let newDialog = dialog
                newDialog.sub.start = DateTime.fromJSDate(startDate).toISODate()
                newDialog.sub.expiry = DateTime.fromJSDate(expiryDate).toISODate()
                setDialog(newDialog)
                // setUpdateSub(true)
            } else {
                addMessage(`Unable to update ${dialog.user}'s ${dialog.sub.role} subscription.`, "warning")
            }
        } catch (e) {
            debug(e)
            addMessage(`Unable to update subscription: ${e}`, "warning")
        } finally {
            setModify(false)
        }
    }

    return (
        <div>
            <div>
                <Modal open={!modify} size="small">
                    <Modal.Header>Subscription</Modal.Header>
                    <Modal.Content>
                        <div>
                            <div className="subscriptionModalRow">
                                <Label>User:</Label>
                                <Input>{dialog.user}</Input>
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Type:</Label>
                                <Input>{dialog.sub.role}</Input>
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Start:</Label>
                                <Input>{DateTime.fromISO(dialog.sub.start).toLocaleString(DateTime.DATE_SHORT)}</Input>
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Expiry:</Label>
                                <Input>{DateTime.fromJSDate(expiryDate).toLocaleString(DateTime.DATE_SHORT)}</Input>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setModify(true)}>
                            Modify
                        </Button>
                        <Button onClick={() => setDialog({
                            add: false,
                            show: false,
                            user: undefined,
                            sub: undefined
                        })}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={modify} size="small">
                    <Modal.Header>Modify Subscription</Modal.Header>
                    <Modal.Content>
                        <div>
                            <div className="subscriptionModalRow">
                                <Label>User:</Label>
                                <Input>{dialog.user}</Input>
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Type:</Label>
                                <Input>{dialog.sub.role}</Input>
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Start:</Label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    dropdownMode="select"
                                />
                            </div>
                            <div className="subscriptionModalRow">
                                <Label>Expiry:</Label>
                                <DatePicker
                                    selected={expiryDate}
                                    onChange={(date) => setExpiryDate(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={(e) => submitHandler(e)}>
                            Confirm
                        </Button>
                        <Button onClick={() => setModify(false)}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    )
    //}

}