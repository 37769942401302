import { Store } from 'react-notifications-component';
import '../../../node_modules/animate.css/animate.css';

/**
 Create a notification.
 * @param msg (str) - The message to be displayed.
 * @param msgType (str) - Choose message type between default, success, info or warning. Uses default if omitted.
 * @param time (str) - optional. Set time for notification to remain in seconds. Uses 3 of omitted. Max 10 seconds.
 **/
export const addMessage = (msg, msgType, time) => {

    let header = ""

    if (msg === undefined) {
        msg = "info"
        //text in message
    }
    const types = ["default", "success", "info", "warning"]
    if (types.includes(msgType) === false) {
        msgType = "default"
    } else {
        header = msgType
    }
    if (msgType === undefined || typeof(msgType) !== "string") {
        msgType = "default"
        //message types: 'default', 'success', 'info', 'warning'
    }
    if (time === undefined || typeof(time) !== "number") {
        time = 3000
        //time sets duration for notification to remain on screen
    } else {
        //multiply by 1000 to get number of milliseconds from seconds, but only if this doesn't exceed 10 seconds
        time * 1000 > 10000 ? time = 3000 : time = time * 1000
    }

    Store.addNotification({
        title: header.toUpperCase(),
        message: msg,
        type: msgType,
        width: document.documentElement.clientWidth - 5 || 0,
        container: "top-center",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        //uses animate.css to change animations in animationIn and animationOut - see "https://animate.style/"
        dismiss: {
            duration: time
        }
    })
}