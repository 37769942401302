import {useState} from 'react';
import {Header, Form, Input, Label, Button, Loader, Dimmer} from 'semantic-ui-react'
import {useHistory} from "react-router-dom";
import i18next from 'i18next';
import {basicEmailCheck} from '../../../lib/functions/LoginHelperFunctions'
import {url, debug} from '../../../config';
import {addMessage} from '../../../lib/functions/notifications';
import './SignUp.css'

const SignUp = () => {

    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [loading, setLoading] = useState(false);
    const [eula, displayEula] = useState(false)

    let history = useHistory();

    const cancelAccountCreation = () => {
        displayEula(false)
        // setEmail("")
        // setPass("")
        // setFirstName("")
        // setSurName("")
        addMessage("Account creation was cancelled, you must accept the EULA to continue.", "warning")

    }

    const submitHandler = async (e) => {
        e.preventDefault()

        const fname = firstName.trim()
        const sname = surName.trim()

        const arrayOfFields = [
            {type: fname, value: "first name"},
            {type: sname, value: "surname"},
            {type: email, value: "email"},
            {type: pass, value: "password"},
        ]

        for (let i = 0; i < arrayOfFields.length; i++) {
            if (arrayOfFields[i].type.trim() === "") {
                addMessage(`Please fill in the ${arrayOfFields[i].value} field.`, "warning")
                return;
            }
        }

        if (!basicEmailCheck(email)) {
            addMessage(i18next.t("general.email_password_reset.invalid_email"), "warning")
            setLoading(false);
            return;
        }
        displayEula(true)
    }

    const createNewAccount = async () => {
        displayEula(false)
        setLoading(true);
        try {
            const fname = firstName.trim()
            const sname = surName.trim()

            let name = fname + " " + sname;

            let data = await fetch(`${url()}/auth/user`, {
                method: 'POST',
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: pass
                }),
                headers: {
                    'content-type': 'application/json'
                }
            })
            debug(data);
            if (data.status === 201) {
                addMessage(`${i18next.t("general.signup.success", {name: name})}`, "success")
                setTimeout(() => {
                    debug("redirecting")
                    history.push("/login")
                }, 2500)
            }
            if (data.status === 403) {
                addMessage(i18next.t("general.signup.duplicate"), "warning")
            }
            if (data.status === 400) {
                addMessage(i18next.t("general.signup.failure"), "warning")
            }
            setLoading(false)
        } catch (error) {
            addMessage(i18next.t("general.signup.error"), "warning")
            debug(error);
            setLoading(false);
        }
    }

    return (
        <div>
            <br/>
            <Dimmer page active={eula}>
                <p id="signupText">Please agree to the terms below to continue.</p>
                <div>
                    <iframe
                        id="iframe"
                        title="EULA"
                        src="https://futureperformance.tech/eula"
                        onLoad={()=> {

                        }}
                    />
                </div>
                <div id="signupButton">
                    <Button onClick={() => createNewAccount()}>Accept</Button>
                    <Button onClick={() => cancelAccountCreation()}>Decline</Button>
                </div>
            </Dimmer>
            <Header as="h1" className="teal">{i18next.t("general.signup.header")}</Header>
            <Form className="loginForm" onSubmit={(e) => submitHandler(e)}>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.signup.label_fname")}</Label>
                    <Input placeholder={i18next.t("general.signup.fname_desc")}
                           onChange={(e) => setFirstName(e.target.value)}/>
                </Form.Field>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.signup.label_sname")}</Label>
                    <Input placeholder={i18next.t("general.signup.sname_desc")}
                           onChange={(e) => setSurName(e.target.value)}/>
                </Form.Field>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.signup.label_email")}</Label>
                    <Input placeholder={i18next.t("general.signup.email_desc")}
                           onChange={(e) => setEmail(e.target.value.toLowerCase())}
                            value={email}
                    />
                </Form.Field>
                <Form.Field inline={true} className="field">
                    <Label className="teal label">{i18next.t("general.signup.label_password")}</Label>
                    <Input type="password" placeholder={i18next.t("general.signup.password_desc")}
                           onChange={(e) => setPass(e.target.value)}/>
                </Form.Field>
                <Button size="small" className="loginButton" type="submit">{i18next.t("layout.signup")}</Button>
                <Button size="small" className="loginButton" type="button"
                        onClick={() => history.push("/login")}>{i18next.t("general.back_button")}</Button>
            </Form>
            <br/>
            <Loader inline inverted active={loading}>{i18next.t("general.loading")}</Loader>
        </div>
    )

}

export default SignUp;