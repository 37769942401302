import {Button, Modal} from "semantic-ui-react";
import {url} from "../../config";
import cookies from "js-cookie";
import {addMessage} from "../../lib/functions/notifications";

export const GameSequenceModal = ({setModal, sequence}) => {

    const deleteSequence = async (sequence_id, sequence_name) => {
        try {
            const res = await fetch(`${url()}/coach/game/sequence/${sequence_id}`, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (res.status === 200) {
                addMessage(`Successfully deleted sequence ${sequence_name}`, 'success')
            } else {
                addMessage(`Error: ${res.message}`)
            }
        } catch (e) {
            addMessage(`unable to delete sequence: ${e.message}`, 'warning')
        } finally {
            setModal({open: false, sequence: 0})
        }
    }

    return (
        <Modal
            size="tiny"
            open={true}
        >
            <Modal.Header>{`Confirmation of deleting ${sequence.name}`}</Modal.Header>
            <Modal.Content>
                <p>{`Do you wish to delete ${sequence.name}? This cannot be undone.`}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={()=> deleteSequence(sequence.id, sequence.name)}>Delete</Button>
                <Button onClick={()=> setModal({open: false, sequence: 0})}>Back</Button>
            </Modal.Actions>
        </Modal>
    )
}