import React from 'react';
import { useEffect, useState } from 'react';
import { Table, Loader, Segment } from "semantic-ui-react";
import { DateTime } from 'luxon';
import '../MySubs/MySubs.css'
import i18next from 'i18next';
import { getMemberships, getSubscriptions } from '../../../lib/functions/SubsHelperFunctions';
import {debug} from '../../../config';
// import Cookies from 'js-cookie';


const MySubs = ({ user }) => {

  const [memberships, setMemberships] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subLoaded, setSubLoaded] = useState(false);
  const [teamLoaded, setTeamLoaded] = useState(false);

  const browserLocale = getBrowserLocales()[0];

  function getBrowserLocales(options = {}) {
    const defaultOptions = {
      languageCodeOnly: false,
    };

    const opt = {
      ...defaultOptions,
      ...options,
    };

    const browserLocales =
      navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
      return undefined;
    }

    return browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      return opt.languageCodeOnly
        ? trimmedLocale.split(/[-_]/)[0]
        : trimmedLocale;
    });
  }

  function formatDate(datetime) {
    return DateTime.fromISO(datetime).setLocale(browserLocale).toLocaleString(DateTime.DATE_MED);
  }

  useEffect(() => {

    const getSubData = async () => {

      let subs = await getSubscriptions(user.subscriptions);
      let mems = await getMemberships();
      debug(subs);
      debug(mems);

      if (subs !== null) {
        setSubscriptions(subs);
        setSubLoaded(true);
      } else {
        debug("failed to get subscriptions");
        setSubLoaded(true)
      }
      if (mems !== null) {
        setMemberships(mems);
        setTeamLoaded(true);
      } else {
        debug("failed to get team memberships");
        setTeamLoaded(true);
      }
    }
    getSubData()
  }, [user.subscriptions])

  return (
    <div>
      <div>
        <Segment basic textAlign="left">
          <h2>{i18next.t("general.subscriptions.subs.title")}</h2>
          {subscriptions.length === 0 &&
            <>
              {subLoaded ?
                <p>{i18next.t("general.subscriptions.subs.no_data")}</p>
                :
                <Loader id="subLoader" active>{i18next.t("general.profile.loading_subs")}</Loader>}
            </>
          }
          {subscriptions.length > 0 &&
            <Table celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.subs.subs")}</Table.HeaderCell>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.subs.status")}</Table.HeaderCell>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.subs.valid_from")}</Table.HeaderCell>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.subs.valid_to")}</Table.HeaderCell>
                  {/*<Table.HeaderCell></Table.HeaderCell>*/}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {subscriptions.map((subscription, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{subscription.role}</Table.Cell>
                    <Table.Cell>{subscription.status}</Table.Cell>
                    <Table.Cell>{formatDate(subscription.start)}</Table.Cell>
                    <Table.Cell>{formatDate(subscription.expiry)}</Table.Cell>
                    {/*<Table.Cell><Button onClick={confirmTermination}>{i18next.t("general.subscriptions.subs.button")}</Button></Table.Cell>*/}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          }

          <h2>{i18next.t("general.subscriptions.team.title")}</h2>
          {memberships.length === 0 &&
            <>
              {teamLoaded ?
                <p>{i18next.t("general.subscriptions.subs.no_data")}</p>
                :
                <Loader id="teamLoader" active>{i18next.t("general.profile.loading_teams")}</Loader>}
            </>
          }
          {memberships.length > 0 &&
            <Table celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.team.team")}</Table.HeaderCell>
                  <Table.HeaderCell>{i18next.t("general.subscriptions.team.role")}</Table.HeaderCell>
                  {/*<Table.HeaderCell></Table.HeaderCell>*/}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {memberships.map((membership, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{membership.teamName}</Table.Cell>
                    <Table.Cell>{membership.role}</Table.Cell>
                  {/*  <Table.Cell><Button onClick={membership.role === "Owner" ? ()=>confirmRemoveTeam(membership.teamId) : ()=>confirmLeaveTeam(membership.teamId)}*/}
                  {/*  >{ membership.role === "Owner" ? "Remove Team" : i18next.t("general.subscriptions.team.button")*/}
                  {/*}</Button></Table.Cell>*/}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          }
        </Segment>
        <Segment basic>
          <p>{i18next.t("sub_contactInfo")}
            <a href="https://futureperformance.tech/contact" target="_blank" rel="noreferrer">
              {i18next.t("sub_contactInfo2")}</a>
          </p>
        </Segment>
      </div>
    </div>
  )
}

export default MySubs;
