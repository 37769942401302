import {
    Header,
    Dropdown,
    Loader
} from 'semantic-ui-react';
import {debug} from "../../../../config";
import i18next from 'i18next';

const PlayerSelect = ({ teamPlayers, setTeamPlayers, players, selectedTeam }) => {

    const onChange = (e, data) => {
        debug(e)
        let arr = [...teamPlayers]
        data = data.value;

        arr.forEach(element => {
            element.active = false;
        })

        if (data.length === 0) {
            setTeamPlayers(arr);
            debug(data)
            return;
        }
        data.forEach(id => {
            for (let i = 0; i < arr.length; i++) {
                if (id === arr[i].playerId) {
                    arr[i].active = true;
                }
            }
            setTeamPlayers(arr);
        });
    }

    return (
        <div>
            {players && <Header as="h2">{i18next.t("soccer_team.results.playerSelect_title")}</Header>}

            {players ? <Dropdown
                fluid
                multiple
                selection
                options={players}
                onChange={onChange}
                placeholder={i18next.t("soccer_team.results.select_placeholder")}
            />
                :
                <Loader active={selectedTeam !== i18next.t("soccer_team.results.select_team")}>{i18next.t("general.loading")}</Loader>
            }
        </div>
    )
}
export default PlayerSelect;