import {Button, Form, Header, Modal, TextArea} from "semantic-ui-react";
import {useState} from "react";
import {url} from "../../config";
import cookies from "js-cookie";
import {addMessage} from "../../lib/functions/notifications";

export const DescriptionModal = ({active, setActive, setLoading, setOpenModal}) => {

    const [gameDescription, setGameDescription] = useState("")

    const updateDescription = async () => {
        try {
            setLoading(true)
            let res = await fetch(`${url()}/coach/upload/description/${active.id}`, {
                method: 'POST',
                body: JSON.stringify({description: gameDescription}),
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (res.status === 200) {
                addMessage("Description successfully updated", "success")
                setOpenModal({description: false})
                let desc = active
                desc.description = gameDescription
                setActive(desc)
            } else {
                addMessage(`Error: ${res.message}`)
            }
        } catch (e) {
            addMessage(`Error: ${e}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
        size="tiny"
        open
        >
        <Modal.Header>
            <Header>Modify {active.category}</Header>
            <p>Description</p>
        </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input
                        control={TextArea}
                        placeholder={active.description}
                        onChange={(e) => setGameDescription(e.target.value)}
                        value={gameDescription}
                    />
                    <Form.Button onClick={() => updateDescription()}>Submit</Form.Button>
                </Form>
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={()=>setOpenModal({description: false})}>Cancel</Button>
            </Modal.Actions>
        </Modal>
    )
}