import { useState, useEffect } from 'react';
import { Table, Button, Header, Loader, Segment, Modal } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import '../ComparisonTable/ComparisonTable.css'
import ComparisonDropDown from '../ComparisonDropDown/ComparisonDropDown';
import PitchResultChart from '../../../session_results/PitchResultChart/PitchResultChart';
import { getCSVData } from '../../../../lib/functions/CSVFunctions';
import i18next from 'i18next';
import { addMessage } from '../../../../lib/functions/notifications';
import {debug} from "../../../../config";

const ComparisonTable = ({ sessionsList, setSessionsList, getUser, loading }) => {

    const [exercise, setExercise] = useState(-1);
    const [attempt, setAttempt] = useState(-1);
    const [index, setIndex] = useState(0);
    const [graphOpen, setgraphOpen] = useState(false);

    useEffect(() => {
        if (exercise === -1) {
            setAttempt(-1);
        }
    }, [exercise])

    const removeHandler = (index) => {
        try {
            debug(index)
            let arr = [...sessionsList];
            arr.splice(index, 1);
            setSessionsList(arr);
            addMessage(i18next.t("soccer_comparison.select_remove"), "success")
        } catch (error) {
            debug(error)
            addMessage(i18next.t("soccer_comparison.generic_error_message"), "warning")
        }
    }

    const disableGraphHandler = (data) => {
        if (data.exercises[exercise].plays.length < 5) {
            const numOfPlays = data.exercises[exercise].plays.length;
            if (attempt >= numOfPlays) {
                return true;
            }
        }
        return false;
    }

    const showGraphicHandler = (data, index) => {
        try {
            setIndex(index);
            setgraphOpen(true)
        } catch (error) {
            debug(data);
            addMessage(i18next.t("soccer_comparison.graph_error"), "warning")
        }
    }

    const exerciseHandler = () => {
        debug(sessionsList);
        if (sessionsList[0].exercises.length < 9) {
            for (let i = 1; i < 9; i++) {
                if (sessionsList[i].exercises.length === 9) {
                    return sessionsList[i].exercises[i].whatToDo;
                }
            }
            //if does not return from for loop, there is no valid exercise data that can be given
            return i18next.t("soccer_comparison.error_data")
        } else {
            return sessionsList[0].exercises[exercise].whatToDo;
        }

    }

    const errorHandler = (data, param) => {
        // debug("running errorHandler")
        debug(data);
        let playerAttempt
        if (exercise !== -1) {
            if (data.exercises.length < exercise) {
                return i18next.t("soccer_comparison.no_data_found");
            }
            playerAttempt = data.exercises[exercise].plays[attempt]
        } else {
            // debug("error! Not defined - if data still renders, the state has updated whilst this is running")
            playerAttempt = undefined;
        }
        if (playerAttempt === undefined) {

            return i18next.t("soccer_comparison.no_data_found");
        } else {
            if (param === "success") {
                data.exercises[exercise].plays[attempt].graph = true;
                return data.exercises[exercise].plays[attempt].success;

            } if (param === "scanningPercentage") {
                data.exercises[exercise].plays[attempt].graph = true;
                return `${data.exercises[exercise].plays[attempt].scanningPercentage.toPrecision(2)}%`;

            } if (param === "scanningFrequency") {
                data.exercises[exercise].plays[attempt].graph = true;
                return `${data.exercises[exercise].plays[attempt].scanningFrequency.toPrecision(1)}`;

            } if (param === "decisionTime") {
                data.exercises[exercise].plays[attempt].graph = true;
                return `${data.exercises[exercise].plays[attempt].decisionTime.toPrecision(1)}s`;
            }
            return data;
        }
    }

    if (sessionsList.length < 1) { //workaround to allow loading screen for each stage of page rendering table
        return (
            <Loader size="massive" className="centered" active={loading} inline>{i18next.t("general.loading")}</Loader>
        )
    }

    else if (loading) {
        return (
            <Loader size="massive" className="centered" active inline>{i18next.t("general.results.loading")}</Loader>
        )
    } else {
        return (
            <div>
                {sessionsList &&
                    <Table celled unstackable selectable textAlign="center">
                        <Table.Header >

                            <Table.Row className="teamTable">
                                <ComparisonDropDown attempt={attempt}
                                    exercise={exercise}
                                    setAttempt={data => setAttempt(data)}
                                    setExercise={data => setExercise(data)}
                                />

                                {sessionsList.map((player, index) =>
                                    <Table.HeaderCell key={index}>{getUser(player.userId)}</Table.HeaderCell>
                                )}
                            </Table.Row>

                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                {exercise !== -1 &&
                                    <Table.Cell colSpan={sessionsList.length + 1} textAlign="center">{exerciseHandler()}</Table.Cell>
                                }
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>{i18next.t("soccer_comparison.table_date")}</Table.Cell>
                                {sessionsList.map((date, index) =>
                                    <Table.Cell key={index}>{DateTime.fromISO(date.datePosted).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Table.Cell>
                                )}
                            </Table.Row>

                            {attempt === -1 ? <Table.Row>
                                <Table.Cell>{i18next.t("soccer_comparison.table_score")}</Table.Cell>
                                {sessionsList.map((data, index) =>
                                    <Table.Cell key={index}>{exercise === -1 ? `${data.score}/${data.outOf}` : `${data.exercises[exercise].score}/${data.exercises[exercise].outOf}`}</Table.Cell>
                                )}
                            </Table.Row>
                                :
                                <Table.Row>
                                    <Table.Cell>{i18next.t("soccer_comparison.table_success")}</Table.Cell>
                                    {sessionsList.map((data, index) =>
                                        <Table.Cell key={index}>{errorHandler(data, "success") === true ? i18next.t("general.results.positive") : i18next.t("general.results.negative")}</Table.Cell>
                                    )}
                                </Table.Row>}

                            {attempt === -1 ? <Table.Row>
                                <Table.Cell>{i18next.t("soccer_comparison.table_scanning")}</Table.Cell>
                                {sessionsList.map((data, index) =>
                                    <Table.Cell key={index}>{exercise === -1 ? `${data.averageScanningPercentage.toPrecision(2)}%` : `${data.exercises[exercise].averageScanningPercentage.toPrecision(2)}%`}</Table.Cell>
                                )}
                            </Table.Row>
                                :
                                <Table.Row>
                                    <Table.Cell>{i18next.t("soccer_comparison.table_scanning")}</Table.Cell>
                                    {sessionsList.map((data, index) =>
                                        <Table.Cell key={index}>{errorHandler(data, "scanningPercentage")}</Table.Cell>
                                    )}
                                </Table.Row>}

                            {attempt === -1 ? <Table.Row>
                                <Table.Cell>{i18next.t("soccer_comparison.table_average_scan")}</Table.Cell>
                                {sessionsList.map((data, index) =>
                                    <Table.Cell key={index}>{exercise === -1 ? `${data.averageScanningFrequency.toPrecision(1)}` : `${data.exercises[exercise].averageScanningFrequency.toPrecision(1)}`}</Table.Cell>
                                )}
                            </Table.Row>
                                :
                                <Table.Row>
                                    <Table.Cell>{i18next.t("soccer_comparison.table_scan")}</Table.Cell>
                                    {sessionsList.map((data, index) =>
                                        <Table.Cell key={index}>{errorHandler(data, "scanningFrequency")}</Table.Cell>
                                    )}
                                </Table.Row>}

                            {attempt === -1 ? <Table.Row>
                                <Table.Cell>{i18next.t("soccer_comparison.table_average_decision")}</Table.Cell>
                                {sessionsList.map((data, index) =>
                                    <Table.Cell key={index}>{exercise === -1 ? `${data.averageDecisionTime.toPrecision(1)}s` : `${data.exercises[exercise].averageDecisionTime.toPrecision(1)}s`}</Table.Cell>
                                )}
                            </Table.Row>
                                :
                                <Table.Row>
                                    <Table.Cell>{i18next.t("soccer_comparison.table_action_time")}</Table.Cell>
                                    {sessionsList.map((data, index) =>
                                        <Table.Cell key={index}>{errorHandler(data, "decisionTime")}</Table.Cell>
                                    )}
                                </Table.Row>}

                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Button onClick={() => getCSVData(sessionsList)}>{i18next.t("soccer_comparison.button_CSV")}</Button>
                                </Table.HeaderCell>
                                {sessionsList.map((data, index) =>
                                    <Table.HeaderCell key={index}>
                                        {exercise !== -1 && <Button disabled={disableGraphHandler(data)} onClick={() => showGraphicHandler(data, index)}>{i18next.t("soccer_comparison.button_graph")}</Button>}
                                        <Button onClick={() => removeHandler(index)}>{i18next.t("soccer_comparison.button_removeItem")}</Button>
                                    </Table.HeaderCell>
                                )}

                            </Table.Row>
                        </Table.Footer>
                    </Table>}
                <Modal
                    open={graphOpen}
                    onOpen={() => setgraphOpen(true)}
                    onClose={() => setgraphOpen(false)}
                    size="tiny"
                >
                    <Modal.Header>{`${getUser(sessionsList[index].userId)}, 
                    ${DateTime.fromISO(sessionsList[index].datePosted).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}`}</Modal.Header>
                    {sessionsList &&
                        <>
                            <Modal.Content>
                                <div id="modalDiv">
                                    <Segment compact>
                                        <Modal.Description>
                                            <Header textAlign="center" as="h2">{i18next.t("soccer_comparison.header.graph")}</Header>
                                        </Modal.Description>
                                        <br></br>
                                        <Header textAlign="center" floated="left" as="h3" >{`${i18next.t("soccer_comparison.graph.exercise")}${exercise + 1}`}</Header>
                                        <Header textAlign="center" floated="right" as="h3" >{`${i18next.t("soccer_comparison.graph.attempt")}${attempt === -1 ? "summary" : attempt + 1}`}</Header>
                                    </Segment>
                                    <PitchResultChart results={sessionsList[index]} exercise={exercise} attempt={attempt} />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => setgraphOpen(false)}>{i18next.t("soccer_comparison.button_closeGraph")}</Button>
                            </Modal.Actions>
                        </>
                    }
                </Modal>
            </div>
        )
    }
}
export default ComparisonTable;
