import i18next from "i18next";
import { useState } from "react";
import { Button, Dimmer, Form, Header, Input, Label} from "semantic-ui-react";
import { addMessage } from "../../../../lib/functions/notifications";
import {debug, url} from "../../../../config";
import './NewTeam.css'
import cookies from "js-cookie";

const NewTeam = ({ dimmerActive, setDimmerActive, managedTeams, setManagedTeams}) => {

    const [newTeamName, setTeamName] = useState("")

    const submitHandler = async (e) => {
        e.preventDefault()
        if (newTeamName.trim().length === 0) {
            addMessage('Please enter a name', "warning")
            setDimmerActive(false);
            return
        }
        try {
            let data = await fetch(`${url()}/api/soccer/teams`, {
                method: "POST",
                body: JSON.stringify({
                    teamName: newTeamName
                }),
                headers: new Headers({
                    "content-type": "application/json",
                    'authorization': `Bearer ${cookies.get("session")}`
                })
            })
            if (data.status === 200) {
                let res = await data.json()
                addMessage(`Successfully created team ${newTeamName}`, "success")
                let newTeamList = managedTeams
                newTeamList.push({
                    owner: true, role: 'Owner', teamId: res.id, teamName: res.name
                })
                setManagedTeams(newTeamList)
            } else {
                addMessage(`There was an issue creating team ${newTeamName} code: ${data.status}`, "warning")
            }
        } catch (error) {
            addMessage(`Unable to create team: ${error}`, "warning")
            debug(error);
        } finally {
            setDimmerActive(false)
        }
    }

    return (
        <Dimmer active={dimmerActive} onClickOutside={() => setDimmerActive(false)} page>
            <div>
                <Form onSubmit={(e)=>submitHandler(e)}>
                    <Header as="h2" inverted>{i18next.t("soccer_team.create_team_dimmer_header")}</Header>
                    <p>{i18next.t("soccer_team.create_team_desc")}</p>
                    <Form.Field inline={true} className="field">
                        <Label className="teal label">{i18next.t("soccer_team.create_team.label")}</Label>
                        <Input placeholder={i18next.t("soccer_team.create_team.placeholder")} onChange={(e) => setTeamName(e.target.value)} value={newTeamName} />
                    </Form.Field>
                    <Button type="submit">{i18next.t("soccer_team.create_team.submit")}</Button>
                    <Button type="button" onClick={() => setDimmerActive(false)}>{i18next.t("soccer_team.create_team.cancel")}</Button>
                </Form>
                {/*{loading &&*/}
                {/*    <Loader active={true}>Loading</Loader>}*/}
            </div >
        </Dimmer>
    )

}

export default NewTeam;