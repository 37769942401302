import {
    Table,
    Button,
} from 'semantic-ui-react';
import { useState } from 'react';
import { DateTime } from 'luxon';
import ComparisonTable from '../ComparisonTable/ComparisonTable';
import IndividualResult from '../IndividualResult/IndividualResult';
import { getUserData } from '../../../../lib/functions/TeamHelperFunctions';
import i18next from 'i18next';
import { addMessage } from '../../../../lib/functions/notifications';
import {debug} from "../../../../config";

const ResultsList = ({
    allResults,
    pagination,
    setCurrentPage,
    currentPage,
    teamPlayers,
    fromDate,
    toDate,
}) => {

    const [sessionsList, setSessionsList] = useState([]);
    const [individualResult, setIndividualResult] = useState();
    const [toggleButton, setToggleButton] = useState({ compare: false, text: i18next.t("soccer_team.results.button.show_comparison") });
    const [loading, setLoading] = useState(false);

    const getUser = (userId) => {
        //fetches data from teamplayers to determine playername from userid
        for (let i = 0; i < teamPlayers.length; i++) {
            if (teamPlayers[i].playerId === userId) {
                return teamPlayers[i].playerName;
            }
        }
        return i18next.t("results.error.invalid_data");
    }

    const checkPlayerIsSelected = (player, array) => {

        if (array === undefined) {
            // debug(array);
            return false;
            // throw new Error("Array parameter not defined");
        } else if (player === undefined) {
            // debug(player);
            return false;
            // throw new Error("player parameter not defined");
        }
        if (Array.isArray(array) === false) {
            array = [array];
        }
        for (let i = 0; i < array.length; i++) {
            if (array[i].sessionId === player.sessionId) {
                return true;
            }
        }
        return false;
    }

    const isToggleButtonTrue = () => {
        //choose array to compare for deciding if selection is active
        if (toggleButton.compare) {
            return sessionsList;
        } else {
            return individualResult;
        }
    }

    const sessionsListHandler = async (session) => {
        setLoading(true);
        let arr = [];
        debug(session)
        debug(sessionsList)
        if (sessionsList !== undefined) {
            arr = [...sessionsList];
            if (toggleButton.compare === true) {
                //for loop checks for duplicate data based on date of posting
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].sessionId === session.sessionId) {
                        debug("duplicate data comparison table");
                        arr.splice(i, 1);
                        setSessionsList(arr);
                        setLoading(false);
                        addMessage(i18next.t("soccer_comparison.select_remove"), "success");
                        return;
                    }
                }
            } else {
                if (individualResult !== undefined) {
                    if (individualResult.sessionId === session.sessionId) {
                        debug("duplicate data invidiual result")
                        setLoading(false);
                        addMessage(i18next.t("soccer_comparison.select_duplicate"), "warning");
                        return;
                    }
                }
            }
        }
        if (session.exercises.length === 0) {
            let res = await getUserData(session.sessionId, session.userId, DateTime.fromJSDate(fromDate).toISODate(), DateTime.fromJSDate(toDate).toISODate());
            session = res;
        }
        arr.push(session);
        if (toggleButton.compare === true) { //if comparison table  is currently showing
            setSessionsList(arr);
            addMessage(i18next.t("soccer_comparison.add_success"), "success")
        } else { //if individual results are currently showing
            setIndividualResult(session);
            addMessage(i18next.t("soccer_comparison.select_success"), "success")
        }
        setLoading(false);
    };

    const addToCompareHandler = () => {
        let result = individualResult;
        let arr = [];

        if (result === undefined) {
            debug("no data to compare with!")
            addMessage(i18next.t("soccer_comparison.comparison_no_data"), "warning");
            return;
        }

        if (sessionsList !== undefined) {
            arr = [...sessionsList];
            //for loop checks for duplicate data based on date of posting
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].sessionId === result.sessionId) {
                    debug("duplicate data");
                    addMessage(i18next.t("soccer_comparison.comparison_duplicate"), "warning");
                    return;
                }
            }
        };
        arr.push(result);
        setSessionsList(arr);
        addMessage(i18next.t("soccer_comparison.comparison_add"), "success");
    }

    if (allResults.length < 1) {
        return (
            <p>{i18next.t("soccer_team.results.desc")}</p>
        )
    } else {
        return (
            <div>
                {allResults && <Table unstackable celled selectable={!loading}>
                    <Table.Header colSpan='6'>
                        <Table.Row className="teamTable">
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_player")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_date")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_score")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_scanning")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_average_scan")}</Table.HeaderCell>
                            <Table.HeaderCell>{i18next.t("soccer_team.results.table.header_average_decision")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body colSpan='6'>
                        {allResults.map((item, index) =>
                            <Table.Row key={index} className={checkPlayerIsSelected(item, isToggleButtonTrue()) === true ? "active" : "inactive"} onClick={loading ? () => debug("inactive") : () => sessionsListHandler(item)}>
                                <Table.Cell>{getUser(item.userId)}</Table.Cell>
                                <Table.Cell>{DateTime.fromISO(item.datePosted).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</Table.Cell>
                                <Table.Cell>{item.score}/{item.outOf}</Table.Cell>
                                <Table.Cell>{item.averageScanningPercentage.toPrecision(2)}%</Table.Cell>
                                <Table.Cell>{item.averageScanningFrequency.toPrecision(1)}</Table.Cell>
                                <Table.Cell>{item.averageDecisionTime.toPrecision(1)}s</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer>
                        {pagination && <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                {pagination.map(page => {
                                    return <Button key={page} onClick={() => setCurrentPage(page + 1)} className={currentPage === page + 1 ? "active" : "inactive"}>{page + 1}</Button>
                                })}
                                <Button floated="right" onClick={() => setToggleButton(toggleButton.compare === false ?
                                    { compare: true, text: i18next.t("soccer_team.results.button.show_results") }
                                    : { compare: false, text: i18next.t("soccer_team.results.button.show_comparison") })}>
                                    {toggleButton.text}
                                </Button>
                                {toggleButton.compare === false &&
                                    <Button floated="right" onClick={addToCompareHandler}>{i18next.t("soccer_team.results.button.add_to_comparison")}</Button>}
                            </Table.HeaderCell>
                        </Table.Row>}
                    </Table.Footer>
                </Table>}

                {toggleButton.compare === true &&
                    <ComparisonTable sessionsList={sessionsList}
                        setSessionsList={data => setSessionsList(data)}
                        getUser={data => getUser(data)}
                        loading={loading}
                    />
                }
                {toggleButton.compare === false &&
                    <IndividualResult individualResult={individualResult}
                        getUser={data => getUser(data)}
                        loading={loading}
                    />
                }
            </div>
        )
    }
}

export default ResultsList;