import React, {useEffect, useState} from "react";
import {debug, url} from "../../../config";
import cookies from "js-cookie";
import {addMessage} from "../../../lib/functions/notifications";
import {Dropdown, Header, Loader, Menu, Table} from "semantic-ui-react";
import i18next from "i18next";
import {GameSequenceModal} from "../../modals/GameSequenceModal";
import {Tags} from "../../modals/Tags";

export const ViewGameSequences = () => {

    const [userUploads, setUserUploads] = useState([])
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState([])
    const [selectedGame, setSelectedGame] = useState({id: 0, name: "select game..."})
    const [modal, setModal] = useState({open: false, sequence: {}})

    const [tagsOpen, setTagsOpen] = useState(false)
    const [tags, setTags] = useState([])
    const [activeSequence, setActiveSequence] = useState(0)

    debug(active)
    const getUserGames = async () => {
        setLoading(true)
        try {
            const data = await fetch(`${url()}/coach/games`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            const res = await data.json()
            debug(res)
            setUserUploads(res)
        } catch (e) {
            addMessage(`Unable to fetch uploads: ${e.message}`)
        } finally {
            setLoading(false)
        }
    }

    const getUserSequences = async (game_id) => {
        setLoading(true)
        try {
            const data = await fetch(`${url()}/coach/game/sequences/${game_id}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            const res = await data.json()
            // console.log(res)
            setActive(res)
        } catch (e) {
            addMessage(`Unable to fetch uploads: ${e.message}`)
        } finally {
            setLoading(false)
        }
    }

    const handleItemSelect = (data) => {
        if (data.status === 'Pending' || data.status === 'Processing') {
            addMessage(`Unable to select: ${data.name} status is ${data.status}`, 'warning')
            return
        }
        // addMessage(`You selected ${data.name}`)
        setModal({open: true, sequence: data})

    }

    const handleTags = (tagsArray) => {
        let ret = ""
        for (let i = 0; i < tagsArray.length; i++) {

            if (i === 0) {
                ret = tagsArray[i]
            } else {
                ret = ret + tagsArray[i]
            }
            if (i !== tagsArray.length - 1) {
                ret = ret + ", "
            }
        }
        return ret
    }

    useEffect(() => {
        getUserGames()
    }, [])

    debug(tags)

    if (loading) {
        return <Loader id='uploadLoader' active>{i18next.t("general.refresh")}</Loader>
    } else {
        return (
            <div>
                <Header as="h1">{i18next.t("coachElite.viewGameSequences.header")}</Header>
                {userUploads.length > 0 ?
                    <Menu compact>
                        {
                            <Dropdown item text={selectedGame.name}>
                                <Dropdown.Menu>
                                    {userUploads.map((game, index) =>
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => {
                                                setActive(game)
                                                setSelectedGame({id: game.id, name: game.file_name})
                                                getUserSequences(game.id)
                                            }}
                                        >{game.file_name}</Dropdown.Item>
                                    )}
                                </ Dropdown.Menu>
                            </Dropdown>
                        }
                        {active.length > 0 &&
                            <Menu.Item onClick={() => getUserSequences(selectedGame.id)}>
                                {i18next.t("coachElite.viewGameSequences.refresh")}
                            </Menu.Item>}
                    </Menu>
                    :
                    <p>no data found</p>
                }
                {active.length > 0 ?
                    <>
                        <Table celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{i18next.t("coachElite.viewGameSequences.table.name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{i18next.t("coachElite.viewGameSequences.table.description")}</Table.HeaderCell>
                                    <Table.HeaderCell>{i18next.t("coachElite.viewGameSequences.table.processStatus")}</Table.HeaderCell>
                                    <Table.HeaderCell>{i18next.t("coachElite.viewGameSequences.table.tags")}</Table.HeaderCell>
                                    <Table.HeaderCell>{i18next.t("coachElite.viewGameSequences.table.edit")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {active.map(row =>
                                    <Table.Row
                                        className={active === row.id ? "selected" : "notSelected"}
                                        disabled={row.status === 'Pending' || row.status === 'Processing'}
                                        key={row.id}
                                    >
                                        <Table.Cell>{row.name}</Table.Cell>
                                        <Table.Cell>{row.description}</Table.Cell>
                                        <Table.Cell>{row.status}</Table.Cell>
                                        <Table.Cell>{handleTags(row.tags)}</Table.Cell>
                                        <Table.Cell>
                                            <Menu
                                                compact
                                                disabled={row.status === 'Pending' || row.status === 'Processing'}>
                                                <Dropdown item text="Select option">
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleItemSelect(row)}>
                                                            {i18next.t("coachElite.viewGameSequences.optionRemove")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            setTags(row.tags)
                                                            setActiveSequence(row.id)
                                                            setTagsOpen(true)
                                                        }}>
                                                            {i18next.t("coachElite.viewGameSequences.optionEdit")}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Menu>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                                }
                            </Table.Body>
                        </Table>
                    </>
                    :
                    <p>No data found.</p>
                }
                {modal.open &&
                    <GameSequenceModal
                        setModal={(val) => setModal(val)}
                        sequence={modal.sequence}
                    />
                }
                {tagsOpen &&
                    <Tags
                        setTags={(val) => setTags(val)}
                        tags={tags}
                        setTagsOpen={(val) => setTagsOpen(val)}
                        existingSequence={{id: activeSequence, isTrue: true}}
                        getUserSequences={()=>getUserSequences(selectedGame.id)}
                    />
                }
            </div>
        )
    }
}