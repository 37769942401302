import {Button, Dropdown, Form, Header, Input, Label, Table} from "semantic-ui-react";
//import i18next from "i18next";
import {useEffect, useState} from "react";
import {debug, url} from "../../../../config";
import cookies from "js-cookie";
import {addMessage} from "../../../../lib/functions/notifications";
import {SubscriptionSelection} from "../SubscriptionSelection/SubscriptionSelection";
import {AddSubscription} from "../AddSubscription/AddSubscription";
import {ViewDetails} from "../ViewDetails/ViewDetails";
import {saveAs} from 'file-saver';

export const Users = ({user}) => {

    const [email, setEmail] = useState("")
    const [PIN, setPIN] = useState("")
    const [name, setName] = useState("")
    const [club, setClub] = useState("")
    const [results, setResults] = useState([])
    const [updateSub, setUpdateSub] = useState(false)
    const [dialog, setDialog] = useState({addSub: false, showSub: false, user: undefined, sub: undefined})
    const [showDetails, setShowDetails] = useState({show: false, user: undefined})
    const [search, setSearch] = useState(false)

    useEffect(() => {
        if (updateSub) {
            setUpdateSub(false)
        }
    }, [updateSub])

    const subscriptionHandler = (sub) => {
        const subs = ["Individual", "Coach", "Team", "CoachElite"]
        if (sub === "length") {
            return subs.length
        }
        let arr = []
        sub.forEach(element => {
            for (let i = 0; i < subs.length; i++) {
                console.log(element.role === subs[i])
                if (element.role === subs[i]) {
                    arr.push(element)
                    break
                }
            }
        })
        return arr
    }
    const getAllUsers = async () => {
        try {
            let data = await fetch(`${url()}/auth/users`, {
                method: "GET",
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            let res = await data.json()
            // console.log(res)
            setResults(res)
        } catch (e) {
            addMessage("Unable to fetch users", "warning")
            debug(e)
        }
    }

    const createCSVOfUsers = () => {

        if (results.length < 1) {
            addMessage("No results listed", "warning")
            return
        }
        try {
            let csv = "name, email, PIN, club_association, gender, primary_position, secondary_position, subscriptions\n"
            let data = ""
            results.forEach(user => {
                let subscriptions = ""
                subscriptionHandler(user.subscriptions).forEach(sub => {
                    if (subscriptions === "") {
                        subscriptions = sub.role
                    } else {
                        subscriptions = subscriptions + ", " + sub.role
                    }
                })
                const listOfSubscriptions = '"' + subscriptions + '"'
                data = data + `${user.name}, ${user.email}, ${user.pin}, ${user.associated_club}, ${user.gender}, ${user.primary_position}, ${user.secondary_position}, ${listOfSubscriptions}\n`
            })
            csv = csv + data

            saveAs(new Blob([csv]), "users.csv")
        } catch (e) {
            addMessage(`Error: ${e}`, "warning")
            debug(e)
        }
    }

    const submitHandler = async () => {

        try {
            let queryString = "?"

            const queryStringHandle = () => {
                if (queryString.length > 1) {
                    return "&"
                }
                return ""
            }
            //only query values that have been entered
            if (email.trim().length > 0) {
                queryString = queryString + queryStringHandle() + "email=" + email
            }
            if (PIN.trim().length > 0) {
                queryString = queryString + queryStringHandle() + "pin=" + PIN
            }
            if (name.trim().length > 0) {
                queryString = queryString + queryStringHandle() + "name=" + name
            }
            if (club.trim().length > 0) {
                queryString = queryString + queryStringHandle() + "club=" + club
            }
            if (queryString.length === 1) {
                addMessage("You cannot search without using at least one search parameter", "warning")
                return
            }

            if (!search) { //determines if user has attempted to search yet
                setSearch(true)
            }

            let data = await fetch(`${url()}/api/admin/query` + queryString, {
                method: "GET",
                headers: new Headers({
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("session")}`
                })
            })
            let res = await data.json()
            debug(res)
            if (res.length < 1) {
                addMessage("No results found", "info")
            }
            setResults(res)
        } catch (e) {
            debug(e)
        }
    }
    if (!user.isAdmin) {
        return (
            <p>Access denied.</p>
        )
    } else {
        return (
            <div>
                <Header as="h1">
                    Users
                </Header>
                <div>
                    <Button onClick={() => getAllUsers()}>Show All Users</Button>
                    <Button onClick={() => createCSVOfUsers()}>Download CSV of listed users</Button>
                </div>
                <br/>
                <div>
                    <p>Search for user:</p>
                    <Form onSubmit={(e) => submitHandler(e)}>
                        <Form.Field inline={true} className="field">
                            <Label className="teal label">Email</Label>
                            <Input type="input" placeholder="Email"
                                   onChange={(e) => setEmail(e.target.value)} value={email}/>
                        </Form.Field>
                        <Form.Field inline={true} className="field">
                            <Label className="teal label">PIN</Label>
                            <Input type="input" placeholder="PIN"
                                   onChange={(e) => setPIN(e.target.value)} value={PIN}/>
                        </Form.Field>
                        <Form.Field inline={true} className="field">
                            <Label className="teal label">Name</Label>
                            <Input type="input" placeholder="Name"
                                   onChange={(e) => setName(e.target.value)} value={name}/>
                        </Form.Field>
                        <Form.Field inline={true} className="field">
                            <Label className="teal label">Associated Club</Label>
                            <Input type="input" placeholder="Associated Club"
                                   onChange={(e) => setClub(e.target.value)} value={club}/>
                        </Form.Field>
                        <Button>Search</Button>
                    </Form>
                </div>
                <br/>
                {results.length > 0 ? <div>
                        <Header as="h2">Results</Header>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>View Details</Table.HeaderCell>
                                    <Table.HeaderCell>Manage Subscriptions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {results.map((users, index) =>
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            {users.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {users.email}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={() => setShowDetails({show: true, user: users})}>View</Button>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Dropdown text={subscriptionHandler(users.subscriptions).length.toString()}>
                                                <Dropdown.Menu>
                                                    {subscriptionHandler(users.subscriptions).map((sub, index) =>
                                                        <Dropdown.Item
                                                            key={index}
                                                            onClick={() => setDialog({
                                                                addSub: false,
                                                                showSub: true,
                                                                user: users.name,
                                                                userId: users.id,
                                                                sub: sub
                                                            })}
                                                        >{sub.role}
                                                        </Dropdown.Item>
                                                    )}
                                                    {subscriptionHandler(users.subscriptions).length < subscriptionHandler("length") &&
                                                        <Dropdown.Item onClick={() => setDialog({
                                                            addSub: true,
                                                            showSub: false,
                                                            user: users.name,
                                                            userId: users.id,
                                                            sub: subscriptionHandler(users.subscriptions)
                                                        })}>
                                                            Add new Subscription...
                                                        </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Table.Cell>
                                    </Table.Row>)}
                            </Table.Body>
                        </Table>
                    </div>
                    :
                    search &&
                    <p>No results found</p>}
                {dialog.showSub &&
                    <SubscriptionSelection
                        dialog={dialog} setDialog={(val) => setDialog(val)} setUpdateSub={(val) => setUpdateSub(val)}
                    />}
                {dialog.addSub &&
                    <AddSubscription
                        dialog={dialog} setDialog={(val) => setDialog(val)}
                    />}
                {showDetails.show &&
                    <ViewDetails
                        showDetails={showDetails} setShowDetails={(val) => setShowDetails(val)}
                    />}
            </div>
        )
    }
}