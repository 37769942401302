
export const playerPositions = [
    { long: "Goalkeeper", short: "GK" },
    { long: "Right Back", short: "RB" },
    { long: "Left Back", short: "LB" },
    { long: "Centre Back", short: "CB" },
    { long: "Right Wing Back", short: "RWB" },
    { long: "Left Wing Back", short: "LWB" },
    { long: "Defensive Midfielder", short: "CDM" },
    { long: "Centre Midfielder", short: "CM" },
    { long: "Attacking Midfielder", short: "CAM" },
    { long: "Left Midfielder", short: "LM" },
    { long: "Right Midfielder", short: "RM" },
    { long: "Left Winger", short: "LW" },
    { long: "Right Winger", short: "RW" },
    { long: "Right Forward", short: "RF" },
    { long: "Left Forward", short: "LF" },
    { long: "Centre Forward", short: "CF" }
];