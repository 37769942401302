import React, {useEffect, useState} from 'react';
import {Dropdown, Header, List, Loader, Menu} from 'semantic-ui-react'; 
import {Segment} from 'semantic-ui-react'
import {loadStripe} from '@stripe/stripe-js';

import coachLogo from './img/coach_logo.png'
import coachPreview from './img/coach_preview_alt.jpg'
import playerLogo from './img/player_logo.png'
import playerPreview from './img/player_preview.jpg'
import teamPreview from './img/team_preview.jpg'

import i18next from 'i18next';
import {url, debug} from '../../config';

import cookies from 'js-cookie';

import './Purchase.css'
import {Stripe} from "../../config";
import {addMessage} from '../../lib/functions/notifications';
import {ImgView} from "./ImgView/ImgView";
// eslint-disable-next-line
import {HighlightTag} from "../../StyledComponents";
import {MacHelp} from "./MacHelp/MacHelp";

const stripePromise = loadStripe(Stripe.KEY);

let qs = require('qs');

const Purchase = ({user}) => {
    // eslint-disable-next-line
    const [individualSub, setIndividualSub] = useState(false);
    const [coachsub, setCoachSub] = useState(false);
    //const [teamsub, setTeamsub] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showImage, setShowImage] = useState({show: false, src: undefined})
    const [macHelpModal, setMacHelpModal] = useState(false)
    const [region, setRegion] = useState("US")

    const redirectUrl = new URL('logged_in_home', document.location.origin);

    const purchaseVIPER = async (product_code) => {

        const stripe = await stripePromise;
        let mode = "subscription"

        let data = await fetch(`${url()}/payment/create-checkout-session?price=${product_code}&mode=${mode}&redirect=${redirectUrl.href}`, {
            method: 'POST',
            redirect: 'follow',
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${cookies.get('session')}`
            })
        }).catch(function (error) {
            debug("Error: " + error);
        });

        if (data === undefined) {
            // We failed to fetch, so let the user know.
            addMessage(i18next.t('general.loggedinhome.failed'), 'warning')
            return;
        }

        debug(data);

        if (data.status === 401) {
            addMessage(i18next.t('general.loggedinhome.unathorised'), 'warning')
            debug('Not authorized.');
            return;
        }

        const session = await data.json();
        debug(session)
        const result = await stripe.redirectToCheckout(session);

        if (result.error) {
            addMessage('Unable to complete request', 'warning')
        }
    }


    useEffect(() => {

        if (qs.parse(document.location.search, {ignoreQueryPrefix: true}).canceled) {
            addMessage(i18next.t("general.loggedinhome.canceled"), "warning")

        } else if (qs.parse(document.location.search, {ignoreQueryPrefix: true}).success) {
            addMessage(i18next.t("general.loggedinhome.success"), "success")
        }
    }, []);

    useEffect(() => {
        const sub = user.subscriptions
        debug(sub)
        const checkCoach = () => {
            sub.forEach(element => {
                if (element.role === "Coach") {
                    setCoachSub(true)
                    return
                }
            })
        }

        const checkPlayer = () => {
            sub.forEach(element => {
                if (element.role === "Individual") {
                    setIndividualSub(true)
                    return
                }
            })
        }
        checkCoach()
        checkPlayer()
        setLoading(true)
        //remove these 2 lines below:
        setCoachSub(false);
        setIndividualSub(false);
    }, [user.subscriptions])

    if (loading) {
        return (
            <div className="purchaseWidth">
                <Menu compact>
                    <Dropdown item text={region}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => setRegion("US")}>
                                $ USD
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setRegion("UK")}>
                                £ GBP
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setRegion("EU")}>
                                € EUR
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu>
                <div className="purchaseFlex">
                    {!coachsub && <div className="purchase">
                        <div className="purchaseHeadingTop">
                            <img src={coachLogo} height="100" width="100"
                                 alt="CoachLogo"
                            />
                            <div className="headerWidth">
                                <Header as="h3">{i18next.t("general.subscriptions.subs.coach")}</Header>
                            </div>
                        </div>
                        <br/>
                        <div className="purchaseHeadingMiddle">
                            <List bulleted>
                                <List.Item>{i18next.t("general.loggedinhome.coachpoint1")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.coachpoint2")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.coachpoint3")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.coachpoint4")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.coachpoint5")}</List.Item>
                            </List>
                        </div>
                        <br/>
                        <div className="purchaseHeadingBottom">
                            <p>{i18next.t(`general.loggedinhome.price.${region}.coach_offer`)}</p>
                            <Menu compact>
                                <Dropdown item text={i18next.t("general.loggedinhome.purchase")}>
                                    <Dropdown.Menu>
                                        {/*<Dropdown.Item*/}
                                        {/*    onClick={() => purchaseVIPER(Stripe[region].PRODUCT_VIPER_COACH_ANNUAL)}>*/}
                                        {/*    {i18next.t("general.loggedinhome.annualSub")}*/}
                                        {/*</Dropdown.Item>*/}
                                        <Dropdown.Item onClick={() => purchaseVIPER(Stripe[region].PRODUCT_VIPER_COACH_MONTHLY)}>
                                            {i18next.t("general.loggedinhome.monthSub")}
                                        </Dropdown.Item>
                                        {/*<Dropdown.Item*/}
                                        {/*    onClick={() => purchaseVIPER(Stripe[region].PRODUCT_VIPER_COACH_3_MONTH_PLAN)}>*/}
                                        {/*    {i18next.t("general.loggedinhome.3monthSub")}*/}
                                        {/*</Dropdown.Item>*/}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu>
                        </div>
                    </div>}

                    {false && <div className="purchase">
                        <div className="purchaseHeadingTop">
                            <img src={playerLogo} height="100" width="100"
                                 alt="playerLogo"
                            />
                            <div className="headerWidth">
                                <Header as="h3">{i18next.t("general.subscriptions.subs.individual")}</Header>
                            </div>
                        </div>
                        <br/>
                        <div className="purchaseHeadingMiddle">
                            <List bulleted>
                                <List.Item>{i18next.t("general.loggedinhome.playerpoint1")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.playerpoint2")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.playerpoint3")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.playerpoint4")}</List.Item>
                            </List>
                        </div>
                        <br/>
                        <div className="purchaseHeadingBottom">
                            {region === "US" ?
                                <p>{i18next.t("general.loggedinhome.price.player")}</p>
                                :
                                <p>{i18next.t("general.loggedinhome.price.player_unavailable")}</p>
                            }
                            <Menu compact>
                                <Dropdown disabled={region !== "US"} item
                                          text={i18next.t("general.loggedinhome.purchase")}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => purchaseVIPER(Stripe[region].PRODUCT_VIPER_INDIVIDUAL)}>
                                            {i18next.t("general.loggedinhome.monthSub")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu>
                        </div>
                    </div>}

                    {false && <div className="purchase">
                        <div className="purchaseHeadingTop">
                            <img src={playerLogo} height="100" width="100"
                                 alt="teamLogo"
                            />
                            <div className="headerWidth">
                                <Header as="h3"> {i18next.t("general.subscriptions.subs.team")}</Header>
                            </div>
                        </div>
                        <br/>
                        <div className="purchaseHeadingMiddle">
                            <List bulleted>
                                <List.Item>{i18next.t("general.loggedinhome.teampoint1")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.teampoint2")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.teampoint3")}</List.Item>
                                <List.Item>{i18next.t("general.loggedinhome.teampoint4")}</List.Item>
                            </List>
                        </div>
                        <br/>
                        <div className="purchaseHeadingBottom">
                            <p className="invisibleElement">text</p>
                            <Menu compact>
                                <Menu.Item href="https://futureperformance.tech/contact" link={true}>
                                    Contact Us
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>}
                </div>
                <br/>

                <div className="purchaseWidth">
                    <div className="purchaseFlex">
                        {!coachsub && <div>
                            <img src={coachPreview}
                                 onClick={() => setShowImage({show: true, src: coachPreview, alt: "CoachPreview"})}
                                 className="purchaseImg"
                                 alt="CoachPreview"
                            />
                        </div>}
                        {false && <div>
                            <img src={playerPreview}
                                 onClick={() => setShowImage({
                                     show: true,
                                     src: playerPreview,
                                     alt: "IndividualPreview"
                                 })}
                                 className="purchaseImg"
                                 alt="IndividualPreview"
                            />
                        </div>}
                        {false && <div>
                            <img src={teamPreview}
                                 onClick={() => setShowImage({show: true, src: teamPreview, alt: "TeamPreview"})}
                                 className="purchaseImg"
                                 alt="TeamPreview"
                            />
                        </div>}
                    </div>
                </div>
                <br/>
                <p align="center">{i18next.t("general.loggedinhome.downloadfree")}</p>
                <div id="coachVideo">
                    <iframe
                        src="https://www.youtube.com/embed/5EY0rZWFo84?si=G2mw2HhfZRfnTrBV"
                        width="640"
                        height="360"
                        frameBorder="0"
                        title="Viper Coach"
                        allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </div>
                <br/>
                <Segment basic textAlign="left" className="getStartedSegment">
                <Header as="h1">Downloads</Header>
                <p>See below on downloading the app onto your Windows, Mac or Meta Quest 2 system.</p>
                <p>To use the full features either:</p>
                <p>Sign up for a subscription and get started on creating your own content and working with your own players/coaches
                or
                connect to a current subscriber by pairing your profile to their account.</p>
                </Segment>
                <Segment basic textAlign="left" className="getStartedSegment">
                    <Header as="h2">
                        {i18next.t("coach.getStarted.windows.heading")}
                    </Header>
                    <p>{i18next.t("coach.getStarted.windows.desc1")}
                        <a
                            href={process.env.REACT_APP_PM_COACH_DOWNLOAD}
                            download
                            target="_blank"
                            rel="noreferrer"
                        >{i18next.t("coach.getStarted.windows.desc2")}</a>
                        {i18next.t("coach.getStarted.windows.desc3")}</p>
                </Segment>
                <Segment basic textAlign="left" className="getStartedSegment">
                    <Header as="h2">
                        {i18next.t("general.loggedinhome.mac_header")}
                    </Header>
                    <p>
                        {i18next.t("general.loggedinhome.mac_desc1")}
                        <a
                            href={process.env.REACT_APP_PM_COACH_DOWNLOAD_MAC}
                            download
                            target="_blank"
                            rel="noreferrer"
                        > here </a>
                        {i18next.t("general.loggedinhome.mac_desc2")}
                    </p>
                    {<HighlightTag
                        italic
                        link
                        color="#3399ff"
                        onClick={() => setMacHelpModal(true)}
                    >{i18next.t("general.loggedinhome.mac_help")}</HighlightTag>}
                </Segment>
                <Segment basic textAlign="left" className="getStartedSegment">
                    <Header as="h2">
                        {i18next.t("coach.getStarted.meta.heading")}
                    </Header>
                    <p>{i18next.t("coach.getStarted.oculus.desc1")}</p>
                    <p>{i18next.t("coach.getStarted.oculus.desc2")}</p>
                </Segment>
                <Segment basic>
                  <Header as="h2">
                    {i18next.t("coach.getStarted.tutorial.heading")}
                  </Header>
                  <div className="purchaseWidth">
                    <div style={{marginBottom: "15px"}}>
                      <h3>General Controls</h3>
                      <iframe
                        src="https://www.youtube.com/embed/PsLSvbphuCQ?si=mXrvaHwHtcbwV0cO"
                        width="560"
                        height="315"
                        title="General Controls"
                        frameborder="0"
                        allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                    </div>

                    <div style={{marginBottom: "15px"}}>
                      <h3>Free Play Mode</h3>
                      <iframe
                          src="https://www.youtube.com/embed/BGexbp_6m1Q?si=QaMOPADX05L7ngpz"
                          width="560"
                          height="315"
                          title="Free Play Mode"
                          frameborder="0"
                          allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                          allowfullscreen
                        />
                    </div>
                    <div style={{marginBottom: "15px"}}>
                      <h3>Animation Sequence</h3>
                      <iframe
                        src="https://www.youtube.com/embed/I4FMWiY_loQ?si=xTm23AlrYDFlEkSW"
                        width="560"
                        height="315"
                        title="Creating animation sequence"
                        frameborder="0"
                        allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                    </div>
                    <div>
                      <h3>Connecting with other users</h3>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/e-tuf5WseD0?si=NTZIdPouVtNUxcOI"
                        title="Connecting with other users"
                        frameborder="0"
                        allow="accelerometer; autoplay; gyroscope; picture-in-picture"
                        allowfullscreen
                      />
                    </div>
                  </div>
                </Segment>
                <Segment basic textAlign="left" className="getStartedSegment">
                    <Header as="h3">{i18next.t("coach.getStarted.notice.heading")}</Header>
                    <p>{i18next.t("coach.getStarted.notice.desc1")}</p>
                    <br/>
                    <p>{i18next.t("coach.getStarted.notice.desc2")}</p>
                </Segment>
                <Segment basic textAlign="left" className="getStartedSegment">
                    <Header as="h3">Contact Us</Header>
                    <p><a href="https://futureperformance.tech/contact/">https://futureperformance.tech/contact/</a></p>
                </Segment>
                {showImage.show &&
                    <ImgView showImage={showImage} setShowImage={(val) => setShowImage(val)}/>}
                    <MacHelp macHelpModal={macHelpModal} setMacHelpModal={(val) => setMacHelpModal(val)}/>
            </div>
        )
    } else {
        return (
            <div className='loader'>
                <Loader size='massive' active={true}>Loading</Loader>
            </div>
        )
    }

}
export default Purchase;
