import {Button, Modal} from "semantic-ui-react";
import './MacHelp.css'

import macBlocked from '../img/mac_blocked.png'
import macAppstore from '../img/mac_appstore.png'
import i18n from "../../../i18n";

export const MacHelp = ({macHelpModal, setMacHelpModal}) => {

    return (
        <div className="macModal">
        <Modal
        onClose={()=> setMacHelpModal(false)}
        open={macHelpModal}
        >
            <Modal.Header>Mac Help</Modal.Header>
            <Modal.Content>
            <p>{i18n.t("general.loggedinhome.mac.help_modal1")}</p>
                <img className="macImage" src={macAppstore} alt="security and privacy panel" />
                <p>{i18n.t("general.loggedinhome.mac.help_modal2")}</p>
                <img className="macImage" src={macBlocked} alt="security and privacy panel" />
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={()=> setMacHelpModal(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
        </div>
    )
}