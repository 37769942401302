import i18next from "i18next";
import {debug} from "../../config";

export const isDateOfBirthValid = (jsDate) => {
    //check dd/mm/yyyy format, if date is the same/newer than current return false
    let currentDate = new Date()
    let date = jsDate;

    const dateObj = {
        dateDay: date.getDate(),
        currentDateDay: currentDate.getDate(),
        dateMonth: date.getMonth(),
        currentDateMonth: currentDate.getMonth(),
        dateYear: date.getFullYear(),
        currentDateYear: currentDate.getFullYear()
    }
    //you can subtract from the currentDateYear to set a minimum age allowed (by default will allow any age exept same day and future.)
    currentDate = new Date(dateObj.currentDateYear, dateObj.currentDateMonth, dateObj.currentDateDay)
    date = new Date(dateObj.dateYear, dateObj.dateMonth, dateObj.dateDay)

    if (date >= currentDate) {
        return true;
    }
    return false;
}

export const checkProfileValue = (val) => {
    //returns null if value is an empty string or undefined

    if (val === "" || val === undefined || val === i18next.t("general.profile.select_text")) {
        return null;
    } else {
        return val;
    }
}

export const isProfileFilledIn = (userData) => {
    //if any field in profile is null returns false, else returns true
    let sub = false;

    for (let i = 0; i < userData.subscriptions.length; i++) {
        if (userData.subscriptions[i].role === "Individual" || userData.subscriptions[i].role === "Player") {
            sub = true;
        }
    }

    let surnameIndex = userData.name.indexOf(" ");
    let firstName;
    let surname;
    if (surnameIndex === -1) {
        firstName = userData.name;
        surname = null;
    } else {
        firstName = userData.name.slice(0, surnameIndex);
        surname = userData.name.slice(surnameIndex + 1);
    }

    const profileData = [
        checkProfileValue(firstName),
        checkProfileValue(surname),
    ]

    if (sub) {  //checks each value for empty strings or placeholder text, returns null if true
        profileData.push(
            checkProfileValue(userData.gender),
            checkProfileValue(userData.primary_position),
            checkProfileValue(userData.secondary_position))
    }

    const isValueNotNull = (value) => {
        if (value === null) {
            return false;
        } else {
            return true;
        }
    }
    debug(profileData.every(val => isValueNotNull(val)))
    return profileData.every(val => isValueNotNull(val))
    //checks every value for null, returns false if found else returns true
}